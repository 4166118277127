import { createContext, MouseEvent, TouchEvent, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import Card from "../../../components/ui/Card";
import { OuvrageType } from "../../../models/ouvrage";
import VisualizationElements from "./components/VisualizationElements";
import { getEventCoordinates, updateCoordinates, visualizationReducer } from "./functions";
import './index.scss';
import { VisualizationProps, VisualizationReducerState } from "./types";

const INITIAL_REDUCER_STATE: VisualizationReducerState = {
    bbox: { width: 100, height: 100, x: 0, y: 0 },
    viewBox: {
        x: 0,
        y: 0,
        width: 100,
        height: 100
    },
    view: 'front',
    type: OuvrageType.PONT,
    contentBbox: { width: 100, length: 100, height: 100, x: 0, y: 0, z: 0 },
    translation: { x: 0, y: 0 },
    translationInProgress: { x: 0, y: 0 },
    scale: 1,
    zoom: 1,
}


export const VisualizationContext = createContext<VisualizationReducerState>({
    ...INITIAL_REDUCER_STATE
});

const Visualization = ({
    ouvrage,
    children,
    selected,
    onSelect,
    onChange,
    view,
    onMarker,
}: VisualizationProps) => {
    const ref = useRef<SVGSVGElement>(null);
    const [state, dispatch] = useReducer(visualizationReducer, { ...INITIAL_REDUCER_STATE, type: ouvrage.type ?? OuvrageType.MUR });

    const offsetZ = useMemo(() => ouvrage.elements?.find(e => e.type === 'tablier')?.dimensions.height, [ouvrage.elements]);
    const viewBoxString = useMemo(() => `${Math.round((state.viewBox.x + (state.translationInProgress?.x ?? 0)) / state.zoom)} ${Math.round((state.viewBox.y + (state.translationInProgress?.y ?? 0)) / state.zoom)} ${Math.round(state.viewBox.width / state.zoom)} ${Math.round(state.viewBox.height / state.zoom)}`
        , [state]);

    const handleAddMarker = (e: MouseEvent | TouchEvent) => {
        if (!onMarker) return;
        onMarker(updateCoordinates(state, getEventCoordinates(e), {}));
    }

    const handleMouseMove = (e: MouseEvent | TouchEvent) => {
        if (!state.mouseDown) return;
        dispatch({ type: 'translate', payload: { coordinates: getEventCoordinates(e) } })
    }

    const handleMouseDown = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        dispatch({ type: 'mouseDown', payload: { coordinates: getEventCoordinates(e) } });
    }

    const handleMouseUp = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        if (!state.translationInProgress?.x && !state.translationInProgress?.y) {
            if (onMarker) {
                handleAddMarker(e)
            }
        }

        dispatch({ type: 'mouseUp' });
    }

    useEffect(() => {
        dispatch({ type: 'setContentBbox', payload: { content: ouvrage.elements } });
    }, [ouvrage.elements]);

    useEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver(() => {
            if (ref.current) {
                dispatch({ type: 'setBbox', payload: { bbox: ref.current.getBoundingClientRect() } });
            }
        });

        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (view && view !== state.view) {
            dispatch({ type: 'setView', payload: { view } })
        }
    }, [view]);

    return (
        <VisualizationContext.Provider value={state}>
            <div id="visualization">
                {!!onMarker && (
                    <Card id="visualization-tooltip">
                        <span className="info">Cliquez à l'endroit où se situe l'élément. Changez de vue si nécessaire. Vous pourrez le déplacer ultérieurement.</span>
                    </Card>
                )}
                <svg
                    id="visualizator"
                    className={`visualizator-${onMarker ? 'marker' : ''}`}
                    viewBox={viewBoxString}
                    fillRule={(state.view === 'left' || state.view === 'right') && ouvrage.type === OuvrageType.BUSE ? 'evenodd' : undefined}
                    ref={ref}
                    onWheel={(e) => dispatch({ type: 'addZoom', payload: { zoom: e.deltaY, coordinates: { x: e.clientX - e.currentTarget.getBoundingClientRect().left, y: e.clientY - e.currentTarget.getBoundingClientRect().top } } })}
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                >
                    <VisualizationElements
                        elements={ouvrage.elements}
                        selected={selected}
                        onClick={onSelect}
                        onChange={onChange}
                        offsetZ={offsetZ}
                    />
                    {children}
                </svg>
            </div>
        </VisualizationContext.Provider>
    )
}

export const useVisualisation = () => {
    return useContext<VisualizationReducerState>(VisualizationContext);
};

export default Visualization;