import { useCallback, useEffect, useMemo, useState } from 'react';
import PrInput from '../../../components/inputs/PrInput';
import TextInput from '../../../components/inputs/TextInput';
import { Modal } from '../../../components/ui/Modal';
import { FormComparator, validateAll } from '../../../hooks/useForm';
import { Exit, WayDto } from '../../../models/operation';

interface ExistsProps {
    index: number;
    way: WayDto;
    onClose: () => void;
    onSubmit: (e: Exit[]) => void;
}

const Exits = ({
    index,
    way,
    onClose,
    onSubmit
}: ExistsProps) => {
    const [exit, setExit] = useState<Partial<Exit>>({});
    const [errors, setErrors] = useState<{ [field: string]: string[] }>({});
    const prRules = useMemo(() => [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.PR },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: way?.prStart, max: way?.prEnd }, message: 'Le PR est hors des limites du sens' }
    ], [way]);

    const handleSubmit = useCallback(() => {
        const [errors, hasError] = validateAll(exit, {
            name: [{ comparator: FormComparator.REQUIRED }],
            pr: prRules
        });

        if (way.exits?.some((e, i) => index !== i && e.name === exit.name && e.pr === exit.pr)) {
            errors.name = ['Une sortie identique existe déjà'];
            setErrors(errors);
            return;
        }

        if (!hasError) {
            const exits = [...(way.exits ?? [])];
            if (index === -1) {
                exits.push(exit as Exit);
            } else {
                exits[index] = exit as Exit;
            }
            onSubmit(exits);
        }
        setErrors(errors);
    }, [onSubmit, exit, way, prRules, index]);

    useEffect(() => {
        if (index === -1) {
            setExit({});
        } else if (index < way?.exits.length) {
            setExit(way.exits[index])
        }
    }, [way, index]);

    return (
        <Modal
            title="Editer une sortie"
            id="modal-exits"
            size="medium"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', onClick: handleSubmit },
            ]}
        >
            <div className="row">
                <div className="input-column">
                    <label htmlFor={'name' + index}>Nom *</label>
                    <TextInput
                        id="name"
                        value={exit.name}
                        placeholder="Nom de la sortie"
                        onChange={(name) => setExit({ ...exit, name })}
                        errors={errors?.name}
                    />
                </div>
                <div className="input-column">
                    <label htmlFor={'pr' + index}>PR *</label>
                    <PrInput
                        id="pr"
                        value={exit.pr}
                        onChange={(pr) => setExit({ ...exit, pr })}
                        errors={errors?.pr}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default Exits;