import { Icon } from '@iconify/react';
import { Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logoCompact@2x.png';
import { BusinessIcon } from '../../models/business';
import { LicenseIcon } from '../../models/license';
import { MaterialIcon } from '../../models/material';
import { OperationSubType } from '../../models/operation';
import { OuvrageIcon } from '../../models/ouvrage';
import { Role } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import useWorkspace from '../../services/hooks/use-workspace';
import { logout } from '../../services/slices/auth.slice';
import { toggleNotificationsPanel } from '../../services/slices/ui.slice';
import { setWorkspace, WorkspaceLabel, WorkspaceType } from '../../services/slices/workspace.slice';
import Collapse from '../ui/Collapse';
import MenuBar from '../ui/MenuBar';
import './index.scss';

export const SidebarSubcontractor = () => {
    const { workspace, operation, ouvrage } = useWorkspace();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSwitch = useCallback(() => {
        dispatch(setWorkspace(workspace === WorkspaceType.CHAUSSES ? WorkspaceType.OUVRAGES : WorkspaceType.CHAUSSES));
        navigate('/');
    }, [workspace]);

    return (
        <nav id="navigation-subcontractor">
            <div id="navigation-logo-workspace">
                <div id="navigation-logo" onClick={() => navigate('/')}>
                    <div id="navigation-logo-image"><img src={logo} alt="OSO Numérique" id="logo" /></div>
                    <div id="navigation-logo-text">
                        <span>OSO Numérique</span>
                        <div>
                            {WorkspaceLabel[workspace]}
                            <Icon icon="mdi:chevron-down" className="color-white" />
                            <MenuBar card column>
                                <MenuBar.Item
                                    onClick={handleSwitch}
                                    label={`Aller vers '${WorkspaceLabel[workspace === WorkspaceType.CHAUSSES ? WorkspaceType.OUVRAGES : WorkspaceType.CHAUSSES]}'`}
                                />
                            </MenuBar>
                        </div>
                    </div>
                </div>
                {!!operation._id && (
                    <Link id="navigation-workspace" to={'/operation/' + operation._id}>
                        <div>
                            <span>Opération en cours</span>
                            <div>
                                <div>
                                    <Icon icon={OperationSubType[operation.type].icon} />
                                    <span className="text-bold color-white">{operation.name}</span>
                                </div>
                                {!!operation.waysObj[1] && (
                                    <div>
                                        <Icon icon="mdi:flag" />
                                        <span>{operation.waysObj[1].prStartString} à {operation.waysObj[1].prEndString}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Link>
                )}
                {!!ouvrage._id && (
                    <div id="navigation-workspace">
                        <div>
                            <span>Ouvrage en cours</span>
                            <Link to={'/ouvrage/' + ouvrage._id}><span className="text-bold color-white">{ouvrage.name}</span></Link>
                        </div>
                    </div>
                )}
            </div>
            <div id="navigation-user">
                <span>{user.fullName}</span>
                <Icon icon="mdi:notifications" className="color-white" onClick={() => dispatch(toggleNotificationsPanel())} />
                <Icon icon="mdi:settings-outline" className="color-white" onClick={() => navigate('/mon-profil')} />
                <Icon icon="mdi:logout" className="color-white" onClick={() => dispatch(logout())} />
            </div>
        </nav>
    );
}

const Sidebar = () => {
    const location = useLocation();
    const { workspace, operation, ouvrage } = useWorkspace();
    const { user, permissions } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpened, setOpened] = useState<boolean>(false);

    const closeMenu = useCallback(() => setOpened(false), []);
    const handleSwitch = useCallback(() => {
        dispatch(setWorkspace(workspace === WorkspaceType.CHAUSSES ? WorkspaceType.OUVRAGES : WorkspaceType.CHAUSSES));
        navigate('/');
    }, [workspace]);

    return (
        <nav id="navigation" className={isOpened ? 'opened' : undefined}>
            <div id="navigation-content">
                <div id="navigation-logo-workspace">
                    <div id="navigation-logo" onClick={() => setOpened(!isOpened)}>
                        <div id="navigation-logo-image"><img src={logo} alt="OSO Numérique" id="logo" /></div>
                        <div id="navigation-logo-text">
                            <span>OSO Numérique</span>
                            <div>
                                {WorkspaceLabel[workspace]}
                                <Icon icon="mdi:chevron-down" className="color-white" />
                                <MenuBar card column>
                                    <MenuBar.Item
                                        onClick={handleSwitch}
                                        label={`Aller vers '${WorkspaceLabel[workspace === WorkspaceType.CHAUSSES ? WorkspaceType.OUVRAGES : WorkspaceType.CHAUSSES]}'`}
                                    />
                                </MenuBar>
                            </div>
                        </div>
                    </div>
                    {!!operation._id && (
                        <Link id="navigation-workspace" to={'/operation/' + operation._id}>
                            <span>Opération en cours</span>
                            <div>
                                <div>
                                    <Icon icon={OperationSubType[operation.type].icon} />
                                    <span className="text-bold color-white">{operation.name}</span>
                                </div>
                                {!!operation.waysObj[1] && (
                                    <div>
                                        <Icon icon="mdi:flag" />
                                        <span>{operation.waysObj[1].prStartString} à {operation.waysObj[1].prEndString}</span>
                                    </div>
                                )}
                            </div>
                        </Link>
                    )}
                    {!!ouvrage._id && (
                        <Link id="navigation-workspace" to={'/ouvrage/' + ouvrage._id}>
                            <span>Ouvrage en cours</span>
                            <div>
                                <div>
                                    <Icon icon={OuvrageIcon[ouvrage.type]} />
                                    <span className="text-bold color-white">{ouvrage.name}</span>
                                </div>
                            </div>
                        </Link>
                    )}
                </div>
                <div id="navigation-menu-user">
                    <div id="navigation-menu">
                        <Link
                            to="/"
                            onClick={closeMenu}
                            className={location.pathname === '/' ? 'active' : ''}
                        >
                            <Icon icon="mdi:home-variant" className="icon-large color-white" />
                            <span>Accueil</span>
                        </Link>
                        {workspace === WorkspaceType.CHAUSSES && permissions.library && (
                            <Fragment>
                                <Collapse
                                    header={
                                        <div className="navigation-item">
                                            <Icon icon="mdi:chart-box-multiple-outline" className="icon-large color-white" />
                                            <span>Bibliothèque</span>
                                        </div>
                                    }
                                >
                                    <Link
                                        to="/bibliotheque/recherche-de-caracteristique"
                                        onClick={closeMenu}
                                        className={location.pathname === '/bibliotheque/recherche-de-caracteristique' ? 'active' : ''}
                                    >
                                        <Icon icon="mdi:test-tube-empty" className="icon-large color-white" />
                                        <span>Caractéristiques</span>
                                    </Link>
                                    <Link
                                        to="/bibliotheque/recherche-de-materiau"
                                        onClick={closeMenu}
                                        className={location.pathname === '/bibliotheque/recherche-de-materiau' ? 'active' : ''}
                                    >
                                        <Icon icon={MaterialIcon} className="icon-large color-white" />
                                        <span>Matériaux</span>
                                    </Link>
                                </Collapse>
                            </Fragment>
                        )}
                        {workspace === WorkspaceType.CHAUSSES && permissions.administrate && (
                            <Link
                                to="/catalogue"
                                onClick={closeMenu}
                                className={location.pathname.startsWith('/catalogue') ? 'active' : ''}
                            >
                                <Icon icon="mdi:book-open-variant-outline" className="icon-large color-white" />
                                <span>Catalogue</span>
                            </Link>
                        )}
                        {permissions.administrate && (
                            <Link
                                to="/annuaire"
                                onClick={closeMenu}
                                className={location.pathname.startsWith('/annuaire') ? 'active' : ''}
                            >
                                <Icon icon={BusinessIcon} className="icon-large color-white" />
                                <span>Annuaire</span>
                            </Link>
                        )}
                        {[Role.SUPER_ADMIN].includes(user.role) && (
                            <Fragment>
                                <Collapse
                                    header={
                                        <div className="navigation-item">
                                            <Icon icon="mdi:settings-outline" className="icon-large color-white" />
                                            <span>Administration</span>
                                        </div>
                                    }
                                >
                                    {[Role.SUPER_ADMIN].includes(user.role) && (
                                        <Link
                                            to="/licences"
                                            onClick={closeMenu}
                                            className={location.pathname.startsWith('/licenses') ? 'active' : ''}
                                        >
                                            <Icon icon={LicenseIcon} className="icon-large color-white" />
                                            <span>Licences</span>
                                        </Link>
                                    )}
                                    <Link
                                        to="/utilisateurs"
                                        onClick={closeMenu}
                                        className={location.pathname.startsWith('/utilisateurs') ? 'active' : ''}
                                    >
                                        <Icon icon="mdi:users" className="icon-large color-white" />
                                        <span>Utilisateurs</span>
                                    </Link>
                                </Collapse>
                            </Fragment>
                        )}
                    </div>
                    <div id="navigation-user">
                        <span>{user.fullName}</span>
                        <Icon icon="mdi:notifications" className="color-white" onClick={() => dispatch(toggleNotificationsPanel())} />
                        <Icon icon="mdi:settings-outline" className="color-white" onClick={() => navigate('/mon-profil')} />
                        <Icon icon="mdi:logout" className="color-white" onClick={() => dispatch(logout())} />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;