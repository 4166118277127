import { useCallback, useEffect } from 'react';
import NumberInput from '../../../../components/inputs/NumberInput';
import TextInput from '../../../../components/inputs/TextInput';
import { Textarea } from '../../../../components/inputs/Textarea';
import PanelRight from '../../../../components/ui/PanelRight';
import useForm, { FormComparator } from '../../../../hooks/useForm';
import { OuvrageElement } from '../../../../models/ouvrage';

interface OuvrageElementFormProps {
    element: OuvrageElement;
    onChange: (element: OuvrageElement) => void;
    onSubmit: (element: OuvrageElement) => void;
    onClose: () => void;
    onDelete: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.width': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'coordinates.x': [{ comparator: FormComparator.REQUIRED }],
    'coordinates.y': [{ comparator: FormComparator.REQUIRED }],
    'coordinates.z': [{ comparator: FormComparator.REQUIRED }],
}

export const OuvrageElementForm = ({ element, onClose, onChange, onSubmit, onDelete }: OuvrageElementFormProps) => {
    const { attachInput, validate, onChangeMultiple, entity } = useForm<OuvrageElement>({ ...element });

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const element = validate(VALIDATION);
        if (element) {
            onChange(element);
        }
    }, [entity]);

    useEffect(() => {
        if (
            element.coordinates.x !== entity.coordinates?.x ||
            element.coordinates.y !== entity.coordinates?.y ||
            element.coordinates.z !== entity.coordinates?.z ||
            element.dimensions.length !== entity.dimensions?.length ||
            element.dimensions.width !== entity.dimensions?.width ||
            element.dimensions.height !== entity.dimensions?.height
        ) {
            onChangeMultiple([{ field: 'coordinates', value: element.coordinates }, { field: 'dimensions', value: element.dimensions }]);
        }
    }, [element]);

    return (
        <PanelRight
            title={element._id ? element.name : 'Créer un élément'}
            id="ouvrage-element-form"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Confirmer', onClick: handleSubmit },
            ]}
            onDelete={onDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput placeholder="Nom de l'élément" {...attachInput('name')} />
            </div>
            <div className="input-column">
                <label htmlFor="description">Description</label>
                <Textarea {...attachInput('description')} />
            </div>
            <div className="input-column">
                <label htmlFor="location">Localisation</label>
                <TextInput placeholder="Aide à la localisation" {...attachInput('location')} />
            </div>
            <div className="row">
                <div className="input-column">
                    <label htmlFor="length">Longueur *</label>
                    <NumberInput {...attachInput('dimensions.length')} />
                </div>
                <div className="input-column">
                    <label htmlFor="width">Largeur *</label>
                    <NumberInput {...attachInput('dimensions.width')} />
                </div>
                <div className="input-column">
                    <label htmlFor="height">Hauteur *</label>
                    <NumberInput {...attachInput('dimensions.height')} />
                </div>
            </div>
            <div className="column">
                <h4>Position</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="x">Longitudinale *</label>
                        <NumberInput {...attachInput('coordinates.x')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="y">Transversale *</label>
                        <NumberInput {...attachInput('coordinates.y')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="z">Verticale *</label>
                        <NumberInput {...attachInput('coordinates.z')} />
                    </div>
                </div>
            </div>
        </PanelRight >
    )
}
export default OuvrageElementForm;