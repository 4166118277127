import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { StorageFile } from '../../models/storage';
import { deleteRequest, getRequest, postRequest } from '../../services/request.service';
import { SpinnerSmall } from '../ui/Spinner';
import './index.scss';


export interface ImageListTileProps {
    file: StorageFile;
    onDelete?: () => void;
}

const ImageListTile = ({ file, onDelete }: ImageListTileProps) => {
    const [image, setImage] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        getRequest<string>(`/storage/image/${file._id}`)
            .then(setImage)
            .catch(() => setError(true))
    }, [file._id]);

    if (error) return null;

    return (
        <div className="image-list-tile">
            {!image && <SpinnerSmall />}
            {!!image && <img src={`data:${file.mimeType};charset=utf-8;base64,${image}`} alt="" />}
            {!!image && !!expanded && (
                <div className="image-list-image-expanded">
                    <img src={`data:${file.mimeType};charset=utf-8;base64,${image}`} alt="" />
                    <Icon icon="mdi:close-thick" className="icon-button icon-large" onClick={() => setExpanded(false)} />
                </div>
            )}
            {!!image && (
                <div className="image-list-action">
                    <Icon icon="mdi:eye" className="icon-button icon-large" onClick={() => setExpanded(true)} />
                    {!!onDelete && <Icon icon="mdi:trash-can-outline" className="icon-button icon-large" onClick={onDelete} />}
                </div>
            )}
        </div>
    )
}

export interface ImageListNewProps {
    column?: boolean;
    storageFiles: string[];
    onChange: (storageFiles: string[]) => void;
}

export const ImageListNew = ({ column, onChange, storageFiles }: ImageListNewProps) => {
    const [images, setImages] = useState<StorageFile[]>([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (!acceptedFiles?.length) return;
            let data = new FormData();
            for (const file of acceptedFiles) {
                data.append('files[]', file);
            }

            postRequest<StorageFile[]>('/storage', data, { headers: { 'content-type': 'multipart/form-data' }, loader: true, errorMessage: 'Une erreur est survenue pendant le téléversement' })
                .then((files) => {
                    onChange([...storageFiles, ...files.map(f => f._id)]);
                    setImages([...images, ...files])
                })
                .catch(() => null);
        },
        maxFiles: 20,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        }
    });

    const handleDelete = async (file: StorageFile) => {
        deleteRequest<StorageFile>(`/storage/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue pendant la suppression' })
            .then(() => setImages(images.filter(i => i._id !== file._id)))
            .catch(() => null);
    };

    return (
        <div className={`image-list ${column ? 'image-list-column' : ''}`}>
            <div {...getRootProps({ className: 'image-list-add' })}>
                <input {...getInputProps()} />
                <Icon icon="mdi:camera-plus" className="icon-xxl" />
                <div>
                    <div>Glissez votre image ici</div>
                    <span>ou</span>
                    <span className="link">recherchez un fichier</span>
                </div>
            </div>
            {images?.map(image => (
                <ImageListTile key={image._id} file={image} onDelete={() => handleDelete(image)} />
            ))}
        </div>
    )
}

export interface ImageListProps {
    url: string;
    entityId: string;
    editable?: boolean;
    column?: boolean;
}

const ImageList = ({ editable, entityId, url, column }: ImageListProps) => {
    const [images, setImages] = useState<StorageFile[]>([]);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: editable ? (acceptedFiles) => {
            if (!acceptedFiles?.length) return;
            let data = new FormData();
            for (const file of acceptedFiles) {
                data.append('files[]', file);
            }
            data.append('entityId', entityId);

            postRequest<StorageFile[]>('/storage', data, { headers: { 'content-type': 'multipart/form-data' }, loader: true, errorMessage: 'Une erreur est survenue pendant le téléversement' })
                .then(() => getImages())
                .catch(() => null);
        } : undefined,
        maxFiles: 20,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/webp': [],
            'image/heic': [],
            'image/jfif': [],
        }
    });

    const getImages = () => getRequest<StorageFile[]>(url).then(setImages).catch(() => null);

    const handleDelete = async (file: StorageFile) => {
        if (!editable) return;
        deleteRequest<StorageFile>(`/storage/${file._id}`, { loader: true, errorMessage: 'Une erreur est survenue pendant la suppression' })
            .then(() => getImages())
            .catch(() => null);
    };

    useEffect(() => {
        getImages();
    }, [url, entityId]);

    return (
        <div className={`image-list ${column ? 'image-list-column' : ''}`}>
            {!!editable && (
                <div {...getRootProps({ className: 'image-list-add' })}>
                    <input {...getInputProps()} />
                    <Icon icon="mdi:camera-plus" className="icon-xxl" />
                    <div>
                        <div>Glissez votre image ici</div>
                        <span>ou</span>
                        <span className="link">recherchez un fichier</span>
                    </div>
                </div>
            )}
            {images?.map(image => (
                <ImageListTile key={image._id} file={image} onDelete={() => handleDelete(image)} />
            ))}
        </div>
    )
}

export default ImageList;