import { ReactNode } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Diagnostic, getFinalGrade } from '../../../models/diagnostic';
import { OuvrageElement } from '../../../models/ouvrage';
import useWorkspace from '../../../services/hooks/use-workspace';
import Visualization from '../Visualization';
import { ElementMarker } from '../Visualization/components/ElementMarkers';
import './index.scss';

interface DiagnosticViewProps {
    diagnostic: Partial<Diagnostic>;
    onElementSelect: (element: OuvrageElement) => void;
    elementSelected?: OuvrageElement;
    children?: ReactNode;
}

const DiagnosticView = ({ diagnostic, onElementSelect, elementSelected, children }: DiagnosticViewProps) => {
    const { ouvrage } = useWorkspace();

    return (
        <ScrollableContent id="diagnostic-view" noScroll noPadding>
            <Visualization ouvrage={ouvrage} onMarkerInit={console.log}>
                {ouvrage.elements.map(element => {
                    const diagnosticElement = diagnostic.elements?.find(e => e.elementId === element._id);

                    return (
                        <ElementMarker
                            key={element._id}
                            element={element}
                            selected={elementSelected?._id === element._id}
                            onClick={() => onElementSelect(element)}
                            className={diagnosticElement ? `element-marker-grade-${getFinalGrade(diagnosticElement)}` : undefined}
                        />
                    )
                })}
            </Visualization>
            {children}
        </ScrollableContent>
    )
}

export default DiagnosticView;