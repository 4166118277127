import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import UsersManagementPanel from '../../../components/entities/UsersManagementPanel';
import Button from '../../../components/ui/Button';
import Header from '../../../components/ui/Header';
import { MenuBarNavigation } from '../../../components/ui/MenuBar';
import UsersPills from '../../../components/ui/UsersPills';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { LOCATION_VALIDATION } from '../../../models/location';
import { Ouvrage, OuvrageElement, OuvrageElementCategory, OuvrageType } from '../../../models/ouvrage';
import useAuth from '../../../services/hooks/use-auth.hook';
import { getRequest, postRequest, putRequest } from '../../../services/request.service';
import { ActionIcon } from '../../../utils/icons';
import OuvrageBlueprintEdit from './components/OuvrageBlueprintEdit';
import OuvrageInformations from './components/OuvrageInformations';
import './index.scss';

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    type: [{ comparator: FormComparator.REQUIRED }],
    ...LOCATION_VALIDATION
};

const DEFAULT_ELEMENT_PONT: OuvrageElement = {
    _id: v4(),
    category: OuvrageElementCategory.STRUCTURE,
    type: 'tablier',
    name: 'Tablier',
    coordinates: {
        x: 0,
        y: 0,
        z: 0
    },
    dimensions: {
        width: 500,
        length: 1500,
        height: 40,
    }
}

const DEFAULT_ELEMENT_MUR: OuvrageElement = {
    _id: v4(),
    category: OuvrageElementCategory.STRUCTURE,
    type: 'mur',
    name: 'Mur',
    coordinates: {
        x: 0,
        y: 0,
        z: 0
    },
    dimensions: {
        width: 200,
        length: 1500,
        height: 300,
    }
}

const DEFAULT_ELEMENT_BUSE: OuvrageElement = {
    _id: v4(),
    category: OuvrageElementCategory.STRUCTURE,
    type: 'buse',
    name: 'Buse',
    dimensions: {
        width: 100,
        height: 100,
        length: 1000,
    },
    coordinates: {
        x: 0,
        y: 0,
        z: 0
    },
    thicknessV: 10,
    thicknessH: 10,
}

const Edit = () => {
    const { ouvrageId, type } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, setChanged, onChange, onChangeMultiple, hasChanged, errors, areFieldsOnError } = useForm<Ouvrage>({});
    const [page, setPage] = useState<string | null>(null);
    const [isUserPanelVisible, setUserPanelVisible] = useState<boolean>(false);

    const pages = useMemo(() => [
        { key: 'general', label: 'Général', error: areFieldsOnError(['name', 'type', ...Object.keys(LOCATION_VALIDATION)]) },
        { key: 'design', label: 'Design' },
    ], [areFieldsOnError]);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Ouvrage>('/ouvrage', entity, {
            successMessage: create ? 'Ouvrage créé avec succès' : 'Ouvrage mis à jour avec succès',
            errorMessage: "Une erreur est survenue lors de l'enregistrement",
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(`/ouvrage/${data._id}?refresh_ouvrage=1`);
                } else {
                    setChanged(false);
                    setEntity(data);
                    navigate(`/ouvrage/${data._id}/editer/${page ?? ''}?refresh_ouvrage=1`);
                }
            })
            .catch(() => null);
    }, [validate, setEntity]);

    useEffect(() => {
        if (type) {
            setEntity({
                type: type as OuvrageType,
                elements: [type === OuvrageType.PONT ? DEFAULT_ELEMENT_PONT : type === OuvrageType.BUSE ? DEFAULT_ELEMENT_BUSE : DEFAULT_ELEMENT_MUR],
                license: user.managedLicense?._id, licensePopulated: user.managedLicense,
                users: [],
                active: true
            });
        } else if (ouvrageId) {
            getRequest<Ouvrage>(`/ouvrage/edit/${ouvrageId}`, {
                errorMessage: 'Une erreur est survenue lors de la récupération de l\'opération.',
                loader: true
            })
                .then(setEntity)
                .catch(() => navigate('/'))
        } else {
            navigate('/');
        }
    }, [ouvrageId]);

    if (entity._id !== ouvrageId) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[{ label: 'Ouvrage', href: '/' }, { label: entity?.name ? 'Editer l\'ouvrage' : 'Nouvel ouvrage' }]}
            >
                <UsersPills users={entity.users} onClick={() => setUserPanelVisible(true)} />
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <MenuBarNavigation pages={pages} setPage={setPage} />
            {page === 'general' && <OuvrageInformations entity={entity} attachInput={attachInput} errors={errors} onChange={onChange} onChangeMultiple={onChangeMultiple} />}
            {page === 'design' && <OuvrageBlueprintEdit entity={entity} attachInput={attachInput} errors={errors} onChange={onChange} onChangeMultiple={onChangeMultiple} />}
            <UsersManagementPanel
                visible={isUserPanelVisible}
                onClose={() => setUserPanelVisible(false)}
                entity={entity}
                onChange={(users) => onChange('users', users)}
            />
        </Fragment >
    )
}

export default Edit;