import { useCallback, useEffect } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import TextInput from "../../../../components/inputs/TextInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvragePileFormProps {
    pile: OuvrageElement;
    onClose: () => void;
    onSubmit: (tablier: OuvrageElement) => void;
    onChange: (pile: OuvrageElement) => void;
    onDelete: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.MIN_LENGTH, compareTo: 3 }],
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.width': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'coordinates.x': [{ comparator: FormComparator.REQUIRED }],
    'coordinates.y': [{ comparator: FormComparator.REQUIRED }],

}

const OuvragePileForm = ({ onClose, pile, onChange, onDelete, onSubmit }: OuvragePileFormProps) => {
    const { entity, attachInput, setEntity, validate, onChangeMultiple } = useForm<OuvrageElement>(pile);

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const element = validate(VALIDATION);
        if (element) {
            onChange(element);
        }
    }, [entity]);

    useEffect(() => {
        if (
            pile.dimensions.length !== entity.dimensions?.length ||
            pile.dimensions.width !== entity.dimensions?.width ||
            pile.dimensions.height !== entity.dimensions?.height ||
            pile.coordinates.x !== entity.coordinates?.x ||
            pile.coordinates.y !== entity.coordinates?.y
        ) {
            onChangeMultiple([
                { field: 'dimensions', value: pile.dimensions },
                { field: 'coordinates', value: pile.coordinates },
            ]);
        }
    }, [pile]);

    return (
        <PanelRight
            title="Pile"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
            onDelete={onDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput {...attachInput('name')} />
            </div>
            <div className="row">
                <div className="input-column">
                    <label htmlFor="length">Longueur *</label>
                    <NumberInput {...attachInput('dimensions.length')} />
                </div>
                <div className="input-column">
                    <label htmlFor="width">Largeur *</label>
                    <NumberInput {...attachInput('dimensions.width')} />
                </div>
                <div className="input-column">
                    <label htmlFor="height">Hauteur *</label>
                    <NumberInput {...attachInput('dimensions.height')} />
                </div>
            </div>
            <div className="column">
                <h4>Position</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="x">Longitudinale *</label>
                        <NumberInput {...attachInput('coordinates.x')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="y">Transversale *</label>
                        <NumberInput {...attachInput('coordinates.y')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Arche gauche</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="arcLeft.rX">Rayon horizontal</label>
                        <NumberInput {...attachInput('arcLeft.rX')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="arcLeft.rY">Rayon vertical</label>
                        <NumberInput {...attachInput('arcLeft.rY')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Arche droit</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="arcRight.rX">Rayon horizontal</label>
                        <NumberInput {...attachInput('arcRight.rX')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="arcRight.rY">Rayon vertical</label>
                        <NumberInput {...attachInput('arcRight.rY')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default OuvragePileForm;