import { useState } from 'react';
import { v4 } from 'uuid';
import ScrollableContent from '../../../../components/ui/ScrollableContent';
import Toolbar from '../../../../components/ui/Toolbar';
import { FormHookReturn } from '../../../../hooks/useForm';
import { CoordinatesXYZ } from '../../../../models/location';
import { Ouvrage, OuvrageElement, OuvrageElementCategories, OuvrageElementCategory, OuvrageElementIcon, OuvrageType } from '../../../../models/ouvrage';
import { replaceInArray } from '../../../../utils/objects';
import Visualization from '../../Visualization';
import VisualizationViewButtons from '../../Visualization/components/VisualizationViewButtons';
import OuvrageBarriereForm from './OuvrageBarriereForm';
import OuvrageBuseForm from './OuvrageBuseForm';
import OuvrageElementForm from './OuvrageElementForm';
import OuvrageMurForm from './OuvrageMurForm';
import OuvragePileForm from './OuvragePileForm';
import OuvragePoutreForm from './OuvragePoutreForm';
import OuvrageTablierForm from './OuvrageTablierForm';

export interface OuvrageBlueprintEditProps extends Pick<FormHookReturn<Ouvrage>, 'entity' | 'onChange' | 'onChangeMultiple' | 'attachInput' | 'errors'> { }

const OuvrageBlueprintEdit = ({ entity, onChange }: OuvrageBlueprintEditProps) => {
    const [elementToEdit, setElementToEdit] = useState<OuvrageElement>();
    const [insertMode, setInsertMode] = useState<{ category: OuvrageElementCategory, type?: string }>();
    const [view, setView] = useState<"front" | "top" | "left" | "right" | "back" | "bottom">('front');

    const createElement = (coordinates: CoordinatesXYZ, category: OuvrageElementCategory, type?: string) => {
        setInsertMode(undefined);
        const element: OuvrageElement = {
            _id: v4(),
            category,
            type,
            dimensions: type === 'barriere' || type === 'glissiere' ? { width: 20, height: 80, length: 200 } : { width: 20, height: 20, length: 20 },
            coordinates
        };
        setElementToEdit(element);
    }

    return (
        <ScrollableContent noPadding noScroll id="ouvrage-blueprint-edit">
            <Toolbar>
                {entity.type === OuvrageType.PONT && (
                    <Toolbar.ToolbarBlock label="Dessiner">
                        <Toolbar.ToolbarButton label="Pile" icon={OuvrageElementIcon.PILE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.STRUCTURE, 'pile')} />
                        <Toolbar.ToolbarButton label="Poutre" icon={OuvrageElementIcon.POUTRE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.STRUCTURE, 'poutre')} />
                        <Toolbar.ToolbarButton label="Barrière" icon={OuvrageElementIcon.BARRIERE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.PROTECTION, 'barriere')} />
                        <Toolbar.ToolbarButton label="Glissière" icon={OuvrageElementIcon.BARRIERE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.PROTECTION, 'glissiere')} />
                    </Toolbar.ToolbarBlock>
                )}
                {entity.type === OuvrageType.MUR && (
                    <Toolbar.ToolbarBlock label="Dessiner">
                        <Toolbar.ToolbarButton label="Barrière" icon={OuvrageElementIcon.BARRIERE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.PROTECTION, 'barriere')} />
                        <Toolbar.ToolbarButton label="Glissière" icon={OuvrageElementIcon.BARRIERE} onClick={() => createElement({ x: 0, y: 0, z: 0 }, OuvrageElementCategory.PROTECTION, 'glissiere')} />
                    </Toolbar.ToolbarBlock>
                )}
                <Toolbar.ToolbarBlock label="Ajouter un élément">
                    {OuvrageElementCategories.map(category => (
                        <Toolbar.ToolbarButton
                            key={category.key}
                            label={category.label}
                            icon={category.icon}
                            onClick={() => setInsertMode({ category: category.key })}
                        />
                    ))}
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Vue">
                    <VisualizationViewButtons view={view} setView={setView} />
                </Toolbar.ToolbarBlock>
            </Toolbar>
            <div id="ouvrage-blueprint-edit-visualization">
                <Visualization
                    ouvrage={entity}
                    view={view}
                    onSelect={setElementToEdit}
                    selected={elementToEdit}
                    onChange={(e) => elementToEdit ? setElementToEdit({ ...elementToEdit, ...e }) : null}
                    onMarker={insertMode ? (coordinates) => createElement(coordinates, insertMode.category, insertMode.type) : undefined}
                />
                {elementToEdit?.type === 'tablier' && (
                    <OuvrageTablierForm
                        tablier={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                    />
                )}
                {elementToEdit?.type === 'pile' && (
                    <OuvragePileForm
                        pile={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                        onDelete={() => {
                            onChange('elements', entity.elements?.filter(e => e._id !== elementToEdit._id));
                            setElementToEdit(undefined);
                        }}
                    />
                )}
                {elementToEdit?.type === 'poutre' && (
                    <OuvragePoutreForm
                        poutre={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                        onDelete={() => {
                            onChange('elements', entity.elements?.filter(e => e._id !== elementToEdit._id));
                            setElementToEdit(undefined);
                        }}
                    />
                )}
                {elementToEdit?.type === 'mur' && (
                    <OuvrageMurForm
                        mur={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                    />
                )}
                {elementToEdit?.type === 'buse' && (
                    <OuvrageBuseForm
                        buse={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                    />
                )}
                {(elementToEdit?.type === 'barriere' || elementToEdit?.type === 'glissiere') && (
                    <OuvrageBarriereForm
                        barriere={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                        onDelete={() => {
                            onChange('elements', entity.elements?.filter(e => e._id !== elementToEdit._id));
                            setElementToEdit(undefined);
                        }}
                    />
                )}
                {!!elementToEdit && !elementToEdit.type && (
                    <OuvrageElementForm
                        element={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onChange={setElementToEdit}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                        onDelete={() => {
                            onChange('elements', entity.elements?.filter(e => e._id !== elementToEdit._id));
                            setElementToEdit(undefined);
                        }}
                    />
                )}
            </div>
        </ScrollableContent >
    )
}
export default OuvrageBlueprintEdit;