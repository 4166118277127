import { floatToPrString } from "../utils/pr";
import { ValuesMinMaxAverage } from "./analysis";
import { License } from "./license";
import { OperationZone, PrCoordinates } from "./location";
import { Lot } from "./lot";
import { Material } from "./material";
import { UserWorkspaceRole } from "./user";

export type OperationDto = {
    _id: string;
    name: string;
    synoptique: number;
    type: OperationType;
    date: Date;
    phase: Phase;
    phases: Phase[];
    postCode?: string;
    zone: OperationZone;
    line: PrCoordinates[];
    borneType?: BorneType;
    referential?: string;
    project?: string;
    way1: WayDto;
    way2?: WayDto;
    businesses: string[];
    license?: string;
    licensePopulated?: License;
    users: UserWorkspaceRole[];
    comment?: string;
    active: boolean;
    compiledAt?: Date;
    favoritedBy?: string[];
};

export type Operation = OperationDto & {
    ways: Way[];
    waysObj: { [_id: string]: Way };
    roads: Road[];
    roadsObj: { [_id: string]: Road };
};

export type WayDto = {
    name: string;
    section?: string;
    prStart: number;
    prEnd: number;
    active: boolean;
    roads: string[];
    roadsPopulated: RoadDto[];
    exits: Exit[];
};

export type Way = WayDto & {
    key: number;
    prStartString: string;
    prEndString: string;
    label: string;
};

export type RoadDto = {
    _id: string;
    name: RoadName;
    description?: string;
    type?: RoadType;
    prStart: number;
    prEnd: number;
    order: number;
    index?: number;
    active: boolean;
};

export type Road = RoadDto & {
    way: number;
    key: string;
    realName: string;
    prStartString: string;
    prEndString: string;
    label: string;
    labelWithoutWay: string;
};

export type Exit = {
    name: string;
    pr: number;
    operation?: string;
};

export interface OperationNetwork {
    operation: Operation;
    lines: PrCoordinates[][];
}

export interface SummaryStatistics {
    granulometrie: Record<number, ValuesMinMaxAverage>;
    permeabilite: ValuesMinMaxAverage;
    vide: ValuesMinMaxAverage;
    pmt: ValuesMinMaxAverage;
    teneurLiantValInd: ValuesMinMaxAverage;
}

export interface Summary {
    material: Material;
    lots: {
        lot: Lot;
        statistics: SummaryStatistics;
    }[];
    statistics: SummaryStatistics;
}

export enum BorneType {
    AUTOROUTE = "autoroute",
    ROUTE = "route",
    AUTRE = "autre",
}

export enum OperationType {
    ROUTE = "route",
    ECHANGEUR = "echangeur",
    AIRE = "aire",
    FERROVIAIRE = "ferroviaire",
    METRO = "metro",
    PORT = "port",
    AEROPORT = "aeroport",
    PLATEFORME = "plateforme",
    SPORT = "sport",
}

export enum Phase {
    ETUDE = "etude",
    PREPARATOIRE = "preparatoire",
    TRAVAUX = "travaux",
    EXPERTISE = "expertise",
}

export enum RoadName {
    VR = "vr",
    VM = "vm",
    VL = "vl",
    BAU = "bau",
    VSVL = "vsvl",
    VSR = "vsr",
    VS = "vs",
    BPV = "bpv",
    AIRE_REPOS = "aire_repos",
    AIRE_SERVICE = "aire_service",
    VDEC = "vdev",
    VACC = "vacc",
}

export const RoadNameLabel: { [key in RoadName]: string } = {
    [RoadName.VR]: "Voie rapide",
    [RoadName.VM]: "Voie médiane",
    [RoadName.VL]: "Voie lente",
    [RoadName.BAU]: "Bande d'arrêt d'urgence",
    [RoadName.VSVL]: "Voie Supplémentaire Voie Lente",
    [RoadName.VSR]: "Voie Supplémentaire en Rampe",
    [RoadName.VS]: "Voie Supplémentaire",
    [RoadName.BPV]: "Barrière Pleine Voies",
    [RoadName.AIRE_REPOS]: "Aire de repos",
    [RoadName.AIRE_SERVICE]: "Aire de service",
    [RoadName.VDEC]: "Voie de décélération",
    [RoadName.VACC]: "Voie d'accélération",
};

export const RoadNames = [
    { key: RoadName.VR, label: RoadNameLabel[RoadName.VR] },
    { key: RoadName.VM, label: RoadNameLabel[RoadName.VM] },
    { key: RoadName.VL, label: RoadNameLabel[RoadName.VL] },
    { key: RoadName.BAU, label: RoadNameLabel[RoadName.BAU] },
    { key: RoadName.VSVL, label: RoadNameLabel[RoadName.VSVL] },
    { key: RoadName.VSR, label: RoadNameLabel[RoadName.VSR] },
    { key: RoadName.VS, label: RoadNameLabel[RoadName.VS] },
    { key: RoadName.BPV, label: RoadNameLabel[RoadName.BPV] },
    { key: RoadName.AIRE_REPOS, label: RoadNameLabel[RoadName.AIRE_REPOS] },
    { key: RoadName.AIRE_SERVICE, label: RoadNameLabel[RoadName.AIRE_SERVICE] },
    { key: RoadName.VDEC, label: RoadNameLabel[RoadName.VDEC] },
    { key: RoadName.VACC, label: RoadNameLabel[RoadName.VACC] },
];

export enum RoadType {
    VOIE = "voie",
    DEMI_VOIE = "demi_voie",
    BRETELLE_ENTREE = "bretelle_entree",
    BRETELLE_SORTIE = "bretelle_sortie",
    PEAGE = "peage",
    AIRE = "aire",
    RUE = "rue",
    AVENUE = "avenue",
    BOULEVARD = "boulevard",
    ROUTE_NATIONALE = "route_nationale",
    ROUTE_DEPARTEMENTALE = "route_departementale",
    VOIE_COMMUNALE = "voie_communale",
    VOIRIE_DIVERSE = "voirie_diverse",
}

export const RoadTypeLabel: { [key in RoadType]: string } = {
    [RoadType.VOIE]: "Voie",
    [RoadType.DEMI_VOIE]: "Demi-voie",
    [RoadType.BRETELLE_ENTREE]: "Bretelle d'entrée",
    [RoadType.BRETELLE_SORTIE]: "Bretelle de sortie",
    [RoadType.PEAGE]: "Péage",
    [RoadType.AIRE]: "Aire",
    [RoadType.RUE]: "Rue",
    [RoadType.AVENUE]: "Avenue",
    [RoadType.BOULEVARD]: "Boulevard",
    [RoadType.ROUTE_NATIONALE]: "Route nationale",
    [RoadType.ROUTE_DEPARTEMENTALE]: "Route départementale",
    [RoadType.VOIE_COMMUNALE]: "Voie communale",
    [RoadType.VOIRIE_DIVERSE]: "Voirie diverse",
};

export const RoadTypes = [
    { key: RoadType.VOIE, label: RoadTypeLabel[RoadType.VOIE] },
    { key: RoadType.DEMI_VOIE, label: RoadTypeLabel[RoadType.DEMI_VOIE] },
    {
        key: RoadType.BRETELLE_ENTREE,
        label: RoadTypeLabel[RoadType.BRETELLE_ENTREE],
    },
    {
        key: RoadType.BRETELLE_SORTIE,
        label: RoadTypeLabel[RoadType.BRETELLE_SORTIE],
    },
    { key: RoadType.PEAGE, label: RoadTypeLabel[RoadType.PEAGE] },
    { key: RoadType.AIRE, label: RoadTypeLabel[RoadType.AIRE] },
    { key: RoadType.RUE, label: RoadTypeLabel[RoadType.RUE] },
    { key: RoadType.AVENUE, label: RoadTypeLabel[RoadType.AVENUE] },
    { key: RoadType.BOULEVARD, label: RoadTypeLabel[RoadType.BOULEVARD] },
    {
        key: RoadType.ROUTE_NATIONALE,
        label: RoadTypeLabel[RoadType.ROUTE_NATIONALE],
    },
    {
        key: RoadType.ROUTE_DEPARTEMENTALE,
        label: RoadTypeLabel[RoadType.ROUTE_DEPARTEMENTALE],
    },
    {
        key: RoadType.VOIE_COMMUNALE,
        label: RoadTypeLabel[RoadType.VOIE_COMMUNALE],
    },
    {
        key: RoadType.VOIRIE_DIVERSE,
        label: RoadTypeLabel[RoadType.VOIRIE_DIVERSE],
    },
];

export enum RoadPosition {
    ROUE_DROITE = "roue_droite",
    ROUE_GAUCHE = "roue_gauche",
    AXE = "axe",
}

export const RoadPositionLabel: { [key in RoadPosition]: string } = {
    [RoadPosition.ROUE_DROITE]: "Roue droite",
    [RoadPosition.ROUE_GAUCHE]: "Roue gauche",
    [RoadPosition.AXE]: "Axe",
};

export const RoadPositionsList = [
    { key: RoadPosition.AXE, label: RoadPositionLabel[RoadPosition.AXE] },
    {
        key: RoadPosition.ROUE_DROITE,
        label: RoadPositionLabel[RoadPosition.ROUE_DROITE],
    },
    {
        key: RoadPosition.ROUE_GAUCHE,
        label: RoadPositionLabel[RoadPosition.ROUE_GAUCHE],
    },
];

export const RoadPositions = [
    RoadPosition.ROUE_DROITE,
    RoadPosition.ROUE_GAUCHE,
    RoadPosition.AXE,
];
export const RoadPossibleNames = [
    ["bau", "arr", "urge", "bande"],
    ["vl", "voie lente", "lent"],
    ["vm", "voie médiane", "méd", "med"],
    ["vr", "voie rapide", "rap"],
];

export const PhaseLabel: { [key in Phase]: string } = {
    [Phase.ETUDE]: "Etude",
    [Phase.PREPARATOIRE]: "Préparatoire",
    [Phase.TRAVAUX]: "Travaux",
    [Phase.EXPERTISE]: "Expertise",
};

export const PhaseColor: { [key in Phase]: string } = {
    [Phase.ETUDE]: "#6003ca",
    [Phase.PREPARATOIRE]: "#e7bc44",
    [Phase.TRAVAUX]: "#3dc51b",
    [Phase.EXPERTISE]: "#f55828",
};

export const Phases = [
    {
        key: Phase.ETUDE,
        label: PhaseLabel[Phase.ETUDE],
        color: PhaseColor[Phase.ETUDE],
        icon: "mdi:circle-slice-2",
    },
    {
        key: Phase.PREPARATOIRE,
        label: PhaseLabel[Phase.PREPARATOIRE],
        color: PhaseColor[Phase.PREPARATOIRE],
        icon: "mdi:circle-slice-4",
    },
    {
        key: Phase.TRAVAUX,
        label: PhaseLabel[Phase.TRAVAUX],
        color: PhaseColor[Phase.TRAVAUX],
        icon: "mdi:circle-slice-6",
    },
    {
        key: Phase.EXPERTISE,
        label: PhaseLabel[Phase.EXPERTISE],
        color: PhaseColor[Phase.EXPERTISE],
        icon: "mdi:circle-slice-8",
    },
];

export const PhasesEtude = [
    { key: Phase.ETUDE, label: PhaseLabel[Phase.ETUDE] },
    { key: Phase.PREPARATOIRE, label: PhaseLabel[Phase.PREPARATOIRE] },
];

export const PhasesTravaux = [
    { key: Phase.TRAVAUX, label: PhaseLabel[Phase.TRAVAUX] },
    { key: Phase.EXPERTISE, label: PhaseLabel[Phase.EXPERTISE] },
];

export const PhaseChartColor: { [key in Phase]: string } = {
    [Phase.ETUDE]: "#397d92",
    [Phase.PREPARATOIRE]: "#faa97a",
    [Phase.TRAVAUX]: "#84a31f",
    [Phase.EXPERTISE]: "#f0c6c5",
};

export const PhaseChartColorTransparency: { [key in Phase]: string } = {
    [Phase.ETUDE]: "rgba(57, 125, 146, 0.7)",
    [Phase.PREPARATOIRE]: "rgba(250, 169, 122, 0.7)",
    [Phase.TRAVAUX]: "rgba(132, 163, 31, 0.7)",
    [Phase.EXPERTISE]: "rgba(240, 198, 197, 0.7)",
};

export enum OperationSynoptiqueType {
    LINEAR = "linear",
    ZONE = "zone",
}

export const OperationSynoptiqueTypes = [
    OperationSynoptiqueType.LINEAR,
    OperationSynoptiqueType.ZONE,
];

export const OperationSubType: Record<
    OperationType,
    {
        key: OperationType;
        label: string;
        type: OperationSynoptiqueType;
        color: string;
        icon: string;
    }
> = {
    route: {
        key: OperationType.ROUTE,
        label: "Route / Autoroute",
        type: OperationSynoptiqueType.LINEAR,
        color: "#494c5d",
        icon: "mdi:road-variant",
    },
    echangeur: {
        key: OperationType.ECHANGEUR,
        label: "Echangeur",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:source-merge",
    },
    aire: {
        key: OperationType.AIRE,
        label: "Aire d'autoroute",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:forest-outline",
    },
    ferroviaire: {
        key: OperationType.FERROVIAIRE,
        label: "Ferroviaire",
        type: OperationSynoptiqueType.LINEAR,
        color: "#494c5d",
        icon: "mdi:train",
    },
    metro: {
        key: OperationType.METRO,
        label: "Métro",
        type: OperationSynoptiqueType.LINEAR,
        color: "#494c5d",
        icon: "mdi:train-variant",
    },
    port: {
        key: OperationType.PORT,
        label: "Port",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:boat",
    },
    aeroport: {
        key: OperationType.AEROPORT,
        label: "Aéroport",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:airport",
    },
    plateforme: {
        key: OperationType.PLATEFORME,
        label: "Plateforme industrielle",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:factory",
    },
    sport: {
        key: OperationType.SPORT,
        label: "Sport",
        type: OperationSynoptiqueType.ZONE,
        color: "#494c5d",
        icon: "mdi:stadium-variant",
    },
};

export const OperationSubTypes = [
    OperationSubType.route,
    OperationSubType.echangeur,
    OperationSubType.aire,
    OperationSubType.ferroviaire,
    OperationSubType.metro,
    OperationSubType.port,
    OperationSubType.aeroport,
    OperationSubType.plateforme,
    OperationSubType.sport,
];

export enum OperationIcon {
    SYNOPTIQUE = "mdi:floor-plan",
    OPERATION = "mdi:developer-board",
    PHASE = "mdi:chart-timeline-variant",
}

// Function
const mapWay = (way: WayDto | undefined, wayNum: number): Way | undefined =>
    way
        ? {
              ...way,
              key: wayNum,
              prStartString: floatToPrString(way?.prStart),
              prEndString: floatToPrString(way?.prEnd),
              label: `Sens ${wayNum} - ${way.name} (${floatToPrString(
                  way?.prStart
              )} à ${floatToPrString(way.prEnd)})`,
          }
        : undefined;

const mapRoad = (road: RoadDto, way: number): Road => ({
    ...road,
    way,
    key: road._id,
    prStartString: floatToPrString(road.prStart),
    prEndString: floatToPrString(road.prEnd),
    realName: `${RoadNameLabel[road.name]}${
        road.index !== undefined ? " " + road.index : ""
    }`,
    label: `Sens ${way} - ${RoadNameLabel[road.name]}${
        road.index !== undefined ? " " + road.index : ""
    } (${floatToPrString(road.prStart)} à ${floatToPrString(road.prEnd)})`,
    labelWithoutWay: `${RoadNameLabel[road.name]}${
        road.index !== undefined ? " " + road.index : ""
    } (${floatToPrString(road.prStart)} à ${floatToPrString(road.prEnd)})`,
});

export const operationDtoToOperation = (dto: OperationDto): Operation => {
    const waysObj = {
        1: mapWay(dto.way1, 1),
        2: mapWay(dto.way2, 2),
    };
    const roads = [
        ...(dto.way1?.roadsPopulated ?? []).map((r) => mapRoad(r, 1)),
        ...(dto.way2?.roadsPopulated ?? []).map((r) => mapRoad(r, 2)),
    ];
    const roadsObj: { [_id: string]: Road & { label: string } } = {};
    for (const r of roads) {
        roadsObj[r._id] = r;
    }

    return {
        ...dto,
        ways: [
            ...(waysObj[1] ? [waysObj[1]] : []),
            ...(waysObj[2] ? [waysObj[2]] : []),
        ],
        waysObj,
        roads,
        roadsObj,
    } as Operation;
};
