// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ouvrage-view {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#ouvrage-view #ouvrage-view-element .panel-right-content, #ouvrage-view #ouvrage-view-panel .panel-right-content {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ouvrage/View/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAGQ;EACI,UAAA;AADZ","sourcesContent":["#ouvrage-view {\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n    height: 100%;\n    overflow: hidden;\n    position: relative;\n\n    & #ouvrage-view-element,\n    & #ouvrage-view-panel {\n        & .panel-right-content {\n            padding: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
