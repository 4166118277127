import { Icon } from '@iconify/react';
import { MouseEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IdentityCardOperation, IdentityCardOuvrage } from '../../components/entities/IdentityCard';
import SearchInput from '../../components/inputs/SearchInput';
import Menu from '../../components/ui/Menu';
import { Operation, OperationType, Phase } from '../../models/operation';
import { Ouvrage, OuvrageType } from '../../models/ouvrage';
import { Role } from '../../models/user';
import useAuth from '../../services/hooks/use-auth.hook';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest, patchRequest } from '../../services/request.service';
import { addToast } from '../../services/slices/ui.slice';
import { WorkspaceType } from '../../services/slices/workspace.slice';
import { ActionIcon } from '../../utils/icons';
import HomeFilters from './components/HomeFilters';
import HomeImage from './components/HomeImage';
import HomeMap from './components/HomeMap';
import './index.scss';

export interface HomeMapFilters {
    sortBy: string;
    sortDirection: number;
    types: string[];
    phases: Phase[];
    businesses: string[];
    inspectionInProgress: boolean;
    keyword: string;
}

interface HomeReducerState {
    operations: Operation[];
    selectedOperation?: Operation;
    ouvrages: Ouvrage[];
    selectedOuvrage?: Ouvrage;
    filters?: Partial<HomeMapFilters>;
}

const INITIAL_REDUCER_STATE: HomeReducerState = {
    operations: [],
    ouvrages: [],
    filters: {
        sortBy: 'date',
        sortDirection: -1,
    }
}

export interface AuthReducerAction {
    type: "setOperations" | "selectOperation" | "setOuvrages" | "selectOuvrage" | "setFilters" | "updateOperation" | "updateOuvrage";
    payload?: {
        operation?: Operation;
        operations?: Operation[];
        ouvrage?: Ouvrage;
        ouvrages?: Ouvrage[];
        filters?: Partial<HomeMapFilters>;
        userId?: string;
    };
}

export const homeReducer = (
    state: HomeReducerState,
    action: AuthReducerAction
): HomeReducerState => {
    switch (action.type) {
        case 'updateOperation':
            if (!action.payload?.operation) return state;

            const operationIndex = state.operations.findIndex((o) => o._id === action.payload?.operation?._id);

            if (operationIndex < 0) return state;

            state.operations[operationIndex] = action.payload.operation;

            return { ...state }
        case 'updateOuvrage':
            if (!action.payload?.ouvrage) return state;

            const ouvrageIndex = state.ouvrages.findIndex((o) => o._id === action.payload?.ouvrage?._id);

            if (ouvrageIndex < 0) return state;

            state.ouvrages[ouvrageIndex] = action.payload.ouvrage;

            return { ...state }
        case 'setOperations':
            if (action.payload?.userId) {
                const favorites: Operation[] = [];
                const operations: Operation[] = [];

                for (const operation of action.payload.operations ?? []) {
                    if (operation.favoritedBy?.includes(action.payload.userId)) {
                        favorites.push(operation);
                    } else {
                        operations.push(operation);
                    }
                }
                return {
                    ...state,
                    operations: [...favorites, ...operations]
                }
            }
            return {
                ...state,
                operations: action.payload?.operations ?? [],

            };
        case 'selectOperation':
            return {
                ...state,
                selectedOperation: action.payload?.operation,
            };
        case 'setOuvrages':
            if (action.payload?.userId) {
                const favorites: Ouvrage[] = [];
                const ouvrages: Ouvrage[] = [];

                for (const ouvrage of action.payload.ouvrages ?? []) {
                    if (ouvrage.favoritedBy?.includes(action.payload.userId)) {
                        favorites.push(ouvrage);
                    } else {
                        ouvrages.push(ouvrage);
                    }
                }
                return {
                    ...state,
                    ouvrages: [...favorites, ...ouvrages]
                }
            }
            return {
                ...state,
                ouvrages: action.payload?.ouvrages ?? [],
            };
        case 'selectOuvrage':
            return {
                ...state,
                selectedOuvrage: action.payload?.ouvrage,
            };
        case 'setFilters':
            return {
                ...state,
                filters: action.payload?.filters
            }
        default:
            return state;
    }
};

const Home = () => {
    const [{
        filters,
        operations,
        selectedOperation,
        ouvrages,
        selectedOuvrage
    }, dispatchReducer] = useReducer(homeReducer, INITIAL_REDUCER_STATE);
    const [isFiltersModalVisible, setFiltersModalVisible] = useState<boolean>(false);
    const resultsRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { workspace } = useWorkspace();

    const handleNew = useCallback((option: { operationType?: OperationType, ouvrageType?: OuvrageType }) => {
        if (option.operationType) {
            if (user.role === Role.SUPER_ADMIN || (user.managedLicense && (user.managedLicense?.operationsCount ?? 0) < user.managedLicense.operationsQuota)) {
                navigate('/operation/creer/' + option.operationType);
            } else if (user.managedLicense && (user.managedLicense?.operationsCount ?? 0) >= user.managedLicense.operationsQuota) {
                dispatch(addToast({ type: 'error', message: 'Le nombre maximum d\'opérations a été atteint' }));
            }
        } else if (option.ouvrageType) {
            if (user.role === Role.SUPER_ADMIN || (user.managedLicense && (user.managedLicense?.ouvragesCount ?? 0) < user.managedLicense.ouvragesQuota)) {
                navigate('/ouvrage/creer/' + option.ouvrageType);
            } else if (user.managedLicense && (user.managedLicense?.ouvragesCount ?? 0) >= user.managedLicense.ouvragesQuota) {
                dispatch(addToast({ type: 'error', message: 'Le nombre maximum d\'ouvrages a été atteint' }));
            }
        }
    }, [user]);

    const handleFavorite = (e: MouseEvent, _id: string) => {
        e.stopPropagation();

        if (workspace === WorkspaceType.CHAUSSES) {
            patchRequest<Operation>(`/operation/${_id}/favorite`, {}, { loader: true })
                .then((operation) => dispatchReducer({ type: 'updateOperation', payload: { operation } }))
                .catch(() => null);
        } else {
            patchRequest<Ouvrage>(`/ouvrage/${_id}/favorite`, {}, { loader: true })
                .then((ouvrage) => dispatchReducer({ type: 'updateOuvrage', payload: { ouvrage } }))
                .catch(() => null);
        }

    }

    const handleSelect = useCallback((entity?: Operation | Ouvrage) => {
        if (entity && resultsRef.current) {
            resultsRef.current.querySelector<HTMLDivElement>(`div[id='home-result-${entity._id}']`)?.scrollIntoView({ behavior: 'smooth' })
        }

        dispatchReducer((entity as any).synoptique
            ? { type: 'selectOperation', payload: { operation: entity as Operation } }
            : { type: 'selectOuvrage', payload: { ouvrage: entity as Ouvrage } });
    }, []);

    useEffect(() => {
        if (workspace === WorkspaceType.CHAUSSES) {
            getRequest<Operation[]>('/operation', { params: filters, errorMessage: 'Une erreur est survenue lors de la récupération des données.', loader: true })
                .then((operations) => dispatchReducer({ type: 'setOperations', payload: { operations, userId: user._id } }))
                .catch(() => dispatchReducer({ type: 'setOperations', payload: { operations: [] } }));
        } else {
            getRequest<Ouvrage[]>('/ouvrage', { params: filters, errorMessage: 'Une erreur est survenue lors de la récupération des données.', loader: true })
                .then((ouvrages) => dispatchReducer({ type: 'setOuvrages', payload: { ouvrages, userId: user._id } }))
                .catch(() => dispatchReducer({ type: 'setOuvrages', payload: { ouvrages: [] } }));
        }

    }, [filters, workspace]);

    return (
        <div id="home">
            <div id="home-list">
                <div id="home-list-header">
                    <div id="home-search">
                        <SearchInput id="home-search" placeholder="Chercher dans les résultats..." minLength={2} value={filters?.keyword} onChange={(keyword) => dispatchReducer({ type: 'setFilters', payload: { filters: { ...filters, keyword } } })} />
                        <Icon icon="mdi:filter-cog-outline" className="icon-button" onClick={() => setFiltersModalVisible(true)} />
                    </div>
                </div>
                <div id="home-list-results" ref={resultsRef}>
                    {workspace === WorkspaceType.CHAUSSES && operations.map(o => (
                        <div
                            key={o._id}
                            id={o._id}
                            className={`home-result-card ${o._id === selectedOperation?._id ? 'selected' : ''}`}
                            onClick={() => dispatchReducer({ type: 'selectOperation', payload: { operation: o } })}
                        >
                            <div className="home-result-card-image">
                                <div>
                                    <Icon icon="mdi:image-remove-outline" className="icon-xl color-silver" />
                                </div>
                            </div>
                            <div className="home-result-card-content">
                                <IdentityCardOperation entity={o} />
                                <Icon icon={o.favoritedBy?.includes(user._id) ? 'mdi:star' : 'mdi:star-outline'} className="icon-button" onClick={(e) => handleFavorite(e, o._id)} />
                                <Menu icon={ActionIcon.VIEW} label="Aller à l'opération" onClick={() => navigate('/operation/' + o._id)} />
                            </div>
                        </div>
                    ))}
                    {workspace === WorkspaceType.OUVRAGES && ouvrages.map(o => (
                        <div
                            key={o._id}
                            id={o._id}
                            className={`home-result-card ${o._id === selectedOuvrage?._id ? 'selected' : ''}`}
                            onClick={() => dispatchReducer({ type: 'selectOuvrage', payload: { ouvrage: o } })}
                        >
                            <HomeImage entityId={o._id} />
                            <div className="home-result-card-content">
                                <IdentityCardOuvrage entity={o} />
                                <Icon icon={o.favoritedBy?.includes(user._id) ? 'mdi:star' : 'mdi:star-outline'} className="icon-button" onClick={(e) => handleFavorite(e, o._id)} />
                                <Menu icon={ActionIcon.VIEW} label="Aller à l'ouvrage" onClick={() => navigate('/ouvrage/' + o._id)} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <HomeMap
                onCreate={user.role === Role.SUPER_ADMIN || !!user.managedLicense ? handleNew : undefined}
                operations={operations}
                selectedOperation={selectedOperation}
                ouvrages={ouvrages}
                selectedOuvrage={selectedOuvrage}
                onSelect={handleSelect}
            />
            {!!isFiltersModalVisible && (
                <HomeFilters
                    filters={filters ?? undefined}
                    onClose={() => setFiltersModalVisible(false)}
                    onSubmit={(filters) => dispatchReducer({ type: 'setFilters', payload: { filters } })}
                />
            )}
        </div>
    );
}

export default Home;