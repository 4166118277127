import IdentityCard from "../../../../components/entities/IdentityCard";
import { SynoptiqueHover } from "../../../../synoptique/Synoptique.class";

export interface SynoptiqueHoverElemensProps {
    elements?: SynoptiqueHover;
}

const SynoptiqueHoverElements = ({ elements }: SynoptiqueHoverElemensProps) => {

    return (
        <div id="synoptique-hover">
            {elements?.map((e) => <IdentityCard key={e._id} entity={e} />)}
        </div>
    )
}
export default SynoptiqueHoverElements;