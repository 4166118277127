import { Fragment } from "react/jsx-runtime";
import Cube from "./VisualizationViewCube";

interface VisualizationViewButtonsProps {
    view: string;
    setView: (view: "front" | "top" | "left" | "right" | "back" | "bottom") => void;
}

const VisualizationViewButtons = ({ view, setView }: VisualizationViewButtonsProps) => {

    return (
        <Fragment>
            <div className={`toolbar-button visualization-view-button ${view === 'front' ? 'active' : ''}`} onClick={() => setView('front')}>
                <Cube side="front" />
                <span>Avant</span>
            </div>
            <div className={`toolbar-button visualization-view-button ${view === 'back' ? 'active' : ''}`} onClick={() => setView('back')}>
                <Cube side="back" />
                <span>Arrière</span>
            </div>
            <div className={`toolbar-button visualization-view-button ${view === 'left' ? 'active' : ''}`} onClick={() => setView('left')}>
                <Cube side="left" />
                <span>Gauche</span>
            </div>
            <div className={`toolbar-button visualization-view-button ${view === 'right' ? 'active' : ''}`} onClick={() => setView('right')}>
                <Cube side="right" />
                <span>Droite</span>
            </div>
            <div className={`toolbar-button visualization-view-button ${view === 'top' ? 'active' : ''}`} onClick={() => setView('top')}>
                <Cube side="top" />
                <span>Dessus</span>
            </div>
            <div className={`toolbar-button visualization-view-button ${view === 'bottom' ? 'active' : ''}`} onClick={() => setView('bottom')}>
                <Cube side="bottom" />
                <span>Dessous</span>
            </div>
        </Fragment>
    )
}
export default VisualizationViewButtons;