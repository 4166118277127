import { CoordinatesXY } from "../models/location";
import { Marker } from "../models/marker";
import { Operation } from "../models/operation";
import { PopulationSynoptique } from "../models/population";
import { Sample } from "../models/sample";
import { getPolygonCentroid } from "../utils/location";
import Synoptique, {
    ActionDoneType,
    LotForSynoptique,
    MarkerForSynoptique,
    PopulationForSynoptique,
    SampleForSynoptique,
    SynoptiqueElements,
} from "./Synoptique.class";

class ZoneSynoptique extends Synoptique {
    style = {
        zone: {
            strokeStyle: "#bec1cc",
            fillStyle: "#828999",
            font: "bold 14px Roboto",
            lineWidth: 2,
        },
    };

    // Canvas zone polygon
    zone: CoordinatesXY[] = [];

    // Limits min and max of zone UTM polygon
    bounds = {
        minX: 0,
        maxX: 0,
        minY: 0,
        maxY: 0,
    };

    // Grid precision in meters
    gridPrecision = 10;

    constructor(
        canvasRef: HTMLCanvasElement,
        operation: Operation,
        onActionDone: ActionDoneType
    ) {
        super(canvasRef, operation, onActionDone);
        this.getSynoptiqueDimensions();
        this.sizeSynoptique();

        if (this.canvasOriginal?.width > 0) {
            while (this.canvas.width < this.canvasOriginal?.width) {
                this.zoomIn();
                this.sizeSynoptique();
            }
        }
    }

    getSynoptiqueDimensions() {
        this.zone = this.operation?.zone?.polygonXY ?? [{ x: 0, y: 0 }];

        this.bounds = {
            minX: Math.min(...this.zone.map((xy) => xy.x)),
            maxX: Math.max(...this.zone.map((xy) => xy.x)),
            minY: Math.min(...this.zone.map((xy) => xy.y)),
            maxY: Math.max(...this.zone.map((xy) => xy.y)),
        };

        // Get synoptique dimensions
        this.synoptique = {
            height:
                (this.grid.height * (this.bounds.maxY - this.bounds.minY)) /
                this.gridPrecision,
            width:
                (this.grid.width * (this.bounds.maxX - this.bounds.minX)) /
                this.gridPrecision,
        };
    }

    getCanvasXY({ x, y }: CoordinatesXY) {
        return {
            x: (this.grid.width * (x - this.bounds.minX)) / this.gridPrecision,
            y:
                this.synoptique.height -
                (this.grid.height * (y - this.bounds.minY)) /
                    this.gridPrecision, // UTM y position starts at bottom
        };
    }

    render() {
        super.render();
        this.drawGrid();
        this.drawZone();
        this.drawElements();
    }

    localizeElements(elements: SynoptiqueElements) {
        let firstElementPosition: CoordinatesXY | null = null;

        this.elements.lots = elements.lots?.map((l) => {
            const polygon =
                l.zone.polygonXY?.map((xy) => this.getCanvasXY(xy)) ?? [];
            return {
                ...l,
                canvasPosition: { x: 0, y: 0 },
                htmlPosition: polygon.length
                    ? getPolygonCentroid(polygon)
                    : { x: 0, y: 0 },
                width: 0,
                height: 0,
                polygon,
            };
        });

        this.elements.melanges = elements.melanges?.map((m) => {
            const polygon = [];
            for (const s of m.samplesPopulated ?? []) {
                if (s?.location?.coordinatesXY) {
                    polygon.push(this.getCanvasXY(s.location.coordinatesXY));
                }
            }
            return { ...m, polygon };
        });

        const localizedElement = (
            e: PopulationSynoptique | Sample | Marker
        ):
            | PopulationForSynoptique
            | SampleForSynoptique
            | MarkerForSynoptique => {
            const canvasPosition = e.location?.coordinatesXY
                ? this.getCanvasXY(e.location?.coordinatesXY)
                : { x: 0, y: 0 };
            return {
                ...e,
                canvasPosition,
                htmlPosition: this.getHtmlPosition(canvasPosition),
                roadName: "",
                roadPositionIndex: 0,
            };
        };

        this.elements.samples = elements.samples
            ?.filter((s) => s.location.coordinatesXY)
            .map(localizedElement) as SampleForSynoptique[];

        this.elements.markers = elements.markers
            ?.filter(
                (s) =>
                    s.location.road &&
                    s.location.roadPosition &&
                    s.location.pr !== undefined
            )
            .map(localizedElement) as MarkerForSynoptique[];

        this.elements.populations = elements.populations
            ?.filter(
                (s) =>
                    s.location.road &&
                    s.location.roadPosition &&
                    s.location.pr !== undefined
            )
            .map(localizedElement) as PopulationForSynoptique[];

        this.firstElementPosition = firstElementPosition;
    }

    drawGrid() {
        this.setStyle(this.baseStyle.grid);

        // Vertical lines
        let x = 0;
        do {
            this.drawLine(x, 0, x, this.canvas.height);
            x += this.withZoomX(this.grid.width);
        } while (x <= this.ctx.canvas.width);

        // Horizontal lines
        let y = 0;

        do {
            this.drawLine(0, y, this.ctx.canvas.width, y);
            y += this.withZoomY(this.grid.height);
        } while (y <= this.canvas.height);
    }

    drawLot(lot: LotForSynoptique) {
        if (!lot.polygon) return;
        const selected = !!this.selectedLayers?.some((l) => l._id === lot._id);
        this.setStyle(
            selected ? this.baseStyle.lotSelected : this.baseStyle.lot
        );
        this.drawPolygon(
            lot.polygon.map((xy) => this.withZoomAndMarginXY(xy)),
            true,
            true
        );

        this.setStyle(this.baseStyle.lotTextRect);
        const text =
            "Lot " + lot.fullLot + " - " + (lot.materialPopulated?.name ?? "");
        const textWidth = this.ctx.measureText(text).width + 10;

        const bounds = {
            minX: Math.min(...lot.polygon.map((xy) => xy.x)),
            maxX: Math.max(...lot.polygon.map((xy) => xy.x)),
            minY: Math.min(...lot.polygon.map((xy) => xy.y)),
            maxY: Math.max(...lot.polygon.map((xy) => xy.y)),
        };
        const center = {
            x: (bounds.maxX + bounds.minX) / 2,
            y: (bounds.maxY + bounds.minY) / 2,
        };

        this.drawRect(
            this.withZoomAndMarginX(center.x) - textWidth / 2,
            this.withZoomAndMarginY(center.y) - 6,
            textWidth,
            14,
            true,
            true
        );

        this.setStyle(
            selected ? this.baseStyle.lotSelectedText : this.baseStyle.lotText
        );
        this.drawText(
            this.withZoomAndMarginX(center.x) - textWidth / 2 + 5,
            this.withZoomAndMarginX(center.y) + 6,
            text
        );
    }

    drawZone() {
        if (this.zone?.length) {
            this.setStyle(this.style.zone);
            this.drawPolygon(
                this.zone.map((xy) =>
                    this.withZoomAndMarginXY(this.getCanvasXY(xy))
                )
            );
        }
    }
}

export default ZoneSynoptique;
