import { Icon } from "@iconify/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { FormBaseProps } from "../../../hooks/useForm";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { getRequest } from "../../../services/request.service";
import { toggleInArray } from "../../../utils/objects";
import Menu from "../../ui/Menu";
import './index.scss';

const MAX_HEIGHT = 306;

type SelectItem<T> = { key: T, label: string };

interface MultipleSelectProps<T> extends FormBaseProps<T[]> {
    items?: SelectItem<T>[];
    endpoint?: string;
    singleResult?: boolean;
}

export const MultipleSelect = <T,>({
    value,
    items,
    endpoint,
    disabled,
    onChange,
    errors,
    singleResult
}: MultipleSelectProps<T>) => {
    const [itemsOrData, setItemsOrData] = useState<SelectItem<T>[]>([]);
    const [isOpened, setOpened] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    const toggleItem = useCallback((key: T) => {
        const _value = toggleInArray(key, value ?? []);
        onChange(_value);
        if (singleResult) setOpened(false);
    }, [onChange, itemsOrData, value, singleResult]);

    useEffect(() => {
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (!isOpened) {
                ref.current.style.maxHeight = "100%";
            } else {
                if (bound.top + MAX_HEIGHT > window.innerHeight) {
                    ref.current.style.bottom = '0';
                    ref.current.style.top = "auto";
                } else {
                    ref.current.style.top = '0';
                    ref.current.style.bottom = "auto";
                }
                ref.current.style.maxHeight = MAX_HEIGHT + "px";
            }
        }
    }, [isOpened, ref]);

    useEffect(() => {
        if (endpoint) {
            getRequest<SelectItem<T>[]>(endpoint).then(setItemsOrData).catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="select">
            <div
                className={`dropdown border-input${isOpened ? " opened" : ""}${!!errors?.length ? " input-error" : ""}`}
                ref={ref}
            >
                <div className="input" onClick={() => setOpened(!isOpened)}>
                    {!singleResult && !!value?.length &&
                        value.map((v, index) => (
                            <Menu
                                key={index}
                                icon="mdi:window-close"
                                label={
                                    itemsOrData?.find((item) => item.key === v)
                                        ?.label ?? ""
                                }
                                onClick={() => toggleItem(v)}
                            />
                        ))}
                    {!!singleResult && !!value?.length && <span>{itemsOrData?.find((item) => item.key === value[0])?.label ?? ""}</span>}
                </div>
                {!!errors?.length && (
                    <div className="form-error">{errors.join(". ")}</div>
                )}
                {!disabled && (
                    <Fragment>
                        <div className="icon-container" onClick={() => setOpened(!isOpened)}>
                            {!isOpened
                                ? <Icon icon="mdi:chevron-down" onClick={() => setOpened(false)} />
                                : <Icon icon="mdi:chevron-up" onClick={() => setOpened(false)} />}
                        </div>
                        {!!itemsOrData?.length && (
                            <div className="dropdown-scroll">
                                {isOpened && <Icon icon="mdi:window-close" className="icon-button sm-show" onClick={() => setOpened(false)} />}
                                {itemsOrData.map((item) => {
                                    const active = !!value?.includes(item.key);

                                    return (
                                        <div
                                            key={String(item.key)}
                                            className={`select-item ${active ? 'active' : ''}`}
                                            onClick={() => toggleItem(item.key)}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            {!!errors?.length && (
                <div className="form-error">{errors.join(". ")}</div>
            )}
        </div>
    );
};


interface SelectProps<T> extends FormBaseProps<T> {
    items?: SelectItem<T>[];
    endpoint?: string;
}

export const Select = <T,>({
    id,
    value,
    items,
    endpoint,
    disabled,
    onChange,
    errors,
}: SelectProps<T>) => {
    const handleChange = useCallback((v?: T[]) => {
        onChange(v?.pop());
    }, [onChange]);

    return (
        <MultipleSelect<T>
            id={id}
            value={value ? [value] : undefined}
            items={items}
            endpoint={endpoint}
            disabled={disabled}
            errors={errors}
            onChange={handleChange}
            singleResult
        />
    );
}

export default Select;