import { useMemo, useState } from 'react';
import { Bubble } from 'react-chartjs-2';
import { Textarea } from '../../../components/inputs/Textarea';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Diagnostic, DIAGNOSTIC_GRADES, DiagnosticElement } from '../../../models/diagnostic';
import { OuvrageElement, OuvrageElementCategory } from '../../../models/ouvrage';
import useWorkspace from '../../../services/hooks/use-workspace';
import { ActionIcon } from '../../../utils/icons';
import { DiagnosticElementForm, DiagnosticElements } from './DiagnosticElements';
import './index.scss';

interface DiagnosticResultsProps {
    diagnostic: Partial<Diagnostic>;
    onElementChange: (element: DiagnosticElement) => void;
    onValidate?: (comment?: string) => void;
    moa?: boolean;
}

const DiagnosticResults = ({ diagnostic, onElementChange, onValidate, moa }: DiagnosticResultsProps) => {
    const { ouvrage } = useWorkspace();
    const [comment, setComment] = useState<string>();
    const [selectedElement, setSelectedElement] = useState<OuvrageElement>();

    const handleSubmitElement = (element: DiagnosticElement) => {
        onElementChange(element);
        setSelectedElement(undefined);
    }

    const statistics = useMemo(() => {
        const elementsCount = ouvrage.elements.length;
        const elementsDiagnosed = diagnostic.elements?.filter(e => e.diagnostic).length ?? 0;
        const elementsS = diagnostic.elements?.filter(e => e.moaGrade ? e.moaSecurity : e.security).length ?? 0;
        const maxGradeDiagnostic = diagnostic.elements?.filter(e => e.grade).map(e => `${e.grade}${e.security === true ? 'S' : ''}`).sort((g1, g2) => g1 < g2 ? 1 : -1)[0] ?? '';
        const maxGradeMoa = diagnostic.elements?.filter(e => e.grade).map(e => e.moaGrade ? `${e.moaGrade}${e.moaSecurity === true ? 'S' : ''}` : `${e.grade}${e.security === true ? 'S' : ''}`).sort((g1, g2) => g1 < g2 ? 1 : -1)[0] ?? '';

        const chartData = [
            {
                label: 'Urgent',
                data: DIAGNOSTIC_GRADES.map(grade => {
                    const count = (diagnostic.elements?.filter(e => e.moaGrade ? e.moaGrade === grade.key && e.moaSecurity : e.grade === grade.key && e.security).length ?? 0);
                    return {
                        x: grade.key,
                        y: 'Urgent',
                        r: count * 10 / elementsDiagnosed,
                        count
                    }
                }).filter(d => !!d.r),
                backgroundColor: '#d94032',
            },
            {
                label: 'Non urgent',
                data: DIAGNOSTIC_GRADES.map(grade => {
                    const count = (diagnostic.elements?.filter(e => e.moaGrade ? e.moaGrade === grade.key && !e.moaSecurity : e.grade === grade.key && !e.security).length ?? 0);
                    return {
                        x: grade.key,
                        y: 'Non urgent',
                        r: count * 10 / elementsDiagnosed,
                        count
                    }
                }).filter(d => !!d.r),
                backgroundColor: '#004949',
            },
        ];

        return {
            elementsDiagnosed,
            elementsDiagnosedPercentage: Math.round(elementsDiagnosed * 100 / elementsCount),
            elementsS,
            elementsSPercentage: Math.round(elementsS * 100 / elementsCount),
            maxGradeDiagnostic,
            maxGradeMoa,
            chartData
        }
    }, [diagnostic]);

    return (
        <ScrollableContent id="diagnostic-edit-results">
            {!!onValidate && (
                <Card title="Validation">
                    <span className="info">
                        L'ensemble des éléments ont été inspectés. Ajoutez un commentaire si nécesaire et validez votre saisie
                    </span>
                    <div className="input-column">
                        <label htmlFor="comment">Commentaire</label>
                        <Textarea rows={3} placeholder="Expliquer les raisons" value={comment} onChange={setComment} id="diagnosticComment" />
                    </div>
                    <div className="row justify-end">
                        <Button icon={ActionIcon.SEND} label="Terminer la saisie et envoyer les résultats" onClick={() => onValidate(comment)} />
                    </div>
                </Card>
            )}
            <Card>
                <div className="row sm-column">
                    <div className="col lg6 sm12">
                        <h4>Résultats</h4>
                        <div className="row">
                            <label>Eléments diagnostiqués :</label>
                            <div>{statistics.elementsDiagnosed} ({statistics.elementsDiagnosedPercentage}%)</div>
                        </div>
                        <div className="row">
                            <label>Eléments notés 'S' :</label>
                            <div>{statistics.elementsS} ({statistics.elementsSPercentage}%)</div>
                        </div>
                        <div className="row">
                            <label>Note finale diagnostiqueur :</label>
                            <div>{statistics.maxGradeDiagnostic}</div>
                        </div>
                        <div className="row">
                            <label>Note finale MOA :</label>
                            <div>{statistics.maxGradeMoa}</div>
                        </div>
                    </div>
                    <div className="col lg6 sm12">
                        <Bubble
                            options={{
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                    tooltip: {
                                        callbacks: {
                                            label: (item) => `Nombre d'éléments: ${(item.raw as any)?.count}`
                                        }
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'category',
                                        labels: DIAGNOSTIC_GRADES.map(g => g.key),
                                        offset: true,
                                    },
                                    y: {
                                        type: 'category',
                                        labels: ['Urgent', 'Non urgent'],
                                        offset: true,
                                    },
                                }
                            }}
                            data={{ datasets: statistics.chartData }}
                        />
                    </div>
                </div>
            </Card>
            <DiagnosticElements
                title="Structure"
                elements={ouvrage.elements.filter(e => e.category === OuvrageElementCategory.STRUCTURE)}
                diagnosticElements={diagnostic.elements}
                onEdit={setSelectedElement}
                moa={moa}
            />
            <DiagnosticElements
                title="Protection"
                elements={ouvrage.elements.filter(e => e.category === OuvrageElementCategory.PROTECTION)}
                diagnosticElements={diagnostic.elements}
                onEdit={setSelectedElement}
                moa={moa}
            />
            <DiagnosticElements
                title="Equipements"
                elements={ouvrage.elements.filter(e => e.category === OuvrageElementCategory.EQUIPMENT)}
                diagnosticElements={diagnostic.elements}
                onEdit={setSelectedElement}
                moa={moa}
            />
            {!!selectedElement && (
                <DiagnosticElementForm
                    element={selectedElement}
                    diagnosticElement={diagnostic.elements?.find(e => e.elementId === selectedElement._id)}
                    onClose={() => setSelectedElement(undefined)}
                    onSubmit={handleSubmitElement}
                    moa={moa}
                />
            )}
        </ScrollableContent>
    )
}

export default DiagnosticResults;