import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EntityCard from '../../../components/entities/EntityCard';
import { UsersManagementList } from '../../../components/entities/UsersManagementPanel';
import DatePickerInput from '../../../components/inputs/DatePicker';
import Switch from '../../../components/inputs/Switch';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import MenuBar from '../../../components/ui/MenuBar';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { Diagnostic, DIAGNOSTIC_GRADES, DiagnosticStatus, DiagnosticStatusList } from '../../../models/diagnostic';
import { OperationIcon } from '../../../models/operation';
import { OuvrageElement } from '../../../models/ouvrage';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest, postRequest, putRequest } from '../../../services/request.service';
import { formatDate } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import { replaceInArray } from '../../../utils/objects';
import { DiagnosticElementForm } from './DiagnosticElements';
import DiagnosticResults from './DiagnosticResults';
import DiagnosticView from './DiagnosticView';
import './index.scss';

const VALIDATION = {
    date: [{ comparator: FormComparator.REQUIRED }],
};

const DiagnosticEdit = () => {
    const { ouvrage } = useWorkspace();
    const { page, diagnosticId } = useParams();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, setChanged, onChange, hasChanged } = useForm<Diagnostic>({});
    const [mode, setMode] = useState<string>('data');
    const [elementToEdit, setElementToEdit] = useState<OuvrageElement>();

    const handleSubmit = useCallback((close?: boolean) => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Diagnostic>('/diagnostic', entity, {
            successMessage: create ? 'Diagnostic créé avec succès' : 'Diagnostic mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, setEntity]);

    const statistics = useMemo(() => {
        const elementsCount = ouvrage.elements.length;
        const elementsDiagnosed = entity.elements?.filter(e => e.diagnostic).length ?? 0;
        const elementsS = entity.elements?.filter(e => e.moaGrade ? e.moaSecurity : e.security).length ?? 0;
        const maxGradeDiagnostic = entity.elements?.filter(e => e.grade).map(e => `${e.grade}${e.security === true ? 'S' : ''}`).sort((g1, g2) => g1 < g2 ? 1 : -1)[0] ?? '';
        const maxGradeMoa = entity.elements?.filter(e => e.grade).map(e => e.moaGrade ? `${e.moaGrade}${e.moaSecurity === true ? 'S' : ''}` : `${e.grade}${e.security === true ? 'S' : ''}`).sort((g1, g2) => g1 < g2 ? 1 : -1)[0] ?? '';

        const chartData = [
            {
                label: 'Urgent',
                data: DIAGNOSTIC_GRADES.map(grade => {
                    const count = (entity.elements?.filter(e => e.moaGrade ? e.moaGrade === grade.key && e.moaSecurity : e.grade === grade.key && e.security).length ?? 0);
                    return {
                        x: grade.key,
                        y: 'Urgent',
                        r: count * 10 / elementsDiagnosed,
                        count
                    }
                }).filter(d => !!d.r),
                backgroundColor: '#d94032',
            },
            {
                label: 'Non urgent',
                data: DIAGNOSTIC_GRADES.map(grade => {
                    const count = (entity.elements?.filter(e => e.moaGrade ? e.moaGrade === grade.key && !e.moaSecurity : e.grade === grade.key && !e.security).length ?? 0);
                    return {
                        x: grade.key,
                        y: 'Non urgent',
                        r: count * 10 / elementsDiagnosed,
                        count
                    }
                }).filter(d => !!d.r),
                backgroundColor: '#004949',
            },
        ];

        return {
            elementsDiagnosed,
            elementsDiagnosedPercentage: Math.round(elementsDiagnosed * 100 / elementsCount),
            elementsS,
            elementsSPercentage: Math.round(elementsS * 100 / elementsCount),
            maxGradeDiagnostic,
            maxGradeMoa,
            chartData
        }
    }, [entity]);

    useEffect(() => {
        if (diagnosticId !== 'creer') {
            getRequest<Diagnostic>(`/diagnostic/${diagnosticId}${page === 'dupliquer' ? '/duplicate' : ''}`, { errorMessage: 'Une erreur est survenue lors de la récupération du diagnostic.', loader: true })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else {
            setEntity({
                status: DiagnosticStatus.PLANNED,
                active: true,
                ouvrage: ouvrage._id
            });
        }
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: ouvrage?.name, href: `/ouvrage/${ouvrage?._id}` },
                    { label: 'Diagnostics', href: `/ouvrage/${ouvrage?._id}/diagnostics` },
                    { label: entity?._id ? `Diagnostic du ${entity.date ? formatDate(entity.date) : ''}` : 'Nouveau diagnostic' }
                ]}
                withBorder
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            {!!entity.status && [DiagnosticStatus.DONE, DiagnosticStatus.MOA_DONE].includes(entity.status) && (
                <MenuBar>
                    <MenuBar.Item
                        label="Informations"
                        onClick={() => setMode('data')}
                        active={mode === 'data'}
                        icon="mdi:form-outline"
                    />
                    <MenuBar.Item
                        label="Vue"
                        onClick={() => setMode('view')}
                        active={mode === 'view'}
                        icon={OperationIcon.SYNOPTIQUE}
                    />
                    <MenuBar.Item
                        label="Résultats"
                        onClick={() => setMode('results')}
                        active={mode === 'results'}
                        icon="mdi:file-graph"
                    />
                </MenuBar>
            )}
            {mode === 'data' && (
                <ScrollableContent
                    side={<EntityCard entity={entity} attachInput={attachInput} />}
                >
                    <Card title="Informations générales">
                        <div className="row">
                            <div className="input-column">
                                <label htmlFor="date">Date *</label>
                                <DatePickerInput withIcon {...attachInput('date')} />
                            </div>
                            <div className="input-column">
                                <label htmlFor="status">Statut</label>
                                <Switch className="form-switch" items={DiagnosticStatusList} {...attachInput('status')} />
                            </div>
                        </div>
                    </Card>
                    <UsersManagementList title="Diagnostiqueurs" users={entity.users} onChange={(users) => onChange('users', users)} type="subcontractor" noRole />
                </ScrollableContent>
            )}
            {mode === 'view' && <DiagnosticView diagnostic={entity} onElementSelect={setElementToEdit} elementSelected={elementToEdit} />}
            {mode === 'results' && <DiagnosticResults diagnostic={entity} onElementSelect={setElementToEdit} moa />}
            {!!elementToEdit && (
                <DiagnosticElementForm
                    element={elementToEdit}
                    diagnosticElement={entity.elements?.find(e => e.elementId === elementToEdit._id)}
                    onClose={() => setElementToEdit(undefined)}
                    onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1.elementId === e2.elementId, true))}
                    moa
                />
            )}
        </Fragment>
    )
}

export default DiagnosticEdit;