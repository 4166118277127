import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDataSheet from "react-datasheet";
import 'react-datasheet/lib/react-datasheet.css';
import NumberInput from "../../../components/inputs/NumberInput";
import PrInput from "../../../components/inputs/PrInput";
import { Modal } from "../../../components/ui/Modal";
import useForm, { FormComparator } from "../../../hooks/useForm";
import { RoadPosition, RoadPositionLabel, RoadPossibleNames } from "../../../models/operation";
import { Population, PopulationData, PopulationDataHeader } from "../../../models/population";
import useWorkspace from "../../../services/hooks/use-workspace";
import {
    formatNumber,
    formatRoadPosition
} from "../../../utils/format";
import { floatToPrString, prToFloat } from "../../../utils/pr";
import "./index.scss";

const VALIDATION_AUTOFILL = {
    prStart: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    prEnd: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.PR }],
    step: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_INTEGER }],
}

interface PossibleRoads { [key: string]: { name: string, possibleNames?: string[] } }

interface PopulationAutoFill {
    prStart: number;
    prEnd: number;
    step: number;
}

interface PopulationCell {
    value?: string | number;
    renderValue?: string | number;
    className?: string;
    readOnly?: boolean;
    hint?: string;
};

interface PopulationRoadDataSheetProps {
    population: Partial<Population>;
    data?: PopulationData[];
    onSubmit: (data: PopulationData[]) => void;
    onClose: () => void;
}
const PopulationRoadDataSheet = ({
    population,
    data,
    onSubmit,
    onClose
}: PopulationRoadDataSheetProps) => {
    const { operation } = useWorkspace();
    const [gridHeader, setGridHeader] = useState<{ value: string, readOnly: boolean }[]>([]);
    const [gridData, setGridData] = useState<PopulationCell[][]>([]);
    const [possibleRoads, setPossibleRoads] = useState<PossibleRoads>({});
    const [lastEnteredRoadId, setLastEnteredRoadId] = useState<string | null>(null);
    const [isAutofillModalVisible, setAutofillModalVisible] = useState<boolean>(false);
    const { attachInput, validate, setEntity } = useForm<PopulationAutoFill>({ step: 50 });
    const [hasError, setHasError] = useState<boolean>(false);

    const prMin = useMemo(() => Math.min(population.lotPopulated?.zone.prStart ?? 0, population.lotPopulated?.zone.prEnd ?? 0), [population]);
    const prMax = useMemo(() => Math.max(population.lotPopulated?.zone.prStart ?? 0, population.lotPopulated?.zone.prEnd ?? 0), [population]);

    const handleSubmit = useCallback(() => {
        if (hasError) return;

        const _data = [];
        for (const row of gridData) {
            // Filter incomplete lines
            if (
                row.length < 4 ||
                row[0].value === undefined ||
                row[0].value === "" ||
                !row[1].value ||
                !row[2].value ||
                row[3].value === undefined ||
                row[3].value === ""
            ) {
                continue;
            }

            _data.push({
                location: {
                    pr: Number(row[0].value),
                    road: String(row[1].value),
                    roadPosition: row[2].value as RoadPosition,
                    way: population.lotPopulated?.way ? Number(population.lotPopulated?.way) : 1,
                },
                value: Number(row[3].value),
                problematic: false,
            });
        }

        onSubmit(_data);
        onClose();
    }, [onSubmit, onClose, hasError, gridData, population]);

    const getRoad = useCallback((value: string) => {
        const valueLower = value.toLowerCase();
        for (const _id in possibleRoads) {
            if (
                possibleRoads[_id].name?.toLowerCase() === valueLower ||
                possibleRoads[_id].possibleNames?.some(
                    (name) => valueLower === name || valueLower.includes(name)
                )
            ) {
                return _id;
            }
        }
        return "VALEUR INCORRECTE";
    }, [possibleRoads]);

    const getCellValue = useCallback((value: string | number | null, col: number) => {
        if (value === "") return value;
        switch (col) {
            case 0:
                const pr = prToFloat(String(value));
                return isNaN(pr) ? "VALEUR INCORRECTE" : pr;
            case 1:
                return getRoad(String(value));
            case 2:
                return formatRoadPosition(String(value));
            case 3:
                const formatterNumber = formatNumber(value);
                return isNaN(formatterNumber)
                    ? "VALEUR INCORRECTE"
                    : formatterNumber;
            default:
                return value;
        }
    }, [getRoad]);

    const checkPrError = useCallback((value: number) =>
        (prMin && value < prMin) || (prMax && value > prMax)
        , [prMin, prMax]);


    const getRenderValue = useCallback((value: string | number | null | undefined, col: number, _possibleRoads?: PossibleRoads) => {
        switch (col) {
            case 0:
                return floatToPrString(Number(value));
            case 1:
                return _possibleRoads
                    ? _possibleRoads[String(value)]?.name ?? undefined
                    : possibleRoads[String(value)]?.name ?? value ?? undefined;
            case 2:
                return RoadPositionLabel[String(value) as RoadPosition] ?? value ?? undefined;
            default:
                return value ?? undefined;
        }
    }, [possibleRoads]);

    const valueRenderer = useCallback((cell: PopulationCell, row: number, col: number) => {
        if (row === 0 || cell.value === "") return cell.value;

        return cell.renderValue ?? getRenderValue(cell.value ?? null, col);
    }, [getRenderValue]);

    const handleCellChange = useCallback((change: { cell?: any; row: number; col: number; value: string | number | null; }, _gridData: PopulationCell[][], isAddition?: boolean) => {
        const gridDataRow = change.row - 1;
        const gridDataCol = change.col;
        let gridValueFormatted = getCellValue(change.value, gridDataCol);

        if (gridDataRow >= _gridData.length) {
            _gridData.push([
                { value: "" },
                {
                    value: lastEnteredRoadId ?? "",
                    renderValue: getRenderValue(lastEnteredRoadId, 1),
                },
                { value: "axe", renderValue: "Axe" },
                { value: "" },
            ]);
        }

        if (gridDataCol === 2 && !gridValueFormatted && isAddition) {
            gridValueFormatted = "axe";
        } else if (gridDataCol === 1) {
            if (!gridValueFormatted && isAddition) {
                if (lastEnteredRoadId) {
                    gridValueFormatted = lastEnteredRoadId;
                }
            } else if (gridValueFormatted) {
                setLastEnteredRoadId(String(gridValueFormatted));
            }
        }

        const isError =
            (gridDataCol === 0 &&
                gridValueFormatted &&
                checkPrError(Number(gridValueFormatted))) ||
            gridValueFormatted === "VALEUR INCORRECTE";

        _gridData[gridDataRow][gridDataCol] = {
            ..._gridData[gridDataRow][gridDataCol],
            value: gridValueFormatted ?? undefined,
            renderValue: getRenderValue(gridValueFormatted, change.col),
            className: isError ? "error" : "",
        };
    }, [getCellValue, getRenderValue]);

    const onCellsChanged = useCallback((changes: { cell?: any; row: number; col: number; value: string | number | null; }[] = [], additions: { row: number; col: number; value: string | number | null; }[] = []) => {
        const _gridData = [...gridData];

        for (const change of changes) {
            handleCellChange(change, _gridData);
        }

        for (const change of additions ?? []) {
            handleCellChange(change, _gridData, true);
        }

        setGridData(_gridData);
    }, [gridData, handleCellChange]);

    const handleAutofill = useCallback(() => {
        const entity = validate(VALIDATION_AUTOFILL);

        if (!entity) return;

        const _gridData = [];
        if (entity.prStart < entity.prEnd) {
            for (let i = entity.prStart; i < entity.prEnd; i += entity.step / 1000) {
                _gridData.push([
                    { value: i, renderValue: getRenderValue(i, 0) },
                    {
                        value: lastEnteredRoadId ?? "",
                        renderValue: getRenderValue(lastEnteredRoadId, 1),
                    },
                    { value: "axe", renderValue: "Axe" },
                    { value: "" },
                ]);
            }
        } else {
            for (let i = entity.prStart; i > entity.prEnd; i += -entity.step / 1000) {
                _gridData.push([
                    { value: i, renderValue: getRenderValue(i, 0) },
                    {
                        value: lastEnteredRoadId ?? "",
                        renderValue: getRenderValue(lastEnteredRoadId, 1),
                    },
                    { value: "axe", renderValue: "Axe" },
                    { value: "" },
                ]);
            }
        }
        setGridData(_gridData);
        setAutofillModalVisible(false);
    }, [validate, getRenderValue, lastEnteredRoadId, population]);

    useEffect(() => {
        setHasError(
            gridData.some((row) => row.some((col) => col.className === "error"))
        );
    }, [gridData]);

    useEffect(() => {
        const _possibleRoads: PossibleRoads = {};
        for (const road of operation.roads) {
            if (population.lotPopulated?.roads.includes(road._id)) {
                _possibleRoads[road._id] = {
                    name: road.realName,
                    possibleNames: RoadPossibleNames.find((names) =>
                        names.some((name) => road.name.toLowerCase().includes(name))
                    ),
                };
            }
        }
        setPossibleRoads(_possibleRoads);
        setLastEnteredRoadId(operation.roads?.length ? operation.roads[0]._id : null);

        setGridHeader([
            { value: "PR", readOnly: true },
            { value: "Voie", readOnly: true },
            { value: "Emplacement", readOnly: true },
            { value: population.type ? PopulationDataHeader[population.type] : '', readOnly: true },
        ]);
        setGridData(
            (data ?? []).map((d) => [
                {
                    value: d.location?.pr,
                    renderValue: getRenderValue(d.location?.pr ?? null, 0),
                    className: d.location?.pr !== undefined && checkPrError(d.location?.pr) ? "error" : "",
                },
                {
                    value: d.location?.road,
                    renderValue: getRenderValue(
                        d.location?.road ?? null,
                        1,
                        _possibleRoads
                    ),
                    className:
                        d.location?.road === "VALEUR INCORRECTE" || (d.location?.road && !_possibleRoads[d.location.road]) ? "error" : "",
                },
                {
                    value: d.location?.roadPosition,
                    renderValue: getRenderValue(d.location?.roadPosition ?? null, 2),
                    className:
                        (d.location?.roadPosition as string) === "VALEUR INCORRECTE"
                            ? "error"
                            : "",
                },
                {
                    value: d.value,
                    renderValue: getRenderValue(d.value, 3),
                    className: String(d.value) === "VALEUR INCORRECTE" ? "error" : "",
                },
            ])
        );
    }, [population, data, operation]);

    useEffect(() => {
        if (!data?.length) {
            setEntity({
                prStart: prMin ?? 0,
                prEnd: prMax ?? 0,
                step: 50,
            });
            setAutofillModalVisible(true);
        }
    }, [population, data]);

    return (
        <Modal
            size="large"
            title="Saisie de données"
            className="population-data-modal"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: hasError, onClick: handleSubmit },
            ]}
        >
            <div className="population-data-form-roads">
                <strong>Voie(s) du lot : </strong>
                <div>{Object.keys(possibleRoads).map((_id) => <div key={_id}>{possibleRoads[_id]?.name}</div>)}</div>
            </div>
            <ReactDataSheet<PopulationCell>
                className="population-datasheet"
                data={[
                    gridHeader,
                    ...gridData,
                    [
                        { value: "" },
                        { value: "" },
                        { value: "" },
                        { value: "" },
                    ],
                ]}
                valueRenderer={valueRenderer}
                onContextMenu={(e, cell, i, j) =>
                    cell.readOnly ? e.preventDefault() : null
                }
                attributesRenderer={(cell: any) =>
                    cell.hint ? { "data-hint": cell.hint } : {}
                }
                onCellsChanged={onCellsChanged}
            />
            {isAutofillModalVisible && (
                <Modal
                    title="Auto-remplissage"
                    className="modal-autofill"
                    actions={[
                        { label: 'Ne pas remplir', color: 'secondary', onClick: () => setAutofillModalVisible(false) },
                        { label: 'Remplir', color: 'primary', onClick: handleAutofill },
                    ]}
                >
                    <p className="info">
                        Auto-remplir le tableau de données : renseigner les PR
                        de début et de fin, et le pas souhaité. Pour un
                        auto-remplissage décroissant, le PR de début doit être
                        supérieur à celui de fin.
                    </p>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="prStart">PR de début</label>
                            <PrInput {...attachInput('prStart')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="prEnd">PR de fin</label>
                            <PrInput {...attachInput('prEnd')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="city">Pas de (mètres)</label>
                            <NumberInput {...attachInput('step')} />
                        </div>
                    </div>
                </Modal>
            )}
        </Modal>
    );
};

export default PopulationRoadDataSheet;