import { Icon } from '@iconify/react';
import { Fragment, ReactNode, useMemo } from 'react';
import { Analysis, getCharacValue, setCharacValue } from '../../models/analysis';
import { MaterialCharacteristicElementRequirement } from '../../models/material';
import NumberInput from '../inputs/NumberInput';
import { SwitchYesNo } from '../inputs/Switch';
import './index.scss';

export interface AnalysisTableProps {
    children: ReactNode;
}

const AnalysisTable = ({ children }: AnalysisTableProps) => {

    return (
        <div className="analysis-table-container">
            <table className="analysis-table">
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
}

export interface AnalysisTableSeparatorProps {
    label: string;
    colsPan: number;
}

const AnalysisTableSeparator = ({ label, colsPan }: AnalysisTableSeparatorProps) =>
    <tr className="analysis-table-separator"><td>{label}</td><td colSpan={colsPan + 1} /></tr>;

export interface AnalysisTableRowProps {
    children: ReactNode;
    sticky?: boolean;
    label?: string;
    subLabel?: string;
    min?: number;
    max?: number;
    objectif?: number;
    className?: string;
}

const AnalysisTableRow = ({ children, sticky, label, subLabel, min, max, objectif, className }: AnalysisTableRowProps) => {

    const minMaxObjectif = useMemo(() => (min !== undefined || max !== undefined || objectif !== undefined) && (
        <div>
            {(min !== undefined || max !== undefined) && (
                <div>
                    {min !== undefined && (
                        <Fragment>
                            <span>{min}</span>
                            <Icon icon="mdi:chevron-right" className="icon-xs" />
                        </Fragment>
                    )}
                    <span>x</span>
                    {max !== undefined && (
                        <Fragment>
                            <Icon icon="mdi:chevron-right" className="icon-xs" />
                            <span>{max}</span>
                        </Fragment>
                    )}
                </div>
            )}
            {objectif !== undefined && (
                <div><Icon icon="mdi:target-arrow" className="icon-xs" />{objectif}</div>
            )}
        </div>
    ), [min, max, objectif]);

    return (
        <tr className={`${className ?? ''} ${sticky ? 'analysis-table-row-sticky' : ''}`}>
            <td>
                <div className="analysis-table-label">
                    <div>{label}</div>
                    <div>{subLabel}</div>
                </div>
                {minMaxObjectif}
            </td>
            {children}
            <td className="analysis-table-spacer" />
        </tr>
    );
}

export interface AnalysisTableCellProps {
    isEditing?: boolean;
    value?: ReactNode;
    children?: ReactNode;
}

const AnalysisTableCell = ({ children, value, isEditing }: AnalysisTableCellProps) => (
    <td className={isEditing ? 'analysis-table-editing' : undefined}>{isEditing ? children : <span>{value}</span>}</td>
);

export interface AnalysisTableCharacteristicsProps {
    entities: { _id?: string, analysisPopulated?: Partial<Analysis>, isEditing: boolean }[];
    characteristics: MaterialCharacteristicElementRequirement[];
    onChange: (index: number, analysisPopulated: Partial<Analysis>) => void;
}

const AnalysisTableCharacteristics = ({ characteristics, entities, onChange }: AnalysisTableCharacteristicsProps) => {
    return (
        <Fragment>
            {characteristics.map((c) => !c.seuils?.length ? (
                <AnalysisTable.Row
                    key={c.key}
                    label={c.label}
                    min={c.requirement && !Array.isArray(c.requirement) && (c.requirement as any).min !== undefined && (c.requirement as any).min !== null ? (c.requirement as any).min as number : undefined}
                    max={c.requirement && !Array.isArray(c.requirement) && (c.requirement as any).max !== undefined && (c.requirement as any).max !== null ? (c.requirement as any).max as number : undefined}
                    objectif={c.requirement && !Array.isArray(c.requirement) && (c.requirement as any).objectif !== undefined && (c.requirement as any).objectif !== null ? (c.requirement as any).objectif as number : undefined}
                >
                    {entities.map((entity, index) => (
                        <AnalysisTable.Cell key={entity._id ?? 'new-entity'} isEditing={entity.isEditing} value={!c.isBoolean ? getCharacValue(entity.analysisPopulated ?? {}, c) : getCharacValue(entity.analysisPopulated ?? {}, c) === 1 ? 'Oui' : 'None'}>
                            {c.isBoolean ? (
                                <SwitchYesNo
                                    id={index + c.key}
                                    className="form-switch"
                                    value={!!getCharacValue(entity.analysisPopulated ?? {}, c)}
                                    onChange={(v) => onChange(index, setCharacValue(entity.analysisPopulated ?? {}, c.key, v ? 1 : 0))}
                                />
                            ) : (
                                <NumberInput id={index + c.key} value={getCharacValue(entity.analysisPopulated ?? {}, c)} onChange={(v) => onChange(index, setCharacValue(entity.analysisPopulated ?? {}, c.key, v))} />
                            )}
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
            ) : (
                <Fragment key={c.key}>
                    {c.seuils.map((s, i) => (
                        <AnalysisTable.Row
                            key={c.key + s.seuil}
                            label={c.label}
                            subLabel={`${s.seuil}${c.seuilUnit ? ' ' + c.seuilUnit : ''}`}
                            min={s.min !== undefined && s.min !== null ? s.min as number : undefined}
                            max={s.max !== undefined && s.max !== null ? s.max as number : undefined}
                            objectif={s.objectif !== undefined && s.objectif !== null ? s.objectif as number : undefined}
                        >
                            {entities.map((entity, index) => (
                                <AnalysisTable.Cell key={entity._id ?? 'new-entity'} isEditing={entity.isEditing} value={getCharacValue(entity.analysisPopulated ?? {}, c, s.seuil)}>
                                    <NumberInput id={index + c.key + c.seuil} value={getCharacValue(entity.analysisPopulated ?? {}, c, s.seuil)} onChange={(v) => onChange(index, setCharacValue(entity.analysisPopulated ?? {}, c.key, v, s.seuil))} />
                                </AnalysisTable.Cell>
                            ))}
                        </AnalysisTable.Row>
                    ))}
                </Fragment>
            ))}
        </Fragment>
    )
}


export interface AnalysisTableActionsProps {
    entities: { _id?: string, analysisPopulated?: Partial<Analysis>, isEditing: boolean }[];
    actions?: { icon: string, label?: string, onClick: (index: number) => void }[];
    actionsEditing?: { icon: string, label?: string, onClick: (index: number) => void }[];
}

const AnalysisTableActions = ({ entities, actions, actionsEditing }: AnalysisTableActionsProps) => {
    return (
        <tr className="analysis-table-actions">
            <td />
            {entities.map((entity, index) => (
                <td key={entity._id ?? 'new-entity'}>
                    <div>
                        {(entity.isEditing ? actions : actionsEditing)?.map(a => (
                            <div key={a.icon} onClick={() => a.onClick(index)}><Icon icon={a.icon} />{a.label}</div>
                        ))}
                    </div>
                </td>
            ))}
            <td className="analysis-table-spacer" />
        </tr>
    )
}

AnalysisTable.Separator = AnalysisTableSeparator;
AnalysisTable.Row = AnalysisTableRow;
AnalysisTable.Cell = AnalysisTableCell;
AnalysisTable.Characteristics = AnalysisTableCharacteristics;
AnalysisTable.Actions = AnalysisTableActions;

export default AnalysisTable;