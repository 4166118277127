import { Fragment, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import List, { FilterType, ListColumns } from "../../../components/data/List";
import Tag from "../../../components/ui/Tag";
import { Marker } from "../../../models/marker";
import { Phase, RoadPositionLabel, RoadPositionsList } from "../../../models/operation";
import { SampleLabels } from "../../../models/sample";
import { RevisionStatus, RevisionStatusList } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { formatDate } from "../../../utils/format";
import { floatToPrString } from "../../../utils/pr";
import MarkerDetail from "./MarkerDetail";

interface MarkerListProps {
    phase: Phase;
}

const MarkerList = ({ phase }: MarkerListProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const navigate = useNavigate();
    const [selectedMarker, setSelectedMarker] = useState<Marker | null>(null);
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const handleClose = useCallback((refresh?: boolean) => {
        setSelectedMarker(null);
        if (refresh) {
            const refreshParam = URLSearchParams.get('refresh_list');
            URLSearchParams.set('refresh_list', refreshParam ? String(Number(refreshParam) + 1) : '1');
            setURLSearchParams(URLSearchParams);
        }
    }, [URLSearchParams]);

    return (
        <Fragment>
            <List<Marker>
                columns={[
                    { key: 'name', label: 'Nom' },
                    { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                    ...(operation.synoptique === 1
                        ? [
                            { key: 'location.way', label: 'Sens', mapper: (element) => element?.location?.way ? operation.waysObj[element?.location?.way]?.label : '' },
                            { key: 'location.roadPopulated.name', label: 'Voie', mapper: (element) => element?.location?.road ? operation.roadsObj[element?.location?.road]?.labelWithoutWay : '' },
                            { key: 'location.roadPosition', label: 'Position', mapper: (element) => element.location?.roadPosition ? RoadPositionLabel[element.location?.roadPosition] : '' },
                            { key: 'location.pr', label: 'PR', mapper: (element) => floatToPrString(element.location.pr) },
                        ] : [
                            { key: 'location.location', label: 'Localisation' },
                        ]) as ListColumns<Marker>[],
                    { key: 'date', label: 'Date', mapper: (element) => formatDate(element.date) },
                ]}
                dataEndpoint={`/marker/operation/${operation._id}/${phase}`}
                crudEndpoint="/marker"
                withSearch
                baseUrl={`/operation/${operation._id}/${phase}/point-d-interet`}
                actions={{
                    comment: workspacePermissions.write,
                    edit: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    view: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    activate: workspacePermissions.administrate,
                    duplicate: workspacePermissions.write,
                    delete: workspacePermissions.administrate
                }}
                onView={workspacePermissions.read ? setSelectedMarker : undefined}
                initialPagination={{ sortBy: 'date', sortDirection: -1 }}
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                    ...(operation.synoptique === 1
                        ? [
                            { field: 'prMin', label: 'PR minimum', type: FilterType.PR },
                            { field: 'prMax', label: 'PR maximum', type: FilterType.PR },
                            { field: 'road', label: 'Voies', type: FilterType.MULTIPLE_SELECT, items: operation.roads },
                            { field: 'roadPosition', label: 'Position', type: FilterType.MULTIPLE_SELECT, items: RoadPositionsList },
                        ] : []),
                ]}
                buttons={[{ label: SampleLabels.marker.create, onClick: () => navigate(`/operation/${operation._id}/${phase}/point-d-interet/creer`) }]}
            />
            <MarkerDetail id={selectedMarker?._id ?? undefined} onClose={handleClose} />
        </Fragment>
    )
}

export default MarkerList;