import { useCallback, useEffect } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import TextInput from "../../../../components/inputs/TextInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvrageBarriereFormProps {
    barriere: OuvrageElement;
    onClose: () => void;
    onSubmit: (barriere: OuvrageElement) => void;
    onChange: (barriere: OuvrageElement) => void;
    onDelete: () => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.MIN_LENGTH, compareTo: 3 }],
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'coordinates.x': [{ comparator: FormComparator.REQUIRED }],
    'coordinates.y': [{ comparator: FormComparator.REQUIRED }],

}

const OuvrageBarriereForm = ({ onClose, barriere, onChange, onDelete, onSubmit }: OuvrageBarriereFormProps) => {
    const { entity, attachInput, onChangeMultiple, validate } = useForm<OuvrageElement>(barriere);

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const element = validate(VALIDATION);
        if (element) {
            onChange(element);
        }
    }, [entity]);

    useEffect(() => {
        if (
            barriere.coordinates.x !== entity.coordinates?.x ||
            barriere.coordinates.y !== entity.coordinates?.y ||
            barriere.coordinates.z !== entity.coordinates?.z ||
            barriere.dimensions.length !== entity.dimensions?.length ||
            barriere.dimensions.width !== entity.dimensions?.width ||
            barriere.dimensions.height !== entity.dimensions?.height
        ) {
            onChangeMultiple([{ field: 'coordinates', value: barriere.coordinates }, { field: 'dimensions', value: barriere.dimensions }]);
        }
    }, [barriere]);

    return (
        <PanelRight
            title={entity.type === 'glissiere' ? 'Glissière' : 'Barrière'}
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
            onDelete={onDelete}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput {...attachInput('name')} />
            </div>
            <div className="column">
                <h4>Dimensions</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="length">Longueur *</label>
                        <NumberInput {...attachInput('dimensions.length')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="height">Hauteur *</label>
                        <NumberInput {...attachInput('dimensions.height')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Position</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="x">Longitudinale *</label>
                        <NumberInput {...attachInput('coordinates.x')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="y">Transversale *</label>
                        <NumberInput {...attachInput('coordinates.y')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default OuvrageBarriereForm;