import { Fragment, useCallback, useEffect, useState } from "react";
import AnalysisTable from "../../../components/AnalysisTable";
import OptionsMenu from "../../../components/data/OptionsMenu";
import NumberInput from "../../../components/inputs/NumberInput";
import Select from "../../../components/inputs/Select";
import Switch, { SwitchYesNo } from "../../../components/inputs/Switch";
import { Textarea } from "../../../components/inputs/Textarea";
import TextInput from "../../../components/inputs/TextInput";
import ModalDelete from "../../../components/ui/Modal/ModalDelete";
import { Analysis } from "../../../models/analysis";
import { CoucheTypes } from "../../../models/lot";
import { getAnalysisCharac, Material, MaterialCharacteristicElementRequirement, TypeGranulats, UnknownMaterialCharacteristics } from "../../../models/material";
import { Phase } from "../../../models/operation";
import { Fissure, Layer, Sample, SampleType } from "../../../models/sample";
import { ListItem } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { getRequest } from "../../../services/request.service";
import { LayerColors } from "../../../synoptique/Synoptique.class";
import { ActionIcon } from "../../../utils/icons";
import { changeFieldValue } from "../../../utils/objects";

export interface SampleLayersProps {
    sample: Partial<Sample>;
    onChange: (l: Layer[]) => void;
}

const SampleLayers = ({ sample, onChange }: SampleLayersProps) => {
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [producers, setProducers] = useState<ListItem[]>([]);
    const [layerToDelete, setLayerToDelete] = useState<number | undefined>();
    const { operation } = useWorkspace();
    const withMaterialChoice = !sample?.lot;
    const isEtude = sample.phase && [Phase.ETUDE, Phase.PREPARATOIRE].includes(sample.phase);
    const isSingleLayer = sample?.type === SampleType.PRELEVEMENT || !!sample?.lot;

    const handleChange = useCallback((index: number, field: string, value: any) => {
        if (!sample?.layers?.length || index < 0 || index > sample.layers.length) return;

        const layers = [...sample.layers];
        layers[index] = changeFieldValue(layers[index], field, value);

        onChange(layers);
    }, [sample, onChange]);

    const handleDelete = useCallback((index: number) => {
        if (!sample.layers?.length || sample.layers.length <= 1 || index < 0 || index > sample.layers?.length) return;
        const layers = [...sample.layers ?? []]
        layers.splice(index, 1);
        onChange(layers);
    }, [sample, onChange]);

    const handleDuplicate = useCallback((index: number) => {
        if (!sample.layers?.length || index < 0 || index > sample.layers?.length) return;
        const layers = [...sample.layers ?? []]
        layers.splice(index + 1, 0, { ...layers[index], order: Math.max(...(sample?.layers ?? []).map(l => l.order)) + 1, analysis: undefined, analysisPopulated: undefined, melange: undefined, comment: undefined, problematic: false, collage: true, fissure: undefined, fracture: undefined });
        onChange(layers);
    }, [sample, onChange]);

    const handleMove = useCallback((index: number, move: number) => {
        if (!sample.layers?.length || index < 0 || index > sample.layers?.length || (index + move) < 0 || (index + move) > sample.layers?.length) return;

        const layers = [...sample.layers ?? []]
        var temp = layers[index];
        layers[index] = layers[index + move];
        layers[index + move] = temp;
        onChange(layers);
    }, [sample, onChange]);

    useEffect(() => {
        if (sample.lotPopulated?.material) {
            getRequest<Material>(`/material/${sample.lotPopulated?.material}`)
                .then((material) => {
                    setCharacteristics(getAnalysisCharac(material));

                    if (sample.layers?.some(l => !l.analysisPopulated?.business)) {
                        onChange(sample.layers?.map(l => ({
                            ...l,
                            analysisPopulated: {
                                ...l.analysisPopulated,
                                business: l.analysisPopulated?.business ?? material.business
                            } as Analysis
                        }
                        )) ?? []);
                    }
                })
                .catch(() => setCharacteristics([]));
        } else {
            setCharacteristics(UnknownMaterialCharacteristics as MaterialCharacteristicElementRequirement[])
        }
    }, [sample.lotPopulated?.material]);

    useEffect(() => {
        getRequest<ListItem[]>(`/business/list/producer`)
            .then(setProducers)
            .catch(() => null)
    }, []);

    if (!sample.layers?.length) return null;

    return (
        <Fragment>
            <AnalysisTable>
                <AnalysisTable.Separator colsPan={sample.layers?.length} label="Informations" />
                <AnalysisTable.Row label="Couche" className="analysis-table-row-sticky">
                    {sample.layers.map((layer, index) => (
                        <AnalysisTable.Cell key={layer.order} isEditing={!isSingleLayer} value="Couche unique">
                            <div className="sample-layer-header">
                                <div style={{ backgroundColor: LayerColors[layer.order] ?? '#d94032' }} />
                                <span>Couche {layer.order + 1}</span>
                                <OptionsMenu
                                    onDelete={() => layer.analysis ? setLayerToDelete(index) : handleDelete}
                                    onDuplicate={() => handleDuplicate(index)}
                                    actions={[
                                        { onClick: () => handleMove(index, -1), icon: "mdi:chevron-left", label: "Déplacer à gauche" },
                                        { onClick: () => handleMove(index, 1), icon: "mdi:chevron-right", label: "Déplacer à droite" },
                                        ...(!isSingleLayer ? [
                                            { onClick: () => console.log(index, -1), icon: ActionIcon.ADD, label: "Créer à gauche" },
                                            { onClick: () => console.log(index, 1), icon: ActionIcon.ADD, label: "Créer à droite" }
                                        ] : []),
                                    ]}
                                />
                            </div>
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Epaisseur">
                    {sample.layers.map((layer, index) => (
                        <AnalysisTable.Cell key={layer.order} isEditing={true}>
                            <NumberInput id={layer.order + 'thickness'} value={layer.thickness} onChange={(v) => handleChange(index, 'thickness', v)} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>

                {isEtude && (
                    <Fragment>
                        <AnalysisTable.Row label="Produit supposé">
                            {sample.layers.map((layer, index) => (
                                <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                    <TextInput id={index + 'supposedMaterial'} value={layer?.supposedMaterial} onChange={(v) => handleChange(index, 'supposedMaterial', v)} />
                                </AnalysisTable.Cell>
                            ))}
                        </AnalysisTable.Row>
                        <AnalysisTable.Row label="Type de couche">
                            {sample.layers.map((layer, index) => (
                                <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                    <Select id={index + 'typeCouche'} items={CoucheTypes} value={layer.typeCouche} onChange={(v) => handleChange(index, 'typeCouche', v)} />
                                </AnalysisTable.Cell>
                            ))}
                        </AnalysisTable.Row>
                        <AnalysisTable.Row label="Type de granulat">
                            {sample.layers.map((layer, index) => (
                                <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                    <Select id={index + 'typeGranulat'} items={TypeGranulats} value={layer.typeGranulat} onChange={(v) => handleChange(index, 'typeGranulat', v)} />
                                </AnalysisTable.Cell>
                            ))}
                        </AnalysisTable.Row>
                    </Fragment>
                )}
                {!isEtude && (
                    <AnalysisTable.Row label="Produit">
                        {sample.layers.map((layer, index) => (
                            <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                <Select
                                    id={index + 'material'}
                                    value={layer?.material ?? ''}
                                    disabled={!withMaterialChoice}
                                    endpoint={`/material/list/product/${operation?._id}`}
                                    onChange={(material) => handleChange(index, 'material', material)}
                                />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                {!!sample.lotPopulated?.material && (
                    <AnalysisTable.Row label="Producteur">
                        {sample.layers.map((layer, index) => (
                            <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                <Select id={index + 'business'} items={producers} value={layer.analysisPopulated?.business} onChange={(v) => handleChange(index, 'analysisPopulated.business', v)} />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                <AnalysisTable.Separator colsPan={sample.layers.length} label="Caractéristiques" />
                <AnalysisTable.Characteristics entities={sample.layers.map(l => ({ ...l, isEditing: true }))} characteristics={characteristics} onChange={(index, analysis) => handleChange(index, 'analysis', analysis)} />
                <AnalysisTable.Separator colsPan={sample.layers.length} label="Pathologies" />
                {sample?.type === SampleType.CAROTTE && (
                    <AnalysisTable.Row label="Collage">
                        {sample.layers.map((layer, index) => (
                            <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                <SwitchYesNo
                                    id={index + 'collage'}
                                    className="form-switch"
                                    value={!!layer?.collage}
                                    onChange={(v) => handleChange(index, 'collage', v)}
                                />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                <AnalysisTable.Row label="Fracture">
                    {sample.layers.map((layer, index) => (
                        <AnalysisTable.Cell key={layer.order} isEditing={true}>
                            <Switch<Fissure | undefined>
                                id={index + 'fracture'}
                                className="form-switch"
                                items={[{ key: Fissure.TOTAL, label: 'Totale' }, { key: Fissure.PARTIAL, label: 'Partielle' }, { key: undefined, label: 'Non' }]}
                                value={layer?.fracture ?? undefined}
                                onChange={(v) => handleChange(index, 'fracture', v)}
                            />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Fissure">
                    {sample.layers.map((layer, index) => (
                        <AnalysisTable.Cell key={layer.order} isEditing={true}>
                            <Switch<Fissure | undefined>
                                id={index + 'fissure'}
                                className="form-switch"
                                items={[{ key: Fissure.TOTAL, label: 'Totale' }, { key: Fissure.PARTIAL, label: 'Partielle' }, { key: undefined, label: 'Non' }]}
                                value={layer?.fissure ?? undefined}
                                onChange={(v) => handleChange(index, 'fissure', v)}
                            />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                {!isSingleLayer && (
                    <AnalysisTable.Row label="Couche problématique">
                        {sample.layers.map((layer, index) => (
                            <AnalysisTable.Cell key={layer.order} isEditing={true}>
                                <SwitchYesNo
                                    id={index + 'problematic'}
                                    className="form-switch"
                                    value={!!layer.problematic || layer.collage === false || !!layer.fissure || !!layer.fracture}
                                    disabled={!isEtude || !!layer.analysisPopulated?.problematic || layer.collage === false || !!layer.fissure || !!layer.fracture}
                                    onChange={(v) => handleChange(index, 'problematic', v)}
                                />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                <AnalysisTable.Row label="Commentaire">
                    {sample.layers.map((layer, index) => (
                        <AnalysisTable.Cell key={layer.order} isEditing={true}>
                            <Textarea id={index + 'comment'} value={sample.comment} onChange={(v) => handleChange(index, 'comment', v)} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
            </AnalysisTable>
            {layerToDelete !== undefined && (
                <ModalDelete onCancel={() => setLayerToDelete(undefined)} onSubmit={() => handleDelete(layerToDelete)} />
            )}
        </Fragment>
    )
}

export default SampleLayers;