import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@iconify/react";
import { Fragment, useCallback, useEffect, useState } from 'react';
import { v4 } from "uuid";
import OptionsMenu from '../../../components/data/OptionsMenu';
import PrInput from '../../../components/inputs/PrInput';
import TextInput from '../../../components/inputs/TextInput';
import Card from '../../../components/ui/Card';
import IconLink from '../../../components/ui/IconLink';
import Menu from "../../../components/ui/Menu";
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { FormComparator, FormHookReturn } from '../../../hooks/useForm';
import { OperationDto, RoadDto, RoadNameLabel, RoadTypes } from '../../../models/operation';
import { ActionIcon } from "../../../utils/icons";
import { floatToPrString } from '../../../utils/pr';
import Exits from './Exits';
import './index.scss';
import RoadComponent from './Road';

export type RoadWithSections = { order: number, sections: RoadDto[] }

const roadsToRoadsWithSections = (roads: RoadDto[]) => {
    const roadsWithSections: RoadWithSections[] = [];

    for (const road of roads) {
        const index = roadsWithSections.findIndex(r => r.order === road.order);
        if (index >= 0) {
            roadsWithSections[index].sections.push(road);
        } else {
            roadsWithSections.push({ order: road.order!, sections: [road] });
        }
    }

    return roadsWithSections.sort((r1, r2) => r1.order > r2.order ? 1 : -1);
}

const roadsWithSectionsToRoads = (roadsWithSections: RoadWithSections[]): Partial<RoadDto>[] => {
    const roads: Partial<RoadDto>[] = [];

    for (const roadWithSection of roadsWithSections) {
        roads.push(...roadWithSection.sections.map(section => ({ ...section, order: roadWithSection.order })));
    }

    return roads;
}

const RoadItem = ({ roadWithSection, onAction }: { roadWithSection: RoadWithSections, onAction: (action: 'duplicate' | 'edit' | 'activate' | 'delete', order: number, index?: number) => void }) => {
    const { attributes, listeners, setNodeRef, transform } = useSortable({ id: roadWithSection.order + 1 });

    return (
        <div
            className="way-roads-block"
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform)
            }}
        >
            <Icon icon="mdi:drag" {...attributes} {...listeners} />
            <div className="way-roads-sections">
                {roadWithSection.sections?.map((section, index) => (
                    <div className="way-roads-row" key={index}>
                        <div><strong>{RoadNameLabel[section.name]}{section.index !== undefined ? ' ' + section.index : ''}</strong></div>
                        <div>{RoadTypes.find(r => r.key === section.type)?.label ?? ''}</div>
                        <div>{floatToPrString(section.prStart)}</div>
                        <div>{floatToPrString(section.prEnd)}</div>
                    </div>
                ))}
            </div>
            <div className="way-roads-action">
                <OptionsMenu
                    onEdit={() => onAction('edit', roadWithSection.order)}
                    onDuplicate={() => onAction('duplicate', roadWithSection.order)}
                    onDelete={() => onAction('delete', roadWithSection.order)}
                />
            </div>
        </div>
    );
}

interface WayProps extends Pick<FormHookReturn<OperationDto>, 'entity' | 'onChange' | 'attachInput'> {
    way: 1 | 2
}

const WayComponent = ({
    entity,
    onChange,
    attachInput,
    way = 1,
}: WayProps) => {
    const [selectedRoad, setSelectedRoad] = useState<RoadWithSections | null>(null);
    const [roadsWithSections, setRoadsWithSections] = useState<RoadWithSections[]>([]);
    const [selectedExitIndex, setSelectedExitIndex] = useState<number | null>(null);
    const sensors = useSensors(useSensor(PointerSensor));

    const handleDuplicateWay = useCallback(() => {
        const newWay = {
            prStart: entity?.way1?.prStart,
            prEnd: entity?.way1?.prEnd,
            roadsPopulated: [...entity?.way1?.roadsPopulated ?? []].reverse().map((r, i) => ({ ...r, _id: undefined, order: i }))
        }
        onChange('way2', newWay);
    }, [onChange, entity]);

    const handleRoadsChange = useCallback((roads: Partial<RoadDto>[]) => onChange(`way${way}.roadsPopulated`, roads), [onChange, way]);

    const handleRoadWithSectionsChange = (roadWithSection: RoadWithSections) => {
        const _roadsWithSections = [...roadsWithSections];
        if (roadWithSection.order >= 0) {
            _roadsWithSections[roadWithSection.order] = roadWithSection;
        } else {
            _roadsWithSections.push({ order: _roadsWithSections.length, sections: roadWithSection.sections });
        }

        setRoadsWithSections(_roadsWithSections);
        handleRoadsChange(roadsWithSectionsToRoads(_roadsWithSections));
        setSelectedRoad(null);
    }

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        if (!roadsWithSections?.length) return;
        const { active, over } = event;

        if (over !== null && active.id !== over.id) {
            const oldIndex = roadsWithSections.findIndex(l => (l.order + 1) === active.id);
            const newIndex = roadsWithSections.findIndex(l => (l.order + 1) === over.id);

            const _roadsWithSections = arrayMove(roadsWithSections, oldIndex, newIndex);
            handleRoadsChange(roadsWithSectionsToRoads(_roadsWithSections.map((r, i) => ({ ...r, order: i }))));
        }
    }, [handleRoadsChange, roadsWithSections]);

    const handleRoadAction = (action: 'duplicate' | 'edit' | 'delete' | 'activate', order: number, index?: number) => {
        if (order >= 0 && order < roadsWithSections.length) {
            switch (action) {
                case 'activate':
                    if (index === undefined) return;
                    const _roads = [...roadsWithSections];
                    _roads[order].sections[index].active = !_roads[order].sections[index].active;
                    handleRoadsChange(roadsWithSectionsToRoads(_roads));
                    break;
                case 'edit':
                    setSelectedRoad(roadsWithSections[order]);
                    break;
                case 'duplicate':
                    setSelectedRoad({ order: -1, sections: (roadsWithSections[order].sections ?? []).map(s => ({ ...s, _id: v4() })) });
                    break;
                case 'delete':
                    handleRoadsChange(roadsWithSectionsToRoads(roadsWithSections.filter((r, i) => i !== order).map((r, i) => ({ ...r, order: i }))));
                    break;
                default:
            }
        }
    }

    const handleExitAction = useCallback((action: 'edit' | 'delete', index: number) => {
        switch (action) {
            case 'edit':
                setSelectedExitIndex(index);
                break;
            case 'delete':
                const _exits = [...(entity[`way${way}`]?.exits ?? [])];
                _exits.splice(index, 1);
                onChange(`way${way}.exits`, _exits);
                break;
            default:
        }
    }, [entity, onChange, way]);

    useEffect(() => {
        setRoadsWithSections(roadsToRoadsWithSections(entity?.[`way${way}`]?.roadsPopulated ?? []));
    }, [entity]);

    return (
        <ScrollableContent id="operation-edit-way">
            <Card
                title="Configuration"
                actions={<Fragment>
                    {way === 1 && !entity.way2 && (
                        <IconLink
                            type="copy"
                            label="Dupliquer le sens"
                            onClick={() => handleDuplicateWay()}
                        />
                    )}
                </Fragment>}
            >
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="name">Sens *</label>
                        <TextInput {...attachInput(`way${way}.name`)} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="prStart">PR de début *</label>
                        <PrInput
                            {...attachInput(`way${way}.prStart`)}
                            placeholder="Format 'km+hm'"
                            onBlurValidationError={[
                                { comparator: FormComparator.PR },
                                ...(entity?.[`way${way}`]?.prEnd ? [{ comparator: FormComparator.PR_GREATER, compareTo: entity[`way${way}`]?.prEnd! - 250, message: 'La longueur du sens ne peut dépasser 250km' }] : [])
                            ]}
                            onBlurValidationWarning={way === 2
                                ? [{ comparator: FormComparator.PR_EQUAL, compareTo: entity?.way1?.prStart, message: 'Le PR est différent de celui du sens 1' }]
                                : []
                            }
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor="prEnd">PR de fin *</label>
                        <PrInput
                            {...attachInput(`way${way}.prEnd`)}
                            placeholder="Format 'km+hm'"
                            onBlurValidationError={[
                                { comparator: FormComparator.PR },
                                ...(entity?.[`way${way}`]?.prStart ? [{ comparator: FormComparator.PR_LESSER, compareTo: entity[`way${way}`]?.prStart! + 250, message: 'La longueur du sens ne peut dépasser 250km' }] : [])
                            ]}
                            onBlurValidationWarning={way === 2
                                ? [{ comparator: FormComparator.PR_EQUAL, compareTo: entity?.way1?.prEnd, message: 'Le PR est différent de celui du sens 1' }]
                                : []
                            }
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor="section">Section</label>
                        <TextInput {...attachInput(`way${way}.section`)} />
                    </div>
                </div>
            </Card>
            <Card title="Voies" actions={<Menu label="Ajouter une voie" icon={ActionIcon.ADD} onClick={() => setSelectedRoad({ order: -1, sections: [] })} />}>
                <div className="way-roads-content">
                    <div className="way-roads-row" id="way-roads-header">
                        <div>Nom</div>
                        <div>Type</div>
                        <div>PR de début</div>
                        <div>PR de fin</div>
                    </div>
                    <div id="way-roads-list">
                        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                            <SortableContext items={(roadsWithSections ?? [])?.map(r => r.order + 1)} strategy={verticalListSortingStrategy}>
                                {roadsWithSections.map((roadWithSection, i) =>
                                    <RoadItem key={`roadWithSection-${roadWithSection.order}`} roadWithSection={roadWithSection} onAction={handleRoadAction} />
                                )}
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            </Card>
            <Card title="Sorties" actions={<Menu label="Ajouter une sortie" icon={ActionIcon.ADD} onClick={() => setSelectedExitIndex(-1)} />}>
                <table className="simple-table">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>PR</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {entity?.[`way${way}`]?.exits?.map((e, index) => (
                            <tr key={`${e.name}${e.pr}`}>
                                <td>{e.name}</td>
                                <td>{floatToPrString(e.pr)}</td>
                                <td>
                                    <OptionsMenu
                                        onEdit={() => handleExitAction('edit', index)}
                                        onDelete={() => handleExitAction('delete', index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
            {selectedRoad && entity?.[`way${way}`] && (
                <RoadComponent
                    sections={selectedRoad.sections}
                    order={selectedRoad.order}
                    way={entity[`way${way}`]!}
                    onClose={() => setSelectedRoad(null)}
                    onSubmit={handleRoadWithSectionsChange}
                />
            )}
            {selectedExitIndex !== null && entity?.[`way${way}`] && (
                <Exits
                    index={selectedExitIndex}
                    way={entity[`way${way}`]!}
                    onClose={() => setSelectedExitIndex(null)}
                    onSubmit={(exits) => { onChange(`way${way}.exits`, exits); setSelectedExitIndex(null); }}
                />
            )}
        </ScrollableContent>
    );
}

export default WayComponent;