import ImageList from "../../../../components/ImageList";
import Card from "../../../../components/ui/Card";
import PanelRight from "../../../../components/ui/PanelRight";
import { OuvrageElement } from "../../../../models/ouvrage";
import { OuvrageMarker } from "../../../../models/ouvrage-marker";
import useWorkspace from "../../../../services/hooks/use-workspace";


export interface OuvrageViewElementProps {
    element: OuvrageElement;
    markers: OuvrageMarker[];
    onSelectMarker: (m: OuvrageMarker) => void;
    onClose: () => void;
}

const OuvrageViewElement = ({ element, onClose, markers, onSelectMarker }: OuvrageViewElementProps) => {
    const { ouvrage } = useWorkspace();

    return (
        <PanelRight
            id="ouvrage-view-element"
            title={element.name}
            actions={[
                { color: 'secondary', label: 'Fermer', onClick: onClose }
            ]}
        >
            {!!markers?.length && (
                <div className="input-column">
                    <label htmlFor="location">Point d'intérêts</label>
                    <Card id="ouvrage-view-element-markers">
                        {markers.map(m => (
                            <div key={m._id} onClick={() => onSelectMarker(m)}>
                                <span>{m.name}</span>
                                <span>{m.description}</span>
                            </div>
                        ))}
                    </Card>
                </div>
            )}
            <div className="input-column">
                <label htmlFor="location">Photographies</label>
                <ImageList column entityId={element._id} url={`/ouvrage/${ouvrage._id}/images/${element._id}`} editable />
            </div>
        </PanelRight>
    )
}
export default OuvrageViewElement;