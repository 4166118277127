
export interface VisualizationViewCubeProps {
    side: string;
}

const VisualizationViewCube = ({ side }: VisualizationViewCubeProps) => {

    return (
        <svg className="cube" version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 58 58">
            <g>
                <polygon className={side === 'back' ? 'selected' : ''} points="28,0 55,13 55,45 28,32" />
                <polygon className={side === 'left' ? 'selected' : ''} points="4,45 30,32 30,0 4,13" />
                <polygon className={side === 'bottom' ? 'selected' : ''} points="3,45 28,32 55,45 29,58" />
                <polygon className={side === 'front' ? 'selected' : ''} points="29,58 3,45 3,13 29,26" />
                <polygon className={side === 'right' ? 'selected' : ''} points="29,58 55,45 55,13 29,26" />
                <polygon className={side === 'top' ? 'selected' : ''} points="3,13 28,0 55,13 29,26" />
            </g>
        </svg>
    )
}
export default VisualizationViewCube;