import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapAction } from '../../../components/data/Map';
import EntityCard from '../../../components/entities/EntityCard';
import RevisionCard from '../../../components/entities/RevisionCard';
import DatePickerInput from '../../../components/inputs/DatePicker';
import NumberInput from '../../../components/inputs/NumberInput';
import PositionPicker from '../../../components/inputs/PositionPicker';
import PrInput from '../../../components/inputs/PrInput';
import Select, { MultipleSelect } from '../../../components/inputs/Select';
import Switch from '../../../components/inputs/Switch';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { CoucheTypes, Lot, Shifts } from '../../../models/lot';
import { RevisionStatus } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest, postRequest, putRequest } from '../../../services/request.service';
import { formatNumber } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';

const VALIDATION = {
    lot: [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.REGEX, compareTo: /^([1-9])+([0-9])*([.][0-9]+)?$/, message: 'Le lot doit être constitué de chiffres et d\'un point facultatif' }
    ],
    sublot: [{ comparator: FormComparator.POSITIVE_INTEGER }],
    date: [{ comparator: FormComparator.REQUIRED }],
    position: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_INTEGER }],
    material: [{ comparator: FormComparator.REQUIRED }],
    thickness: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    type: [{ comparator: FormComparator.REQUIRED }],
};
const VALIDATION_TYPE_1 = {
    roads: [{ comparator: FormComparator.NOT_EMPTY }],
};
const VALIDATION_TYPE_2 = {
    'zone.polygon': [{ comparator: FormComparator.REQUIRED }],
};

const Edit = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const { page, lotId } = useParams();
    const navigate = useNavigate();
    const { entity, attachInput, validate, onChangeMultiple, setEntity, setChanged, onChange, errors, hasChanged } = useForm<Lot>({});

    const prRules = useMemo(() => [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.PR },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: operation.waysObj[entity?.way ?? 1]?.prStart, max: operation.waysObj[entity?.way ?? 1]?.prEnd }, message: 'Le PR est hors des limites du sens' },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: Math.min(...operation.roads?.filter(r => entity?.roads?.includes(r._id)).map(r => r.prStart)), max: Math.min(...operation.roads?.filter(r => entity?.roads?.includes(r._id)).map(r => r.prEnd)) }, message: 'Le PR est hors des limites des voies' },
    ], [operation, entity]);

    const handleWayChange = useCallback((way: number) => {
        onChangeMultiple([
            { field: 'way', value: way },
            { field: 'roads', value: undefined },
        ])
    }, [onChangeMultiple]);

    const handleSubmit = useCallback((close?: boolean) => {
        const validation = {
            ...VALIDATION,
            ...(operation.synoptique === 1
                ? {
                    ...VALIDATION_TYPE_1,
                    'zone.prStart': prRules,
                    'zone.prEnd': prRules
                } : VALIDATION_TYPE_2)
        };

        const entity = validate(validation);
        if (!entity) return;

        const dto = {
            ...entity,
            lot: formatNumber(entity.lot),
            subLot: formatNumber(entity.subLot),
            position: formatNumber(entity.position),
            thickness: formatNumber(entity.thickness),
        };
        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Lot>('/lot', dto, {
            successMessage: create ? 'Lot créé avec succès' : 'Lot mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [validate, operation.synoptique === 1, prRules, setEntity]);

    useEffect(() => {
        if (lotId) {
            getRequest<Lot>(`/lot/${lotId}${page === 'dupliquer' ? '/duplicate' : ''}`, { errorMessage: 'Une erreur est survenue lors de la récupération du lot.', loader: true })
                .then(setEntity)
                .catch(() => navigate(-1));
        } else {
            setEntity({
                position: 1,
                operation: operation._id,
                status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                active: true,
            });
        }
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Travaux', href: `/operation/${operation?._id}/travaux` },
                    { label: entity?._id ? entity.fullLot ?? '' : 'Nouveau lot' }
                ]}
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent
                side={
                    <EntityCard entity={entity} attachInput={attachInput}>
                        <RevisionCard<Lot> entity={entity} attachInput={attachInput} />
                    </EntityCard>
                }
            >
                <Card title="Informations générales">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="lot">Lot *</label>
                            <NumberInput placeholder="Ex: 1, 1.2, 5.1..." {...attachInput('lot')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="subLot">Sous-lot</label>
                            <NumberInput placeholder="Ex: 1, 4, 5..." {...attachInput('subLot')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="date">Date *</label>
                            <DatePickerInput withIcon {...attachInput('date')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="shift">Shift</label>
                            <Switch className="form-switch" items={[...Shifts, { key: '', label: 'N/A' }]} {...attachInput('shift')} />
                        </div>
                    </div>
                </Card>
                <Card title="Composition">
                    <div className="input-column">
                        <label htmlFor="material">Produit *</label>
                        <Select endpoint={`/material/list/product/${operation?._id}`} {...attachInput('material')} />
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="type">Type de couche *</label>
                            <Select items={CoucheTypes} {...attachInput('type')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="position">Position *</label>
                            <NumberInput placeholder="Position dans l'empilement" {...attachInput('position')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="thickness">Epaisseur *</label>
                            <NumberInput placeholder="Epaisseur" {...attachInput('thickness')} />
                        </div>
                    </div>
                </Card>
                <Card title="Localisation">
                    {operation.synoptique === 1 && (
                        <div className="row">
                            <div className="input-column">
                                <label htmlFor="way">Sens *</label>
                                <Select
                                    id="way"
                                    value={entity.way}
                                    items={operation.ways}
                                    onChange={(way) => way ? handleWayChange(way) : null}
                                    errors={errors.way}
                                />
                            </div>
                            <div className="input-column">
                                <label htmlFor="roads">Voie(s) *</label>
                                <MultipleSelect
                                    id="roads"
                                    value={entity.way && entity.roads ? entity.roads : []}
                                    items={entity.way ? operation.roads.filter(r => r.way === entity.way) : []}
                                    disabled={!entity.way}
                                    onChange={(r) => onChange('roads', r)}
                                    errors={errors?.roads}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="prStart">Repère kilométrique de début *</label>
                            <PrInput placeholder="Format 'km+hm'" {...attachInput('zone.prStart')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="prEnd">Repère kilométrique de fin *</label>
                            <PrInput placeholder="Format 'km+hm'" {...attachInput('zone.prEnd')} />
                        </div>
                    </div>
                    <div className="row">
                        <PositionPicker
                            type="zone"
                            location={entity?.zone?.location}
                            marker={entity?.zone?.marker}
                            polygon={entity?.zone?.polygon}
                            base={operation.zone?.polygon}
                            action={MapAction.POLYGON}
                            onChange={onChange}
                            errors={errors}
                        />
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default Edit;