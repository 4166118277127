import { useCallback, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import Map, { MapAction, Marker } from '../../../components/data/Map';
import NumberInput from '../../../components/inputs/NumberInput';
import PrInput from '../../../components/inputs/PrInput';
import { Modal } from '../../../components/ui/Modal';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { Population, PopulationData, PopulationDataHeader, PopulationIcon } from '../../../models/population';
import { formatNumber } from '../../../utils/format';
import { floatToPrString, prToFloat } from '../../../utils/pr';
import './index.scss';

interface PopulationAdd {
    pr: string;
    value: number;
    longitude: number;
    latitude: number;
}

interface PopulationCell {
    value?: string | number;
    renderValue?: string | number;
    className?: string;
    readOnly?: boolean;
};

interface PopulationZoneDataSheetProps {
    population: Partial<Population>;
    data?: PopulationData[];
    onSubmit: (data: PopulationData[]) => void;
    onClose: () => void;
}

const PopulationZoneDataSheet = ({
    population,
    data,
    onSubmit,
    onClose
}: PopulationZoneDataSheetProps) => {
    const [gridHeader, setGridHeader] = useState<{ value: string, readOnly: boolean }[]>([]);
    const [gridData, setGridData] = useState<PopulationCell[][]>([]);
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const { entity, attachInput, validate, setEntity } = useForm<PopulationAdd>({});

    const handleSubmit = useCallback(() => {
        if (hasError) return;

        const _data = [];
        for (const row of gridData) {
            // Filter incomplete lines
            if (row.length < 4 || row[0].value === undefined || row[0].value === "" || !row[1].value || !row[2].value || row[3].value === undefined || row[3].value === "") {
                continue;
            }
            _data.push({
                location: {
                    pr: Number(row[0].value),
                    coordinates: {
                        longitude: Number(row[1].value),
                        latitude: Number(row[2].value)
                    }
                },
                value: Number(row[3].value),
                problematic: false
            });
        }

        onSubmit(_data);
        onClose();
    }, [onSubmit, onClose, hasError, gridData, population]);

    const getCellValue = useCallback((value: string | number | null, col: number) => {
        if (value === '') return value;

        const cellValue = col === 0 ? prToFloat(String(value)) : formatNumber(value);
        return isNaN(cellValue) ? 'VALEUR INCORRECTE' : cellValue;
    }, []);

    const getRenderValue = useCallback((value: string | number | undefined, col: number) => col === 0 && value !== 'VALEUR INCORRECTE' ? floatToPrString(!isNaN(Number(value)) ? Number(value) : undefined) : value, []);

    const valueRenderer = useCallback((cell: PopulationCell, row: number, col: number) => {
        // Don't render header and empty cells
        if (row === 0 || cell.value === '') return cell.value;

        return cell.renderValue ?? getRenderValue(cell.value, col);
    }, [getRenderValue]);

    const handleCellChange = useCallback((change: { cell?: any; row: number; col: number; value: string | number | null; }, _gridData: PopulationCell[][]) => {
        const gridDataRow = change.row - 1;
        const gridDataCol = change.col;
        let gridValueFormatted = getCellValue(change.value, gridDataCol);

        if (gridDataRow >= _gridData.length) {
            _gridData.push([
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' }
            ]);
        }

        _gridData[gridDataRow][gridDataCol] = {
            ..._gridData[gridDataRow][gridDataCol],
            value: gridValueFormatted,
            renderValue: getRenderValue(gridValueFormatted, change.col),
            className: gridValueFormatted === 'VALEUR INCORRECTE' ? 'error' : ''
        };
    }, [getCellValue, getRenderValue]);

    const onCellsChanged = useCallback((changes: { cell?: any; row: number; col: number; value: string | number | null; }[] = [], additions: { row: number; col: number; value: string | number | null; }[] = []) => {
        const _gridData = [...gridData];

        for (const change of [...changes, ...additions]) {
            handleCellChange(change, _gridData);
        }

        setGridData(_gridData);
    }, [gridData, handleCellChange]);

    const handleAdd = useCallback(() => {
        const entity = validate({
            value: [{ comparator: FormComparator.REQUIRED }],
            pr: [
                { comparator: FormComparator.PR },
                { comparator: FormComparator.PR_INSIDE, compareTo: { min: population?.lotPopulated?.zone?.prStart ? floatToPrString(population?.lotPopulated?.zone?.prStart) : undefined, max: population?.lotPopulated?.zone?.prEnd ? floatToPrString(population?.lotPopulated?.zone?.prEnd) : undefined }, message: 'Le PR est hors des limites du lot' }
            ]
        })

        if (!entity) return;

        onCellsChanged([], [
            { row: gridData.length + 1, col: 0, value: entity.pr },
            { row: gridData.length + 1, col: 1, value: String(entity.longitude) },
            { row: gridData.length + 1, col: 2, value: String(entity.latitude) },
            { row: gridData.length + 1, col: 3, value: String(entity.value) },
        ]);
        setAddModalVisible(false);
        setEntity({});
    }, [gridData, onCellsChanged, population, validate]);

    useEffect(() => {
        setHasError(gridData.some(row => row.some(col => col.className === 'error')));
    }, [gridData]);

    useEffect(() => {
        setGridHeader([
            { value: 'PR', readOnly: true },
            { value: 'Longitude', readOnly: true },
            { value: 'Latitude', readOnly: true },
            { value: population.type ? PopulationDataHeader[population.type] : '', readOnly: true },
        ]);
        setGridData((data ?? []).map(d => [
            { value: d.location?.pr, renderValue: String(d.location?.pr) !== 'VALEUR INCORRECTE' ? floatToPrString(d.location?.pr) : d.location?.pr, className: String(d.value) === 'VALEUR INCORRECTE' ? 'error' : '' },
            { value: d.location?.coordinates?.longitude, renderValue: d.location?.coordinates?.longitude, className: String(d.value) === 'VALEUR INCORRECTE' ? 'error' : '' },
            { value: d.location?.coordinates?.latitude, renderValue: d.location?.coordinates?.latitude, className: String(d.value) === 'VALEUR INCORRECTE' ? 'error' : '' },
            { value: d.value, renderValue: d.value, className: String(d.value) === 'VALEUR INCORRECTE' ? 'error' : '' },
        ]));
    }, [population, data]);

    //onDragEnd={(data) => onCellsChanged([{ row: index + 1, col: 1, value: data.latLng?.lng() ?? null }, { row: index + 1, col: 2, value: data.latLng?.lat() ?? null }])}
    return (
        <Modal
            size="large"
            title="Saisie de données"
            className="population-data-modal"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', disabled: hasError, onClick: handleSubmit },
            ]}
        >
            <div className="population-data-form-zone">
                <Map
                    center={population?.lotPopulated?.zone?.marker}
                    initialAction={MapAction.MARKER}
                    availableActions={[MapAction.MARKER]}
                    base={population.lotPopulated?.zone.polygon}
                    onActionComplete={(_, data) => { setEntity({ ...entity, ...data.marker }); setAddModalVisible(true); }}
                >
                    {!!gridData?.length && gridData.filter(d => d[2].value && d[1].value).map((d, index) => (
                        <Marker
                            key={index}
                            longitude={Number(d[1].value)!}
                            latitude={Number(d[2].value)!}
                            icon={PopulationIcon}
                            className="color-blue"
                            onDragEnd={(data) => onCellsChanged([{ row: index + 1, col: 1, value: data.lngLat.lng ?? null }, { row: index + 1, col: 2, value: data.lngLat.lat ?? null }])}
                        />
                    ))}
                </Map>
            </div>
            <ReactDataSheet<PopulationCell>
                className="population-datasheet"
                data={[gridHeader, ...gridData, [{ value: '' }, { value: '' }, { value: '' }, { value: '' }]]}
                valueRenderer={valueRenderer}
                onContextMenu={(e, cell) => cell.readOnly ? e.preventDefault() : null}
                onCellsChanged={onCellsChanged}
            />
            {isAddModalVisible && (
                <Modal
                    title="Ajout d'un point"
                    className="modal-autofill"
                    actions={[
                        { label: 'Annuler', color: 'secondary', onClick: () => setAddModalVisible(false) },
                        { label: 'Valider', color: 'primary', onClick: handleAdd }
                    ]}
                >
                    <p className="info">
                        Renseigner les valeurs pour le point à ajouter. Le champ PR est obligatoire pour permettre d'ordonner les valeurs et déclencher en cas
                        de valeurs problématiques consécutives.
                    </p>
                    <div className="row">
                        <div className="input-column lg6">
                            <label htmlFor="pr">PR</label>
                            <PrInput {...attachInput('pr')} />
                        </div>
                        <div className="input-column lg6">
                            <label htmlFor="value">Valeur</label>
                            <NumberInput {...attachInput('value')} />
                        </div>
                    </div>
                </Modal>
            )
            }
        </Modal >
    )
}

export default PopulationZoneDataSheet;