import { useMemo, useState } from "react";
import ImageList from "../../../../components/ImageList";
import SearchInput from "../../../../components/inputs/SearchInput";
import Card from "../../../../components/ui/Card";
import PanelRight from "../../../../components/ui/PanelRight";
import { OuvrageElement } from "../../../../models/ouvrage";
import useWorkspace from "../../../../services/hooks/use-workspace";
import { stringToSearchField } from "../../../../utils/format";


export interface OuvrageViewPanelProps {
    onClose: () => void;
    onSelectElement: (element: OuvrageElement) => void;
}

const OuvrageViewPanel = ({ onClose, onSelectElement }: OuvrageViewPanelProps) => {
    const { ouvrage } = useWorkspace();
    const [elementsKeyword, setElementsKeyword] = useState<string>();

    const elementsFiltered = useMemo(() => !elementsKeyword ? ouvrage.elements : ouvrage.elements.filter(e => stringToSearchField(e.name).includes(stringToSearchField(elementsKeyword))), [elementsKeyword, ouvrage]);

    return (
        <PanelRight
            id="ouvrage-view-panel"
            title={ouvrage.name}
        >
            <div className="input-column">
                <label htmlFor="location">Elements</label>
                <Card id="ouvrage-view-panel-elements">
                    <SearchInput id="ouvrage-view-panel-elements-search" placeholder="Rechercher un élément..." minLength={2} value={elementsKeyword} onChange={setElementsKeyword} />
                    {elementsFiltered.map(element => (
                        <div key={element._id} className="ouvrage-view-panel-element" onClick={() => onSelectElement(element)}>
                            {element.name}
                        </div>
                    ))}
                </Card>
            </div>
            <div className="input-column">
                <label htmlFor="location">Photographies</label>
                <ImageList column entityId={ouvrage._id} url={`/ouvrage/${ouvrage._id}/images`} editable />
            </div>
        </PanelRight>
    )
}
export default OuvrageViewPanel;