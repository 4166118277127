import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentAnalysis from '../../../components/Analysis/ComponentAnalysis';
import Button from '../../../components/ui/Button';
import Header from '../../../components/ui/Header';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import { Material, MaterialTypeLabel } from '../../../models/material';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';

const ComponentEdit = () => {
    const { operation } = useWorkspace();
    const { materialId } = useParams();
    const navigate = useNavigate();
    const [material, setMaterial] = useState<Material | null>(null);

    useEffect(() => {
        if (materialId) {
            getRequest<Material>(`/material/${materialId}`, { errorMessage: 'Une erreur est survenue lors de la récupération de l\'exigence.' })
                .then(setMaterial)
                .catch(() => navigate(-1));
        } else {
            navigate(-1);
        }
    }, [materialId]);

    if (!material) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Travaux', href: `/operation/${operation?._id}/travaux/suivi-de-constituants` },
                    { label: `${MaterialTypeLabel[material.type]} : ${material?.name ?? ''}` }
                ]}
            >
                <Button
                    label="Fermer"
                    onClick={() => navigate(-1)}
                />
            </Header>
            <ScrollableContent noPadding>
                <ComponentAnalysis material={material} />
            </ScrollableContent>
        </Fragment>
    )
}

export default ComponentEdit;
