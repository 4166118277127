import { Fragment, useEffect, useMemo, useState } from 'react';
import { useVisualisation } from '..';
import { CoordinatesXYZ } from '../../../../models/location';
import { OuvrageMarker } from '../../../../models/ouvrage-marker';
import { markerToCoordinates, updateCoordinates } from '../functions';

export interface VisualisationMarkerProps {
    marker: Partial<OuvrageMarker>;
    onMove?: (coordinates: CoordinatesXYZ) => void;
    onClick?: () => void;
    selected?: boolean;
    hide?: boolean;
    ghost?: boolean;
}

const VisualisationMarker = ({ marker, onMove, selected, hide, onClick, ghost }: VisualisationMarkerProps) => {
    const state = useVisualisation();
    const [isMoving, setMoving] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<CoordinatesXYZ>();

    const coordinatesXY = useMemo(() => coordinates ? markerToCoordinates(coordinates, state.view) : undefined, [coordinates, state.view]);

    useEffect(() => {
        if (marker.coordinates) {
            setCoordinates(marker.coordinates);
        }
    }, [marker, state.view]);

    useEffect(() => {
        if (isMoving && onMove) {
            const onMouseMove = (event: Event) => {
                setCoordinates((coordinates) => updateCoordinates(state, { x: (event as any).clientX, y: (event as any).clientY }, coordinates ?? {}));
            }
            const onMouseUp = (event: Event) => {
                setMoving(false);
                onMove(updateCoordinates(state, { x: (event as any).clientX, y: (event as any).clientY }, coordinates ?? {}));
            }

            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);

            return () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            }
        }
    }, [isMoving]);

    if (!coordinatesXY || hide) return null;

    return (
        <Fragment>
            <circle
                className={`visualization-marker ${onClick ? 'clickable' : ''} ${selected ? 'selected' : ghost ? 'ghost' : ''} ${onMove ? 'movable' : ''}`}
                cx={coordinatesXY.x}
                cy={coordinatesXY.y}
                r={5 / state.zoom}
                strokeWidth={2 / state.zoom}
                onMouseDown={onMove ? (e) => { e.stopPropagation(); setMoving(true); } : undefined}
                onClick={onClick}
            />
        </Fragment>
    )
}

export default VisualisationMarker;