import { useCallback, useEffect, useState } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import TextInput from "../../../../components/inputs/TextInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { Pile } from "../../../../models/ouvrage";

export interface PileFormProps {
    pile?: Partial<Pile>;
    onClose: () => void;
    onChange: (pile?: Partial<Pile>) => void;
}

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.MIN_LENGTH, compareTo: 3 }],
    length: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    width: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    height: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    x: [{ comparator: FormComparator.REQUIRED }],
    y: [{ comparator: FormComparator.REQUIRED }],

}

const PileForm = ({ onClose, pile, onChange }: PileFormProps) => {
    const [initial, setInitial] = useState<Partial<Pile>>(pile ?? {});
    const { entity, attachInput, setEntity, validate } = useForm<Pile>(pile);

    const handleClose = useCallback(() => {
        onChange(initial);
        onClose();
    }, [initial]);

    const handleSubmit = useCallback(() => {
        if (validate(VALIDATION)) {
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const entity = validate(VALIDATION);
        if (entity) onChange(entity);
    }, [entity]);

    useEffect(() => {
        setInitial(pile ?? {});
        setEntity(pile ?? {});
    }, [pile?._id]);

    return (
        <PanelRight
            title="Pile"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: handleClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div className="input-column">
                <label htmlFor="name">Nom *</label>
                <TextInput {...attachInput('name')} />
            </div>
            <div className="row">
                <div className="input-column">
                    <label htmlFor="length">Longueur *</label>
                    <NumberInput {...attachInput('length')} />
                </div>
                <div className="input-column">
                    <label htmlFor="width">Largeur *</label>
                    <NumberInput {...attachInput('width')} />
                </div>
                <div className="input-column">
                    <label htmlFor="height">Hauteur *</label>
                    <NumberInput {...attachInput('height')} />
                </div>
            </div>
            <div className="column">
                <h4>Position</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="x">Longitudinale *</label>
                        <NumberInput {...attachInput('x')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="y">Transversale *</label>
                        <NumberInput {...attachInput('y')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Arche gauche</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="arcLeft.rX">Rayon horizontal</label>
                        <NumberInput {...attachInput('arcLeft.rX')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="arcLeft.rY">Rayon vertical</label>
                        <NumberInput {...attachInput('arcLeft.rY')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Arche droit</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="arcRight.rX">Rayon horizontal</label>
                        <NumberInput {...attachInput('arcRight.rX')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="arcRight.rY">Rayon vertical</label>
                        <NumberInput {...attachInput('arcRight.rY')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default PileForm;