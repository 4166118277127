import { Icon } from '@iconify/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { FormBaseProps } from '../../hooks/useForm';
import useOutsideClick from '../../hooks/useOutsideClick';
import { getRequest } from '../../services/request.service';

const MAX_HEIGHT = 306;

type SelectAutocompleteItem = { key: string, label: string };

interface SelectAutocompleteProps extends FormBaseProps<string> {
    items?: SelectAutocompleteItem[];
    endpoint?: string;
    placeholder?: string;
}

const SelectAutocomplete = ({
    id,
    value,
    items,
    endpoint,
    placeholder,
    disabled,
    onChange,
    errors
}: SelectAutocompleteProps) => {
    const [itemsOrData, setItemsOrData] = useState<SelectAutocompleteItem[]>([]);
    const [filteredItemsOrData, setFilteredItemsOrData] = useState<SelectAutocompleteItem[]>([]);
    const [isOpened, setOpened] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const ref = useOutsideClick(useCallback(() => setOpened(false), []));

    useEffect(() => {
        if (keyword) {
            setFilteredItemsOrData(itemsOrData.filter(i => i.label?.toLowerCase().includes(keyword.toLowerCase())));
        } else {
            setFilteredItemsOrData(itemsOrData);
        }
    }, [keyword]);

    useEffect(() => {
        // TODO : generalize
        if (ref.current) {
            const bound = ref.current.getBoundingClientRect();
            if (!isOpened) {
                ref.current.style.maxHeight = "100%";
            } else {
                if (bound.top + MAX_HEIGHT > window.innerHeight) {
                    ref.current.style.bottom = '0';
                    ref.current.style.top = "auto";
                } else {
                    ref.current.style.top = '0';
                    ref.current.style.bottom = "auto";
                }
                ref.current.style.maxHeight = MAX_HEIGHT + "px";
            }
        }
    }, [isOpened, ref]);

    useEffect(() => {
        if (endpoint) {
            getRequest<SelectAutocompleteItem[]>(endpoint)
                .then((data => {
                    setItemsOrData(data);
                    setFilteredItemsOrData(data);
                }))
                .catch(() => setItemsOrData([]));
        } else {
            setItemsOrData(items ?? []);
            setFilteredItemsOrData(items ?? []);
        }
    }, [endpoint, items]);

    return (
        <div className="select">
            <div className={`dropdown border-input${isOpened ? ' opened' : ''}${!!errors?.length ? ' input-error' : ''}`} ref={ref}>
                <input
                    id={id}
                    type="text"
                    autoComplete="off"
                    value={isOpened ? keyword : itemsOrData?.find(item => item.key === value)?.label ?? ''}
                    onClick={() => setOpened(!isOpened)}
                    placeholder={placeholder ?? ''}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                {!disabled && (
                    <Fragment>
                        <div className="icon-container" onClick={() => setOpened(!isOpened)}>
                            <Icon icon="mdi:chevron-down" onClick={() => setOpened(false)} />
                        </div>
                        {!!filteredItemsOrData?.length && (
                            <div className="dropdown-scroll">
                                {filteredItemsOrData.map((item) => {
                                    const active = value === item.key;

                                    return (
                                        <div
                                            key={String(item.key)}
                                            className={`select-item ${active ? 'active' : ''}`}
                                            onClick={() => { onChange(item.key, item.label); setOpened(false) }}
                                        >
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            {!!errors?.length && <div className="form-error">{errors.join('. ')}</div>}
        </div>
    )
}

export default SelectAutocomplete;