import { useState } from 'react';
import { v4 } from 'uuid';
import OptionsMenu from '../../../components/data/OptionsMenu';
import NumberInput from '../../../components/inputs/NumberInput';
import PrInput from '../../../components/inputs/PrInput';
import Select from '../../../components/inputs/Select';
import TextInput from '../../../components/inputs/TextInput';
import IconLink from '../../../components/ui/IconLink';
import { Modal } from '../../../components/ui/Modal';
import { FormComparator, validateAll } from '../../../hooks/useForm';
import { RoadDto, RoadNameLabel, RoadNames, RoadType, RoadTypes, WayDto } from '../../../models/operation';
import { changeFieldValue } from '../../../utils/objects';
import { RoadWithSections } from './Way';

const INITIAL_ROAD = {
    active: true,
    type: RoadType.VOIE,
};

interface RoadFormProps {
    order: number;
    sections: Partial<RoadDto>[];
    way: WayDto;
    onClose: () => void;
    onSubmit: (r: RoadWithSections) => void;
}

const RoadForm = ({
    order,
    sections: sectionsFromProps,
    way,
    onClose,
    onSubmit
}: RoadFormProps) => {
    const [sections, setSections] = useState<Partial<RoadDto>[]>(sectionsFromProps.length ? sectionsFromProps : [{ ...INITIAL_ROAD, _id: v4(), order, prStart: way.prStart, prEnd: way.prEnd }]);
    const [errors, setErrors] = useState<{ [k: string]: string[] } | null>(null);
    const roadPrRules = [
        { comparator: FormComparator.REQUIRED },
        { comparator: FormComparator.PR },
        { comparator: FormComparator.PR_INSIDE, compareTo: { min: way?.prStart, max: way?.prEnd }, message: 'Le PR est hors des limites du sens' }
    ];

    const handleChange = (id: string, value: any, index: number) => {
        if (index >= 0 && index < sections?.length) {
            const _sections = [...sections];
            _sections[index] = changeFieldValue(_sections[index], id, value);
            setSections(_sections);
        }
    }

    const handleSubmit = () => {
        const _errors: { [k: string]: string[] } = {};
        let _hasError = false;

        for (let i = 0; i < sections.length; i++) {
            let [sectionErrors, sectionHasError] = validateAll(sections[i], {
                name: [{ comparator: FormComparator.REQUIRED }],
                type: [{ comparator: FormComparator.REQUIRED }],
                prStart: roadPrRules,
                prEnd: roadPrRules,
            });

            _errors['name' + i] = sectionErrors.name;
            _errors['type' + i] = sectionErrors.type;
            _errors['prStart' + i] = sectionErrors.prStart;
            _errors['prEnd' + i] = sectionErrors.prEnd;

            if (sections[i].name && sections.some((s, si) => si !== i && s.name === sections[i].name && s.index === sections[i].index)) {
                _errors['index' + i] = [`La voie ${RoadNameLabel[sections[i].name!]}${sections[i].index ?? ''} existe déjà. Utilisez l'indice pour différencier les voies du même type`];
                _hasError = true;
            }
            _hasError = _hasError || !!sectionHasError;
        }

        if (!_hasError) {
            onSubmit({ order, sections: sections as RoadDto[] });
        }
        setErrors(_errors);
    }

    return (
        <Modal
            title={order === -1 ? 'Créer une nouvelle voie' : 'Modifier la voie'}
            id="modal-road"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', onClick: handleSubmit },
            ]}
        >
            {sections.map((section, index) => (
                <div className="row" key={index}>
                    <div className="input-column">
                        <label htmlFor={'name' + index}>Nom *</label>
                        <Select
                            id={'name' + index}
                            value={section.name ?? ''}
                            items={RoadNames}
                            onChange={(value) => handleChange('name', value, index)}
                            errors={errors?.['name' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'index' + index}>Indice</label>
                        <NumberInput
                            id={'index' + index}
                            value={section.index ?? undefined}
                            onChange={(value) => handleChange('index', value, index)}
                            errors={errors?.['index' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'description' + index}>Description</label>
                        <TextInput
                            id={'description' + index}
                            value={section.description ?? ''}
                            placeholder="Description"
                            onChange={(value) => handleChange('description', value, index)}
                            errors={errors?.['description' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'type' + index}>Type de voie</label>
                        <Select
                            id={'type' + index}
                            value={section.type ?? ''}
                            items={RoadTypes}
                            onChange={(value) => handleChange('type', value, index)}
                            errors={errors?.['type' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'prStart' + index}>PR de début *</label>
                        <PrInput
                            id={'prStart' + index}
                            value={section.prStart}
                            onBlurValidationError={roadPrRules}
                            onBlurValidationWarning={[{ comparator: FormComparator.PR_EQUAL, compareTo: way?.prStart, message: 'Le PR est différent de celui du sens' }]}
                            onChange={(value) => handleChange('prStart', value, index)}
                            errors={errors?.['prStart' + index]}
                        />
                    </div>
                    <div className="input-column">
                        <label htmlFor={'prEnd' + index}>PR de fin *</label>
                        <PrInput
                            id={'prEnd' + index}
                            value={section.prEnd}
                            onBlurValidationError={roadPrRules}
                            onBlurValidationWarning={[{ comparator: FormComparator.PR_EQUAL, compareTo: way?.prEnd, message: 'Le PR est différent de celui du sens' }]}
                            onChange={(value) => handleChange('prEnd', value, index)}
                            errors={errors?.['prEnd' + index]}
                        />
                    </div>
                    {!!sections.length && (
                        <OptionsMenu onDelete={() => setSections(sections.filter(s => s._id !== section._id))} />
                    )}
                </div>
            ))}
            <IconLink type="add" label="Ajouter une section" onClick={() => setSections([...sections, { ...INITIAL_ROAD, _id: v4(), order, prStart: way.prStart, prEnd: way.prEnd }])} />
        </Modal >
    );
}

export default RoadForm;
