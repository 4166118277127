import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import AnalysisTable from '../../../components/AnalysisTable';
import { IdentityCardLot } from '../../../components/entities/IdentityCard';
import DatePicker from '../../../components/inputs/DatePicker';
import NumberInput from '../../../components/inputs/NumberInput';
import PrInput from '../../../components/inputs/PrInput';
import Select from '../../../components/inputs/Select';
import { SwitchYesNo } from '../../../components/inputs/Switch';
import { Textarea } from '../../../components/inputs/Textarea';
import TextInput from '../../../components/inputs/TextInput';
import { FormComparator, validateAll } from '../../../hooks/useForm';
import { Analysis } from '../../../models/analysis';
import { Lot } from '../../../models/lot';
import { MaterialCharacteristicElementRequirement, getAnalysisCharac } from '../../../models/material';
import { Phase, Road, RoadPosition, RoadPositionLabel, RoadPositionsList } from '../../../models/operation';
import { Controle, ControleLabel, Controles, Fissure, Sample, SampleType } from '../../../models/sample';
import { ListItem, RevisionStatus, RevisionStatusLabel, RevisionStatusList } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../services/request.service';
import { formatDate, formatNumberToFixedDecimal } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import { changeFieldValue } from '../../../utils/objects';
import { floatToPrString } from '../../../utils/pr';
import './index.scss';

interface SampleSingleLayer extends Partial<Sample> {
    analysisPopulated?: Partial<Analysis>;
    isEditing: boolean;
    thickness?: number;
    collage?: boolean;
    fissure?: Fissure;
    fracture?: Fissure;
}

interface SampleMasseAnalysisProps {
    lot: Lot;
    type: SampleType;
    roads: Road[];
}

const SampleMasseAnalysis = ({
    lot,
    roads,
    type
}: SampleMasseAnalysisProps) => {
    const { workspacePermissions } = useWorkspace();
    const [characteristics, setCharacteristics] = useState<MaterialCharacteristicElementRequirement[]>([]);
    const [producers, setProducers] = useState<ListItem[]>([]);
    const [samples, setSamples] = useState<SampleSingleLayer[]>([]);
    const [errors, setErrors] = useState<{ [index: number]: { [key: string]: string[] } } | null>(null);

    const controles = useMemo(() => Controles.filter(c => workspacePermissions.controles?.includes(c.key)), [workspacePermissions]);

    const handleChange = useCallback((index: number, field: string, value: any) => {
        setSamples(samples => {
            if (index >= 0 && index < samples?.length) {
                const _samples = [...samples];
                _samples[index] = changeFieldValue(_samples[index], field, value);
                return _samples;
            }
            return samples;
        });
    }, []);

    const handleCopy = useCallback((index: number) => {
        setSamples(samples => {
            if (index >= 0 && index < samples?.length) {
                return [
                    ...samples,
                    {
                        ...samples[index],
                        analysisPopulated: { ...samples[index].analysisPopulated, data: undefined },
                        isEditing: true,
                        _id: 'new_' + v4(),
                    }

                ];
            }
            return samples;
        });
    }, []);

    const handleSave = useCallback(async (index: number) => {
        const sample = samples[index];

        const dto = {
            _id: sample._id?.startsWith('new_') ? undefined : sample._id,
            name: sample.name,
            type: type,
            phase: Phase.TRAVAUX,
            operation: lot.operation,
            diameter: sample.diameter,
            status: sample.status,
            lot: lot._id,
            fullLot: lot.fullLot,
            date: sample.date,
            controle: sample.controle,
            location: {
                roadPosition: sample.location?.roadPosition,
                road: sample.location?.road,
                way: lot.way,
                pr: sample.location?.pr,
            },
            layers: [{
                thickness: sample.thickness ?? 0,
                collage: sample.collage,
                fissure: sample.fissure,
                fracture: sample.fracture,
                order: 0,
                active: true,
                material: lot.materialPopulated?._id,
                analysisPopulated: {
                    _id: sample.analysisPopulated?._id,
                    date: sample.date,
                    controle: sample.controle,
                    type: lot.materialPopulated?.type,
                    business: sample.analysisPopulated?.business,
                    material: lot.materialPopulated?._id,
                    operation: lot.operation,
                    data: sample.analysisPopulated?.data,
                    order: 1,
                    active: true
                }
            }],
            active: true
        }

        const [_errors, hasError] = validateAll(dto, {
            name: [{ comparator: FormComparator.REQUIRED }],
            'location.pr': [
                { comparator: FormComparator.REQUIRED },
                { comparator: FormComparator.POSITIVE_NUMBER },
                { comparator: FormComparator.PR },
                { comparator: FormComparator.PR_INSIDE, compareTo: { min: lot?.zone?.prStart, max: lot?.zone?.prEnd }, message: 'Le PR est hors des limites du lot' },
            ],
        });

        if (hasError) {
            setErrors({ [index]: _errors });
            return;
        } else {
            setErrors(null);
        }

        const create = !dto._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Sample>('/sample', dto, {
            successMessage: create ? 'Echantillon créé avec succès' : 'Echantillon mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                const _samples = [...samples];
                _samples[index] = {
                    _id: data._id,
                    name: data.name,
                    controle: data.controle,
                    status: data.status,
                    date: data.date,
                    location: data.location,
                    diameter: data.diameter,
                    analysisPopulated: data.layers?.length ? data.layers[0].analysisPopulated : undefined,
                    collage: data.layers?.[0]?.collage,
                    thickness: data.layers?.[0]?.thickness ?? 0,
                    isEditing: false
                }
                setSamples(_samples);
            })
            .catch(() => null);

    }, [samples]);

    const handleNew = () => {
        setSamples([...samples,
        {
            _id: 'new_' + v4(),
            name: lot?.materialPopulated?.name ?? '',
            date: lot?.date ?? new Date().toISOString(),
            thickness: type === SampleType.CAROTTE ? lot?.thickness ?? 0 : 0,
            controle: workspacePermissions.controles?.[0] ?? Controle.EXTERIEUR,
            status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
            collage: true,
            analysisPopulated: lot?.materialPopulated?.business ? {
                business: lot?.materialPopulated?.business
            } : {},
            location: {
                road: roads.length ? roads[0]._id : undefined,
                roadPosition: RoadPosition.AXE,
            },
            isEditing: true
        }]);
    }

    const handleDelete = useCallback(async (index: number) => {
        const _samples = [...samples];
        const deleted = _samples.splice(index, 1);

        if (deleted.length) {
            if (!deleted[0]._id?.startsWith('new_')) {
                deleteRequest(`/sample/${deleted[0]._id}`, { loader: true, errorMessage: 'Une erreur est survenue lors de la suppression.', successMessage: 'Echantillon supprimé avec succès' })
                    .then(() => null)
                    .catch(() => null);
            }
            setSamples(_samples);
        }
    }, [samples]);

    useEffect(() => {
        if (lot?.materialPopulated) {
            setCharacteristics(getAnalysisCharac(lot.materialPopulated));
        }
        getRequest<ListItem[]>('/business/list/producer')
            .then(setProducers)
            .catch(() => null);

        if (!samples.length) {
            handleNew();
        }
    }, []);

    if (!characteristics?.length) {
        return (null);
    }

    return (
        <Fragment>
            <IdentityCardLot entity={lot} />
            <AnalysisTable>
                <AnalysisTable.Actions
                    entities={samples}
                    actionsEditing={[
                        { icon: ActionIcon.EDIT, onClick: (index) => handleChange(index, 'isEditing', true) },
                        { icon: ActionIcon.COPY, onClick: (index) => handleCopy(index) },
                        { icon: ActionIcon.DELETE, onClick: (index) => handleDelete(index) }
                    ]}
                    actions={[
                        { icon: ActionIcon.SAVE, label: 'Sauvegarder', onClick: (index) => handleSave(index) },
                        ...(samples.length > 1 ? [{ icon: ActionIcon.DELETE, onClick: (index: number) => handleDelete(index) }] : [])
                    ]}
                    actionsSpacer={[{ icon: ActionIcon.ADD, onClick: handleNew, label: 'Nouveau' }]}
                />
                <AnalysisTable.Separator colsPan={samples.length} label="Informations" />
                <AnalysisTable.Row label="Intitulé">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.name}>
                            <TextInput id={index + 'name'} value={sample.name} onChange={(v) => handleChange(index, 'name', v)} errors={errors?.[index]?.['name']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Date">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={formatDate(sample.date)}>
                            <DatePicker id={index + 'date'} value={sample.date} max={new Date()} onChange={(v) => handleChange(index, 'date', v)} errors={errors?.[index]?.['date']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Statut">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.status ? RevisionStatusLabel[sample.status] : ''}>
                            <Select id={index + 'status'} items={RevisionStatusList} value={sample.status} disabled={!workspacePermissions.validate} onChange={(v) => handleChange(index, 'status', v)} errors={errors?.[index]?.['status']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Controle">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.controle ? ControleLabel[sample.controle] : ''}>
                            <Select id={index + 'controle'} items={controles} value={sample.controle} onChange={(v) => handleChange(index, 'controle', v)} errors={errors?.[index]?.['controle']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Separator colsPan={samples.length} label="Localisation" />
                <AnalysisTable.Row label="Voie">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={roads.find(r => r._id === sample.location?.road)?.realName ?? ''}>
                            <Select id={index + 'voie'} items={roads} value={sample.location?.road} onChange={(v) => handleChange(index, 'location.road', v)} errors={errors?.[index]?.['location.road']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Position">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.location?.roadPosition ? RoadPositionLabel[sample.location?.roadPosition] ?? '' : ''}>
                            <Select id={index + 'position'} items={RoadPositionsList} value={sample.location?.roadPosition} onChange={(v) => handleChange(index, 'location.roadPosition', v)} errors={errors?.[index]?.['location.roadPosition']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="PR">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={floatToPrString(sample.location?.pr)}>
                            <PrInput id={index + 'pr'} value={sample.location?.pr} onChange={(v) => handleChange(index, 'location.pr', v)} errors={errors?.[index]?.['location.pr']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Separator colsPan={samples.length} label="Détails" />
                <AnalysisTable.Row label="Producteur">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={producers.find(p => p.key === sample.analysisPopulated?.business)?.label ?? undefined}>
                            <Select id={index + 'producer'} items={producers} value={sample.analysisPopulated?.business} onChange={(v) => handleChange(index, 'analysisPopulated.business', v)} errors={errors?.[index]?.['analysisPopulated.business']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                <AnalysisTable.Row label="Epaisseur">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={formatNumberToFixedDecimal(sample.thickness)}>
                            <NumberInput id={index + 'thickness'} value={sample.thickness} onChange={(v) => handleChange(index, 'thickness', v)} errors={errors?.[index]?.['thickness']} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
                {type === SampleType.CAROTTE && (
                    <AnalysisTable.Row label="Collage">
                        {samples.map((sample, index) => (
                            <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.collage === true ? 'Oui' : sample.collage === false ? 'Non' : undefined}>
                                <SwitchYesNo
                                    id={index + 'collage'}
                                    className="form-switch"
                                    value={!!sample?.collage}
                                    onChange={(v) => handleChange(index, 'collage', v)}
                                />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                {type === SampleType.CAROTTE && (
                    <AnalysisTable.Row label="Diamètre">
                        {samples.map((sample, index) => (
                            <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={formatNumberToFixedDecimal(sample.diameter)}>
                                <NumberInput id={index + 'diameter'} value={sample.diameter} onChange={(v) => handleChange(index, 'diameter', v)} errors={errors?.[index]?.['diameter']} />
                            </AnalysisTable.Cell>
                        ))}
                    </AnalysisTable.Row>
                )}
                <AnalysisTable.Separator colsPan={samples.length} label="Caractéristiques" />
                <AnalysisTable.Characteristics
                    entities={samples}
                    characteristics={characteristics}
                    onChange={(index, analysis) => handleChange(index, 'analysisPopulated', analysis)}
                />
                <AnalysisTable.Row label="Commentaire">
                    {samples.map((sample, index) => (
                        <AnalysisTable.Cell key={sample._id ?? 'new-sample'} isEditing={sample.isEditing} value={sample.comment}>
                            <Textarea id={index + 'comment'} value={sample.comment} onChange={(v) => handleChange(index, 'comment', v)} />
                        </AnalysisTable.Cell>
                    ))}
                </AnalysisTable.Row>
            </AnalysisTable>
        </Fragment>
    )
}

export default SampleMasseAnalysis;