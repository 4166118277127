import { MouseEvent, TouchEvent, useEffect, useMemo, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { useVisualisation } from "..";
import { CoordinatesXY } from "../../../../models/location";
import { OuvrageElement } from "../../../../models/ouvrage";
import { barriereForVisualization, buseForVisualization, cuboidForVisualization, domCoordinatesToRealCoordinates, pileForVisualization } from "../functions";
import Dimensions from "./VisualizationDimensions";

export interface VisualizationElementProps {
    element: OuvrageElement;
    selected?: boolean;
    onChange?: (e: Pick<OuvrageElement, "coordinates" | "dimensions">) => void;
    onClick?: () => void;
    offsetZ?: number;
    hide?: boolean;
}

const VisualizationElement = ({ element, selected, onChange, onClick, hide, offsetZ }: VisualizationElementProps) => {
    const state = useVisualisation();
    const [rectangle, setRectangle] = useState<CoordinatesXY & { width: number, height: number }>();
    const [movingHandle, setMovingHandle] = useState<number>();

    const elementForVisualization = useMemo(() => {
        switch (element.type) {
            case 'pile':
                return pileForVisualization(element, offsetZ ?? 0, state.view, state.scale);
            case 'barriere':
            case 'glissiere':
                return barriereForVisualization(element, state.view, state.scale);
            case 'buse':
                return buseForVisualization(element, state.view, state.scale);
            case 'poutre':
                return cuboidForVisualization({ ...element, coordinates: { ...element.coordinates, z: offsetZ ?? 0 } }, state.view, state.scale);
            default:
                return cuboidForVisualization(element, state.view, state.scale);
        }
    }, [element, state.scale, state.view])

    const handleStartMoveHandle = (e: TouchEvent | MouseEvent, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        setMovingHandle(index);
    }
    const handleEndMoveHandle = () => {
        if (rectangle && onChange) {
            onChange({
                coordinates: {
                    x: state.view === 'back' ? -rectangle.x - rectangle.width : ['front', 'top'].includes(state.view) ? rectangle.x : element.coordinates.x,
                    y: state.view === 'right' ? -rectangle.x - rectangle.width : state.view === 'left' ? rectangle.x : state.view === 'top' ? rectangle.y : element.coordinates.y,
                    z: ['back', 'right', 'front', 'left'].includes(state.view) ? rectangle.y : element.coordinates.z,
                },
                dimensions: {
                    length: ['front', 'top', 'back'].includes(state.view) ? rectangle.width : element.dimensions?.length ?? 10,
                    width: ['right', 'left'].includes(state.view) ? rectangle.width : state.view === 'top' ? rectangle.height : element.dimensions?.width ?? 10,
                    height: ['back', 'right', 'front', 'left'].includes(state.view) ? rectangle.height : element.dimensions?.height ?? 10,
                }
            })
        }
    }

    useEffect(() => {
        setRectangle(elementForVisualization.bbox);
    }, [elementForVisualization]);

    useEffect(() => {
        if (movingHandle && onChange) {

            const onMouseMove = (event: Event) => {
                setRectangle(rectangle => {
                    if (!rectangle) return;
                    const coordinates = { x: (event as any).clientX, y: (event as any).clientY };
                    const svgCoordinates = domCoordinatesToRealCoordinates(state, {
                        x: coordinates.x - state.bbox.x,
                        y: coordinates.y - state.bbox.y,
                    });

                    if (movingHandle === 1) {
                        return {
                            x: svgCoordinates.x < rectangle.x + rectangle.width ? svgCoordinates.x : rectangle.x,
                            y: svgCoordinates.y < rectangle.y + rectangle.height ? svgCoordinates.y : rectangle.y,
                            width: svgCoordinates.x < rectangle.x + rectangle.width ? rectangle.x + rectangle.width - svgCoordinates.x : rectangle.width,
                            height: svgCoordinates.y < rectangle.y + rectangle.height ? rectangle.y + rectangle.height - svgCoordinates.y : rectangle.height,
                        };
                    } else if (movingHandle === 2) {
                        return {
                            ...rectangle,
                            y: svgCoordinates.y < rectangle.y + rectangle.height ? svgCoordinates.y : rectangle.y,
                            width: svgCoordinates.x > rectangle.x ? svgCoordinates.x - rectangle.x : rectangle.width,
                            height: svgCoordinates.y < rectangle.y + rectangle.height ? rectangle.y + rectangle.height - svgCoordinates.y : rectangle.height,
                        };
                    } else if (movingHandle === 3) {
                        return {
                            ...rectangle,
                            width: svgCoordinates.x > rectangle.x ? svgCoordinates.x - rectangle.x : rectangle.width,
                            height: svgCoordinates.y > rectangle.y ? svgCoordinates.y - rectangle.y : rectangle.height
                        };
                    } else {
                        return {
                            ...rectangle,
                            x: svgCoordinates.x < rectangle.x + rectangle.width ? svgCoordinates.x : rectangle.x,
                            width: svgCoordinates.x < rectangle.x + rectangle.width ? rectangle.x + rectangle.width - svgCoordinates.x : rectangle.width,
                            height: svgCoordinates.y > rectangle.y ? svgCoordinates.y - rectangle.y : rectangle.height
                        };
                    }
                });
            }
            const onMouseUp = () => setMovingHandle(undefined);

            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);

            return () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            }
        } else if (!movingHandle) {
            handleEndMoveHandle()
        }
    }, [movingHandle]);

    if (hide || !rectangle) return null;

    return (
        <g
            className={`visualization-element ${element.type ? 'visualization-' + element.type : ''} ${selected ? 'selected' : !onClick ? 'ghost' : ''}`}
            onTouchEnd={onClick}
            onClick={onClick}
        >
            {state.view === 'bottom' && elementForVisualization.topPath && <path d={elementForVisualization.topPath} />}
            {!!elementForVisualization.innerPath && <path d={elementForVisualization.innerPath} />}
            <path d={elementForVisualization.path} />
            {state.view === 'top' && elementForVisualization.topPath && <path d={elementForVisualization.topPath} />}
            <text
                x={rectangle.x + rectangle.width / 2}
                y={rectangle.y + rectangle.height / 2}
                fontSize={11 / state.zoom}
                strokeWidth={3 / state.zoom}
                stroke="white"
            >
                {element.name}
            </text>
            <text
                x={rectangle.x + rectangle.width / 2}
                y={rectangle.y + rectangle.height / 2}
                fontSize={11 / state.zoom}
            >
                {element.name}
            </text>
            {movingHandle !== undefined && (
                <rect
                    x={rectangle.x}
                    y={rectangle.y}
                    width={rectangle.width}
                    height={rectangle.height}
                />
            )}
            {!!selected && !!onChange && !element.type && (
                <Fragment>
                    <circle
                        cx={rectangle.x}
                        cy={rectangle.y}
                        r={3 / state.zoom}
                        onMouseDown={(e) => handleStartMoveHandle(e, 1)}
                    />
                    <circle
                        cx={rectangle.x + rectangle.width}
                        cy={rectangle.y}
                        r={3 / state.zoom}
                        onMouseDown={(e) => handleStartMoveHandle(e, 2)}
                    />
                    <circle
                        cx={rectangle.x + rectangle.width}
                        cy={rectangle.y + rectangle.height}
                        r={3 / state.zoom}
                        onMouseDown={(e) => handleStartMoveHandle(e, 3)}
                    />
                    <circle
                        cx={rectangle.x}
                        cy={rectangle.y + rectangle.height}
                        r={3 / state.zoom}
                        onMouseDown={(e) => handleStartMoveHandle(e, 4)}
                    />
                </Fragment>
            )}
            {!!selected && <Dimensions dimensions={elementForVisualization.dimensions} bbox={elementForVisualization.bbox} />}
        </g>
    );
}

interface VisualizationElementsProps {
    elements?: OuvrageElement[];
    selected?: OuvrageElement;
    onClick?: (e: OuvrageElement) => void;
    onChange?: (e: Pick<OuvrageElement, "coordinates" | "dimensions">) => void;
    offsetZ?: number;
}

const VisualizationElements = ({ elements, selected, onClick, onChange, offsetZ }: VisualizationElementsProps) => {
    const { view } = useVisualisation();

    const sortedElements = useMemo(() => [...elements ?? []].sort((e1, e2) => {
        switch (view) {
            case 'top':
                return (['barriere', 'glissiere'].includes(e2.type ?? '')
                    ? -e2.dimensions.height
                    : ['pile', 'poutre'].includes(e2.type ?? '')
                        ? e2.coordinates.z + (offsetZ ?? 0)
                        : e2.coordinates.z)
                    - (['barriere', 'glissiere'].includes(e1.type ?? '')
                        ? -e1.dimensions.height
                        : ['pile', 'poutre'].includes(e1.type ?? '')
                            ? e1.coordinates.z + (offsetZ ?? 0)
                            : e1.coordinates.z);
            case 'bottom':
                return (['barriere', 'glissiere'].includes(e1.type ?? '')
                    ? 0
                    : ['pile', 'poutre'].includes(e1.type ?? '')
                        ? e1.coordinates.z + (offsetZ ?? 0) + e1.dimensions.height
                        : e1.coordinates.z + e1.dimensions.height) - (['barriere', 'glissiere'].includes(e2.type ?? '')
                            ? 0
                            : ['pile', 'poutre'].includes(e2.type ?? '')
                                ? e2.coordinates.z + (offsetZ ?? 0) + e2.dimensions.height
                                : e2.coordinates.z + e2.dimensions.height);
            case 'left':
                return e2.coordinates.x - e1.coordinates.x;
            case 'right':
                return (e1.coordinates.x + e1.dimensions.length) - (e2.coordinates.x + e2.dimensions.length);
            case 'front':
                return e2.coordinates.y - e1.coordinates.y;
            default:
                return (e1.coordinates.y + e1.dimensions.width) - (e2.coordinates.y + e2.dimensions.width);
        }
    }), [elements, view, selected?._id, offsetZ]);

    return (
        <Fragment>
            {sortedElements?.map(element => element._id === selected?._id && onChange ? null : (
                <VisualizationElement
                    key={element._id}
                    element={element._id === selected?._id ? selected : element}
                    selected={element._id === selected?._id}
                    onClick={!selected && onClick ? () => onClick(element) : undefined}
                    offsetZ={offsetZ}
                />
            ))}
            {!!selected && onChange && (
                <VisualizationElement
                    element={selected}
                    selected={true}
                    onChange={onChange}
                    onClick={!selected && onClick ? () => onClick(selected) : undefined}
                    offsetZ={offsetZ}
                />
            )}
        </Fragment>
    );
}
export default VisualizationElements;