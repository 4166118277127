import { useCallback, useEffect } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvrageMurFormProps {
    mur: OuvrageElement;
    onClose: () => void;
    onChange: (mur: OuvrageElement) => void;
    onSubmit: (tablier: OuvrageElement) => void;
}

const VALIDATION = {
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.width': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
}

const OuvrageMurForm = ({ onClose, mur, onChange, onSubmit }: OuvrageMurFormProps) => {
    const { entity, attachInput, validate, onChangeMultiple } = useForm<OuvrageElement>(mur);

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const element = validate(VALIDATION);
        if (element) {
            onChange(element);
        }
    }, [entity]);

    useEffect(() => {
        if (
            mur.dimensions.length !== entity.dimensions?.length ||
            mur.dimensions.width !== entity.dimensions?.width ||
            mur.dimensions.height !== entity.dimensions?.height
        ) {
            onChangeMultiple([{ field: 'dimensions', value: mur.dimensions }]);
        }
    }, [mur]);

    return (
        <PanelRight
            title="Mur"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div className="input-column">
                <label htmlFor="length">Longueur *</label>
                <NumberInput {...attachInput('dimensions.length')} />
            </div>
            <div className="input-column">
                <label htmlFor="width">Largeur *</label>
                <NumberInput {...attachInput('dimensions.width')} />
            </div>
            <div className="input-column">
                <label htmlFor="height">Epaisseur *</label>
                <NumberInput {...attachInput('dimensions.height')} />
            </div>
        </PanelRight>
    )
}
export default OuvrageMurForm;