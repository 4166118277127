import { CoordinatesXY } from "../models/location";
import { Marker } from "../models/marker";
import {
    Exit,
    Operation,
    RoadDto,
    RoadNameLabel,
    RoadPositions,
} from "../models/operation";
import { PopulationSynoptique } from "../models/population";
import { Sample } from "../models/sample";
import { RoadPosition } from "./../models/operation";
import Synoptique, {
    ActionDoneType,
    LotForSynoptique,
    MarkerForSynoptique,
    PopulationForSynoptique,
    SampleForSynoptique,
    SynoptiqueElements,
} from "./Synoptique.class";

type WayForSynoptique = {
    y1: number;
    y2: number;
    prStart: number;
    prEnd: number;
    height: number;
};

type RoadForSynoptique = RoadDto & {
    y1: number;
    y2: number;
    way: 1 | 2;
    position?: RoadPosition;
};

type PositionForSynoptique = RoadDto & {
    y1: number;
    y2: number;
    way: 1 | 2;
    position?: RoadPosition;
};

class LinearSynoptique extends Synoptique {
    style = {
        way: {
            strokeStyle: "#bec1cc",
            fillStyle: "#f0f0f5",
            font: "bold 14px Roboto",
            lineWidth: 2,
        },
        wayText: {
            strokeStyle: "#828999",
            fillStyle: "#828999",
            font: "bold 14px Roboto",
            lineWidth: 2,
        },
        exit: {
            font: "bold 14px Roboto",
            strokeStyle: "#000000",
            fillStyle: "#000000",
            lineWidth: 2,
            lineCap: "butt",
        },
        exitWithOperation: {
            strokeStyle: "#000000",
            fillStyle: "#000000",
            font: "italic bold 13px Roboto",
            lineWidth: 1,
        },
        road: {
            strokeStyle: "#d5d7dd",
            fillStyle: "#ffffff",
            font: "italic 18px Roboto",
            lineWidth: 2,
        },
        roadText: {
            strokeStyle: "#d5d7dd",
            fillStyle: "#d5d7dd",
            font: "italic 18px Roboto",
            lineWidth: 2,
        },
        pr: {
            strokeStyle: "#bec1cc",
            fillStyle: "#828999",
            font: "bold 12px Roboto",
            lineWidth: 1,
            textAlign: "center",
        },
    };
    margin = { top: 35, right: 100, bottom: 35, left: 100 };
    pr = {
        min: 0,
        max: 0,
    };
    exitOffsets = {
        x: 60,
        y: 18,
    };
    way1: WayForSynoptique;
    way2: WayForSynoptique | null = null;
    roads: RoadForSynoptique[] = [];
    positions: PositionForSynoptique[] = [];

    constructor(
        canvasRef: HTMLCanvasElement,
        operation: Operation,
        onActionDone: ActionDoneType
    ) {
        super(canvasRef, operation, onActionDone);

        let y = 0;

        // If two ways
        if (this.operation?.way2?.active && this.operation.way2.roads?.length) {
            this.way2 = {
                y1: y,
                y2: y,
                prStart: this.operation.way2.prStart,
                prEnd: this.operation.way2.prEnd,
                height: 0,
            };
            const roads2 = (this.operation?.way2?.roadsPopulated ?? []).filter(
                (r) => !!r.active
            );

            for (const road of roads2) {
                const yRoad = y + (this.grid.height * 3 + 1) * road.order;
                this.roads.push({
                    ...road,
                    y1: yRoad,
                    y2: yRoad + this.grid.height * 3,
                    way: 2,
                });
                for (let e = 0; e < 3; e++) {
                    this.positions.push({
                        ...road,
                        y1: yRoad + e * this.grid.height,
                        y2: yRoad + (e + 1) * this.grid.height,
                        way: 2,
                        position: RoadPositions[e],
                    });
                }
                this.way2.y2 = Math.max(
                    this.way2.y2,
                    yRoad + this.grid.height * 3
                );
                // Height = number of roads = max road order + 1
                this.way2.height = Math.max(this.way2.height, road.order + 1);
            }
            // Add pr legend height
            y = this.way2.y2 + 3 * this.grid.height;
        } else {
            y = this.margin.top;
        }

        this.way1 = {
            y1: y,
            y2: y,
            prStart: this.operation.way1.prStart,
            prEnd: this.operation.way1.prEnd,
            height: 0,
        };
        const roads1 = (this.operation?.way1?.roadsPopulated ?? []).filter(
            (r) => !!r.active
        );

        for (const road of roads1) {
            const yRoad = y + this.grid.height * 3 * road.order;
            this.roads.push({
                ...road,
                y1: yRoad,
                y2: yRoad + this.grid.height * 3,
                way: 1,
            });
            for (let e = 0; e < 3; e++) {
                this.positions.push({
                    ...road,
                    y1: yRoad + e * this.grid.height,
                    y2: yRoad + (e + 1) * this.grid.height,
                    way: 1,
                    position: RoadPositions[e],
                });
            }
            this.way1.y2 = Math.max(
                this.way1.y2 ?? 0,
                yRoad + this.grid.height * 3
            );
            this.way1.height = Math.max(this.way1.height, road.order + 1);
        }

        this.pr.min = Math.floor(
            this.way2
                ? Math.min(this.way1.prStart, this.way2.prEnd)
                : this.way1.prStart
        );
        this.pr.max = Math.ceil(
            this.way2
                ? Math.max(this.way1.prEnd, this.way2.prStart)
                : this.way1.prEnd
        );

        // Set synoptique dimensions
        this.synoptique = {
            height:
                3 * this.grid.height +
                this.grid.height *
                    3 *
                    (this.way1.height + (this.way2?.height ?? 0)),
            width: (this.pr.max - this.pr.min) * this.grid.width * 10,
        };

        this.sizeSynoptique();

        if (this.canvasOriginal?.width > 0 && this.canvas?.width > 0) {
            while (
                this.canvas.width < this.canvasOriginal?.width &&
                this.canvas?.width > 0
            ) {
                this.zoomIn();
                this.sizeSynoptique();
            }
        }
    }

    public getScrollFromPr(pr: number): number {
        return Math.floor(this.withZoomAndMarginX(this.getXFromPr(pr)));
    }

    getXFromPr(pr: number) {
        return this.grid.width * (pr - this.pr.min) * 10;
    }

    getYFromRoad(roadPositionIndex: number) {
        const position = this.positions[roadPositionIndex];
        return (position?.y1 ?? 0) + this.grid.height / 2;
    }

    getRoadName(roadPositionIndex: number) {
        const position = this.positions[roadPositionIndex];
        return position?.name ?? "";
    }

    getRoadPositionIndex(roadId: string, roadPosition: RoadPosition) {
        return this.positions.findIndex(
            (r) => r._id === roadId && r.position === roadPosition
        );
    }

    render() {
        super.render();
        this.drawWays();
        this.drawRoads();
        this.drawGrid();
        this.drawPr();
        this.drawElements();
    }

    localizeElements(elements: SynoptiqueElements) {
        let firstElementPosition: CoordinatesXY | null = null;

        this.elements.lots = elements.lots?.map((l) => {
            const lotRoads = this.roads.filter(
                (r) => l.roads.includes(r._id) && l.zone.prStart !== undefined
            );
            const x = this.getXFromPr(l.zone.prStart!);
            const y = Math.min(...lotRoads.map((l) => l.y1)) + 4;
            const height = Math.max(...lotRoads.map((l) => l.y2)) - y - 4;
            const width =
                ((l.zone.prEnd ?? 0) - (l.zone.prStart ?? 0)) *
                this.grid.width *
                10;

            // Handle first element to display
            if (!firstElementPosition || firstElementPosition.x > x) {
                firstElementPosition = { x, y };
            }
            return {
                ...l,
                canvasPosition: { x, y },
                htmlPosition: this.getHtmlPosition({
                    x: x + width / 2,
                    y: y,
                }),
                height,
                width,
                polygon: [],
            };
        });

        this.elements.melanges = elements.melanges?.map((m) => {
            const polygon: CoordinatesXY[] = [];
            for (const s of m.samplesPopulated ?? []) {
                if (
                    s?.location.pr !== undefined &&
                    s?.location.road &&
                    s?.location.roadPosition
                ) {
                    polygon.push({
                        x: this.getXFromPr(s.location.pr),
                        y: this.getYFromRoad(
                            this.getRoadPositionIndex(
                                s.location.road,
                                s.location.roadPosition
                            )
                        ),
                    });
                }
            }
            return { ...m, polygon };
        });

        const localizedElement = (
            e: PopulationSynoptique | Sample | Marker
        ):
            | PopulationForSynoptique
            | SampleForSynoptique
            | MarkerForSynoptique => {
            const roadPositionIndex = this.getRoadPositionIndex(
                e.location.road!,
                e.location.roadPosition!
            );
            const x = this.getXFromPr(e.location.pr!);
            const y = this.getYFromRoad(roadPositionIndex);

            // Handle first element to display
            if (!firstElementPosition || firstElementPosition.x > x) {
                firstElementPosition = { x, y };
            }
            return {
                ...e,
                canvasPosition: { x, y },
                htmlPosition: this.getHtmlPosition({ x, y }),
                roadName: this.getRoadName(roadPositionIndex),
                roadPositionIndex: roadPositionIndex,
            };
        };

        this.elements.samples = elements.samples
            ?.filter(
                (s) =>
                    s.location.road &&
                    s.location.roadPosition &&
                    s.location.pr !== undefined
            )
            .map(localizedElement) as SampleForSynoptique[];

        this.elements.markers = elements.markers
            ?.filter(
                (s) =>
                    s.location.road &&
                    s.location.roadPosition &&
                    s.location.pr !== undefined
            )
            .map(localizedElement) as MarkerForSynoptique[];

        this.elements.populations = elements.populations
            ?.filter(
                (s) =>
                    s.location.road &&
                    s.location.roadPosition &&
                    s.location.pr !== undefined
            )
            .map(localizedElement) as PopulationForSynoptique[];

        this.firstElementPosition = firstElementPosition;
    }

    drawGrid() {
        // Grid
        this.setStyle(this.baseStyle.grid);

        // Vertical lines
        for (let i = this.pr.min * 10; i <= this.pr.max * 10; i++) {
            const x = this.withZoomAndMarginX(this.getXFromPr(i / 10));
            this.drawLine(
                x,
                this.margin.top + this.way1.y1,
                x,
                this.margin.top + this.way1.y2
            );
            if (this.way2) {
                this.drawLine(
                    x,
                    this.margin.top + this.way2.y1,
                    x,
                    this.margin.top + this.way2.y2
                );
            }
        }

        // Horizontal lines
        for (let i = 0; i < this.positions.length; i++) {
            this.drawLine(
                this.margin.left,
                this.margin.top + this.positions[i].y2,
                this.ctx.canvas.width - this.margin.right,
                this.margin.top + this.positions[i].y2
            );
        }

        this.setStyle(this.style.way);

        this.drawLine(
            0,
            this.margin.top + this.way1.y1,
            this.canvas.width,
            this.margin.top + this.way1.y1
        );
        this.drawLine(
            0,
            this.margin.top + this.way1.y2,
            this.canvas.width,
            this.margin.top + this.way1.y2
        );

        if (this.way2) {
            this.drawLine(
                0,
                this.margin.top + this.way2.y1,
                this.canvas.width,
                this.margin.top + this.way2.y1
            );
            this.drawLine(
                0,
                this.margin.top + this.way2.y2,
                this.canvas.width,
                this.margin.top + this.way2.y2
            );
        }
    }

    drawWays() {
        this.setStyle(this.style.way);
        this.drawRect(
            0,
            this.margin.top + this.way1.y1,
            this.withZoomAndMarginX(this.synoptique.width),
            this.way1.y2 - this.way1.y1,
            false,
            true
        );

        if (this.way2) {
            this.drawRect(
                0,
                this.margin.top + this.way2.y1,
                this.withZoomAndMarginX(this.synoptique.width),
                this.way2.y2 - this.way2.y1,
                false,
                true
            );
        }

        let lastExitTextEnd = 0;
        (this.operation.way1?.exits ?? []).forEach((exit, index) => {
            lastExitTextEnd = this.drawExit(exit, lastExitTextEnd, index, 1);
        });

        lastExitTextEnd = 0;
        (this.operation.way2?.exits ?? []).forEach((exit, index) => {
            lastExitTextEnd = this.drawExit(exit, lastExitTextEnd, index, 2);
        });
    }

    drawExit(
        exit: Exit,
        lastExitTextEnd: number,
        index: number,
        wayNumber: 1 | 2
    ) {
        this.setStyle(this.style.exit);
        const prX = this.withZoomAndMarginX(this.getXFromPr(exit.pr));
        const way = wayNumber === 1 ? this.way1 : this.way2;

        if (!way) {
            return lastExitTextEnd;
        }

        const border = wayNumber === 1 ? way.y2 : way.y1;
        const coef = wayNumber === 1 ? 1 : -1;
        const name = `${index + 1} : ${exit.name}`;

        const width = this.ctx.measureText(name).width;
        const height = this.exitOffsets.y;

        const EXIT_SIZE = 4;

        let x = prX - width / 2 + EXIT_SIZE * 2;

        if (x - width / 2 < lastExitTextEnd)
            x = lastExitTextEnd - EXIT_SIZE * 2;
        const y =
            this.margin.top + border + coef * 30 + (wayNumber === 2 ? 10 : 0);

        // Drawl legend
        this.setStyle({
            ...this.style.exit,
            font: "normal 12px Roboto",
        });

        this.drawText(x, y, name);

        this.exits.push({ ...exit, x, y, height, width });

        // Draw icon
        this.setStyle({
            ...this.style.exit,
            font: "bold 12px Roboto",
        });

        this.drawLine(
            prX - EXIT_SIZE,
            this.margin.top + border + coef * EXIT_SIZE * 3,
            prX + EXIT_SIZE,
            this.margin.top + border + coef * EXIT_SIZE
        );
        this.drawLine(
            prX + EXIT_SIZE,
            this.margin.top + border + coef * EXIT_SIZE,
            prX + EXIT_SIZE - EXIT_SIZE * 1.5,
            this.margin.top + border + coef * EXIT_SIZE
        );
        this.drawLine(
            prX + EXIT_SIZE,
            this.margin.top + border + coef * EXIT_SIZE,
            prX + EXIT_SIZE,
            this.margin.top + border + coef * EXIT_SIZE * 2.5
        );
        this.drawText(
            prX + EXIT_SIZE * 2,
            this.margin.top + border + coef * 13 + (wayNumber === 2 ? 10 : 0),
            String(index + 1)
        );

        return x + width;
    }

    drawRoads() {
        for (const road of this.roads) {
            const prStart = Math.min(road.prStart, road.prEnd);
            const prEnd = Math.max(road.prStart, road.prEnd);
            const start = this.withZoomAndMarginX(this.getXFromPr(prStart));
            const end = this.withZoomAndMarginX(this.getXFromPr(prEnd));
            const name = `${RoadNameLabel[road.name]}${
                road.index !== undefined ? " " + road.index : ""
            }`;

            // Draw road
            this.setStyle(this.style.road);
            this.drawRect(
                start,
                this.margin.top + road.y1,
                end - start,
                road.y2 - road.y1,
                true,
                true
            );

            // Draw road text
            this.setStyle(this.style.roadText);
            let prText = prStart;
            do {
                const x = this.withZoomAndMarginX(this.getXFromPr(prText));
                if (x + this.ctx.measureText(name).width > end) {
                    break;
                }
                this.drawText(
                    x + 5,
                    this.margin.top + road.y1 + this.grid.height * 3 - 3,
                    name
                );
                prText = Math.ceil(prText) + 2;
            } while (prText < prEnd);
        }
    }

    drawPr() {
        this.setStyle(this.style.pr);

        // Text
        const yText = this.way2
            ? this.way2.y2 + this.grid.height * 1.5 + 5
            : this.way1.y1 - 25;

        for (let i = this.pr.min; i <= this.pr.max; i++) {
            const x = this.withZoomAndMarginX(
                (i - this.pr.min) * this.grid.width * 10
            );

            // Line way 1
            this.drawLine(
                x,
                this.margin.top + this.way1.y1 - 8,
                x,
                this.margin.top + this.way1.y2
            );
            // Line way 2
            if (this.way2) {
                this.drawLine(
                    x,
                    this.margin.top + this.way2.y1,
                    x,
                    this.margin.top + this.way2.y2 + 8
                );
            }

            // Text
            this.drawText(x, this.margin.top + yText, i + " km");
        }
    }

    drawLot(lot: LotForSynoptique) {
        const realX = this.withZoomAndMarginX(lot.canvasPosition.x);
        const realY = this.withZoomAndMarginY(lot.canvasPosition.y);

        const selected = !!this.selectedLayers?.some((l) => l._id === lot._id);
        this.setStyle(
            selected ? this.baseStyle.lotSelected : this.baseStyle.lot
        );
        this.drawRect(
            realX,
            realY,
            this.withZoomX(lot.width),
            lot.height,
            true,
            true
        );

        this.setStyle(this.baseStyle.lotTextRect);
        const text =
            "Lot " + lot.fullLot + " - " + (lot.materialPopulated?.name ?? "");
        const textWidth = this.ctx.measureText(text).width;

        this.drawRect(realX + 2, realY + 2, textWidth + 10, 14, true, true);

        this.setStyle(
            selected ? this.baseStyle.lotSelectedText : this.baseStyle.lotText
        );
        this.drawText(realX + 5, realY + 14, text);
    }

    protected startSelectionDiv(event: MouseEvent | TouchEvent) {
        super.startSelectionDiv(event);
        // Start selection div on road position
        if (!this.selectionDiv) return;
        const startPosition = this.positions.find(
            (p) => p.y1 <= this.selectionDiv!.y && p.y2 >= this.selectionDiv!.y
        );
        if (startPosition) {
            this.selectionDiv.startY = startPosition.y1;
            this.selectionDiv.y = startPosition.y1;
        }
    }

    moveSelectionDiv(event: MouseEvent | TouchEvent) {
        super.moveSelectionDiv(event);
        // End selection div on road position
        if (!this.selectionDiv) return;

        const endPosition = this.positions.find(
            (p) =>
                p.y1 <= this.selectionDiv!.y + this.selectionDiv!.height &&
                p.y2 >= this.selectionDiv!.y + this.selectionDiv!.height
        );
        if (endPosition) {
            this.selectionDiv.height = Math.abs(
                endPosition.y2 - this.selectionDiv.y
            );
        }
    }

    zoomIn() {
        this.zoom.x *= this.zoomStep;

        const newCanvaswidth = Math.floor(
            this.withZoomX(this.synoptique.width) +
                this.margin.left +
                this.margin.right
        );

        if (newCanvaswidth > this.zoomLimit) {
            this.zoom.x /= this.zoomStep;
        }
    }

    zoomOut() {
        this.zoom.x /= this.zoomStep;
    }
}

export default LinearSynoptique;
