import { CoordinatesXYZ } from "./location";
import { BasicEntity } from "./shared";

export interface OuvrageMarker extends BasicEntity {
    name: string;
    description: string;
    localisation?: string;
    ouvrage: string;
    element?: string;
    diagnostic?: string;
    storageFiles?: string[];
    date: Date;
    coordinates: CoordinatesXYZ;
    pathologies?: Record<string, Record<string, OuvragePathology | undefined>>;
}

export interface OuvragePathology {
    length?: number;
    width?: number;
    quantity?: number;
    present: boolean;
}

export interface OuvragePathologyType {
    type: string;
    label: string;
    pathologies: {
        type: string;
        label: string;
    }[];
}

export const OUVRAGE_PATHOLOGIES: OuvragePathologyType[] = [
    {
        type: "fissures",
        label: "Fissures",
        pathologies: [
            {
                type: "fissure_inf_0_3",
                label: "Fissure d'ouverture < 0.3 mm",
            },
            {
                type: "fissure_0_3_a_1",
                label: "Fissure d'ouverture ≥ 0.3 mm et < 1.0 mm",
            },
            {
                type: "fissure_sup_1",
                label: "Fissure d'ouverture ≥ 1.0mm",
            },
            { type: "faiencage", label: "Faïençage" },
            {
                type: "reprise_betonnage",
                label: "Reprise de bétonnage fissurée",
            },
            { type: "ragreage", label: "Ragréage" },
            { type: "ragreage_faience", label: "Ragréage faïencé" },
        ],
    },
    {
        type: "humidite",
        label: "Humidité",
        pathologies: [
            {
                type: "traces_seches_ecoulement_salissures",
                label: "Traces sèches d'écoulement/Salissures",
            },
            { type: "humidite", label: "Humidité" },
            { type: "arrivee_eau", label: "Arrivée d'eau" },
            { type: "moisissure_verdatre", label: "Moisissure verdâtre" },
            {
                type: "efflorescence_seche_humide",
                label: "Efflorescence sèche ou humide",
            },
            { type: "concretion_seche", label: "Concrétion sèche" },
            {
                type: "concretion_active_humide",
                label: "Concrétion active ou humide",
            },
            { type: "stalactite_seche", label: "Stalactite sèche" },
            {
                type: "stalactite_active_humide",
                label: "Stalactite active ou humide",
            },
            { type: "coulure_de_fines", label: "Coulure de fines" },
        ],
    },
    {
        type: "parement",
        label: "Parement",
        pathologies: [
            { type: "alteration_profonde", label: "Altération profonde" },
            {
                type: "alteration_superficielle",
                label: "Altération superficielle",
            },
            { type: "descellement", label: "Descellement" },
            { type: "desorganisation", label: "Désorganisation" },
            { type: "pierre_fracturee", label: "Pierre fracturée" },
            {
                type: "deformation_plan_deversement",
                label: "Déformation plan/Déversement",
            },
            { type: "bombement", label: "Bombement" },
            {
                type: "segregation_nid_cailloux",
                label: "Ségrégation/Nid de cailloux",
            },
            { type: "epaufrure", label: "Epaufrure" },
            { type: "epaufrure_acier", label: "Epaufrure avec acier" },
            { type: "acier_apparent_oxyde", label: "Acier apparent oxydé" },
            { type: "amorce_eclat", label: "Amorce d'éclat" },
            {
                type: "traces_frottementfrottement",
                label: "Traces de frottement",
            },
            { type: "eclat_beton", label: "Eclat béton" },
            {
                type: "eclat_acier_apparent",
                label: "Eclat avec acier apparent",
            },
            { type: "traces_rouille", label: "Traces de rouille" },
            {
                type: "disjointement_alteration_joints",
                label: "Disjointoiement/Altération des joints",
            },
            { type: "cavite_effondrement", label: "Cavité/Effondrement" },
            { type: "lacune", label: "Lacune" },
        ],
    },
    {
        type: "metallique",
        label: "Métallique",
        pathologies: [
            { type: "corrosion", label: "Corrosion" },
            { type: "ecaillage_peinture", label: "Ecaillage peinture" },
            {
                type: "perte_revetement_peinture",
                label: "Perte de revêtement peinture",
            },
            { type: "piqures_rouille", label: "Piqûres de rouille" },
        ],
    },
    {
        type: "divers",
        label: "Divers",
        pathologies: [
            { type: "vegetation", label: "Végétation" },
            { type: "depots_divers", label: "Dépôts divers" },
            { type: "affiche", label: "Affiche" },
            { type: "graffitis", label: "Graffitis" },
            { type: "rien_a_signaler", label: "Rien à signaler" },
        ],
    },
    {
        type: "chaussee",
        label: "Chaussée",
        pathologies: [
            { type: "fissure_chaussee", label: "Fissure sur chaussée" },
            { type: "fissure_traitee", label: "Fissure traitée" },
            { type: "nid_poule", label: "Nid de poule" },
            { type: "cloquage", label: "Cloquage" },
            {
                type: "arrachement_revetement",
                label: "Arrachement du revêtement",
            },
            { type: "flache", label: "Flache" },
            { type: "ornierage", label: "Orniérage" },
            { type: "fracture", label: "Fracture" },
        ],
    },
    {
        type: "joints",
        label: "Joints",
        pathologies: [
            {
                type: "joint_chaussee_encombre",
                label: "Joint de chaussée encombré",
            },
            {
                type: "joint_chaussee_degarni",
                label: "Joint de chaussée dégarni",
            },
            { type: "solin_degrade", label: "Solin dégradé" },
            { type: "marche_escalier", label: "Marche d'escalier" },
            { type: "tassement", label: "Tassement" },
        ],
    },
    {
        type: "equipement",
        label: "Equipement",
        pathologies: [
            { type: "etancheite_degradee", label: "Etanchéité dégradée" },
            { type: "element_accidente", label: "Elément accidenté" },
            {
                type: "oxydation_boulonnerie",
                label: "Oxydation (Boulonnerie)",
            },
            {
                type: "point_bas_stagnation_eau",
                label: "Point bas/Stagnation d'eau",
            },
        ],
    },
    {
        type: "abords",
        label: "Abords",
        pathologies: [
            { type: "ravinement", label: "Ravinement" },
            { type: "affouillement", label: "Affouillement" },
        ],
    },
];
