import { useCallback, useEffect } from "react";
import NumberInput from "../../../../components/inputs/NumberInput";
import PanelRight from "../../../../components/ui/PanelRight";
import useForm, { FormComparator } from "../../../../hooks/useForm";
import { OuvrageElement } from "../../../../models/ouvrage";

export interface OuvrageBuseFormProps {
    buse: OuvrageElement;
    onClose: () => void;
    onChange: (buse: OuvrageElement) => void;
    onSubmit: (tablier: OuvrageElement) => void;
}

const VALIDATION = {
    'dimensions.length': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.width': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    'dimensions.height': [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    thicknessV: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    thicknessH: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.POSITIVE_NUMBER }],
    biseauStart: [{ comparator: FormComparator.POSITIVE_NUMBER }],
    biseauEnd: [{ comparator: FormComparator.POSITIVE_NUMBER }],
}

const OuvrageBuseForm = ({ onClose, buse, onChange, onSubmit }: OuvrageBuseFormProps) => {
    const { entity, attachInput, validate, onChangeMultiple } = useForm<OuvrageElement>(buse);

    const handleSubmit = useCallback(() => {
        const element = validate(VALIDATION);
        if (element) {
            onSubmit(element);
            onClose();
        }
    }, [validate]);

    useEffect(() => {
        const element = validate(VALIDATION);
        if (element) {
            onChange(element);
        }
    }, [entity]);

    useEffect(() => {
        if (
            buse.dimensions.length !== entity.dimensions?.length ||
            buse.dimensions.width !== entity.dimensions?.width ||
            buse.dimensions.height !== entity.dimensions?.height ||
            buse.thicknessH !== entity.thicknessH ||
            buse.thicknessV !== entity.thicknessV ||
            buse.biseauStart !== entity.biseauStart ||
            buse.biseauEnd !== entity.biseauEnd
        ) {
            onChangeMultiple([
                { field: 'dimensions', value: buse.dimensions },
                { field: 'thicknessH', value: buse.thicknessH },
                { field: 'thicknessV', value: buse.thicknessV },
                { field: 'biseauStart', value: buse.biseauStart },
                { field: 'biseauEnd', value: buse.biseauEnd },
            ]);
        }
    }, [buse]);

    return (
        <PanelRight
            title="Buse"
            actions={[
                { label: 'Annuler', color: 'secondary', onClick: onClose },
                { label: 'Valider', color: 'primary', onClick: handleSubmit }
            ]}
        >
            <div className="input-column">
                <label htmlFor="length">Longueur *</label>
                <NumberInput {...attachInput('dimensions.length')} />
            </div>
            <div className="column">
                <h4>Diamètres</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="diameterH">Horizontal *</label>
                        <NumberInput {...attachInput('dimensions.width')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="diameterV">Vertical *</label>
                        <NumberInput {...attachInput('dimensions.height')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Epaisseurs</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="thicknessH">Horizontale *</label>
                        <NumberInput {...attachInput('thicknessH')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="thicknessV">Verticale *</label>
                        <NumberInput {...attachInput('thicknessV')} />
                    </div>
                </div>
            </div>
            <div className="column">
                <h4>Biseaux (degrés)</h4>
                <div className="row">
                    <div className="input-column">
                        <label htmlFor="biseauStart">Début</label>
                        <NumberInput {...attachInput('biseauStart')} />
                    </div>
                    <div className="input-column">
                        <label htmlFor="biseauEnd">Fin</label>
                        <NumberInput {...attachInput('biseauEnd')} />
                    </div>
                </div>
            </div>
        </PanelRight>
    )
}
export default OuvrageBuseForm;