import { Fragment } from "react";
import { useVisualisation } from "..";
import { formatNumberToFixedDecimal } from "../../../../utils/format";
import { VisualizationDimension } from "../types";

const SETTINGS = {
    horizontal: {
        offset: 7,
        ratio: 10
    },
    vertical: {
        offset: 13,
        ratio: 30
    }
};

interface VisualizationDimensionPathProps {
    dimension: VisualizationDimension;
    bbox: { x: number, y: number, width: number, height: number };
    view: string;
}

const VisualizationDimensionPath = ({ dimension, bbox, view }: VisualizationDimensionPathProps) => {
    const { side, offset, point1, point2, value } = dimension;
    if (side === 'left' || side === 'right') {
        const coef = view === 'back' ? -1 : 1;
        const lineX = side === 'left' ? bbox.x + coef * (-(offset + 1) * SETTINGS.vertical.ratio) : bbox.x + coef * bbox.width + coef * ((offset + 1) * SETTINGS.vertical.ratio);
        return (
            <Fragment>
                <line className="dimension" x1={point1.x} y1={point1.y} x2={lineX + coef * (SETTINGS.vertical.offset) * (side === 'right' ? 1 : -1)} y2={point1.y} />
                <line className="dimension" x1={point2.x} y1={point2.y} x2={lineX + coef * (SETTINGS.vertical.offset) * (side === 'right' ? 1 : -1)} y2={point2.y} />
                {Math.abs(point1.y - point2.y) > 20 && (
                    <Fragment>
                        <line className="dimension" x1={lineX} y1={point1.y} x2={lineX} y2={(point1.y + point2.y) / 2 - 8} />
                        <line className="dimension" x1={lineX} y1={point2.y} x2={lineX} y2={(point1.y + point2.y) / 2 + 8} />
                    </Fragment>
                )}
                <text className="dimension" x={lineX} y={(point1.y + point2.y) / 2 + 3}>{formatNumberToFixedDecimal(value / 100)}</text>
            </Fragment>
        );
    }

    const lineY = side === 'top'
        ? bbox.y + (-(offset + 1) * SETTINGS.horizontal.ratio)
        : bbox.y + bbox.height + ((offset + 1) * SETTINGS.horizontal.ratio);
    return (
        <Fragment>
            <line className="dimension" x1={point1.x} y1={point1.y} x2={point1.x} y2={lineY + (SETTINGS.horizontal.offset) * (side === 'bottom' ? 1 : -1)} />
            <line className="dimension" x1={point2.x} y1={point2.y} x2={point2.x} y2={lineY + (SETTINGS.horizontal.offset) * (side === 'bottom' ? 1 : -1)} />
            {Math.abs(point1.x - point2.x) > 30 && (
                <Fragment>
                    <line className="dimension" x1={point1.x} y1={lineY} x2={(point1.x + point2.x) / 2 - 15} y2={lineY} />
                    <line className="dimension" x1={point2.x} y1={lineY} x2={(point1.x + point2.x) / 2 + 15} y2={lineY} />
                </Fragment>
            )}
            <text className="dimension" x={(point1.x + point2.x) / 2} y={lineY + 3}>{formatNumberToFixedDecimal(value / 100)}</text>
        </Fragment>
    );
}


const VisualizationDimensions = ({ dimensions, bbox }: { dimensions: (VisualizationDimension & { key: string })[], bbox: { x: number, y: number, width: number, height: number } }) => {
    const { view } = useVisualisation();
    return (
        <g className="dimensions">
            {dimensions.map(d => <VisualizationDimensionPath key={d.key} dimension={d} bbox={bbox} view={view} />)}
        </g>
    )
}

export default VisualizationDimensions;