import { Icon } from "@iconify/react";
import { Fragment, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SampleDetail from "../../../components/SampleDetail";
import List, { FilterType, ListColumns } from "../../../components/data/List";
import { ButtonProps } from "../../../components/ui/Button";
import Tag from "../../../components/ui/Tag";
import { CoucheTypes, Lot } from "../../../models/lot";
import { Phase, RoadPositionLabel, RoadPositionsList } from "../../../models/operation";
import { Controles, Sample, SampleLabels, SampleType } from "../../../models/sample";
import { RevisionStatus, RevisionStatusList } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { formatDate } from "../../../utils/format";
import { floatToPrString } from "../../../utils/pr";

interface SampleListProps {
    phase: Phase;
    type: SampleType;
    lot?: Lot;
}

const SampleList = ({ phase, type, lot }: SampleListProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const navigate = useNavigate();
    const [isSelectModeForMelange, setSelectModeForMelange] = useState(false);
    const [selectedSamples, setSelectedSamples] = useState<Sample[] | null>(null);
    const [selectedSample, setSelectedSample] = useState<Sample | null>(null);
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const handleClose = useCallback((refresh?: boolean) => {
        setSelectedSample(null);
        if (refresh) {
            const refreshParam = URLSearchParams.get('refresh_list');
            URLSearchParams.set('refresh_list', refreshParam ? String(Number(refreshParam) + 1) : '1');
            setURLSearchParams(URLSearchParams);
        }
    }, [URLSearchParams]);

    return (
        <Fragment>
            <List<Sample>
                columns={[
                    { key: 'name', label: 'Nom' },
                    { key: 'problematic', label: 'Prob.', mapper: (element) => element.problematic ? <Icon icon="fluent:warning-16-regular" className="color-error" /> : (null) },
                    { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                    { key: 'controle', label: 'Contrôle', mapper: (element) => <Tag value={element.controle} items={Controles} /> },
                    ...(phase === Phase.TRAVAUX && !lot ? [{ key: 'lotPopulated.fullLot', label: 'Lot' }] : []),
                    ...(operation.synoptique === 1
                        ? [
                            { key: 'location.way', label: 'Sens', mapper: (element) => element?.location?.way ? operation.waysObj[element?.location?.way]?.label : '' },
                            { key: 'location.roadPopulated.name', label: 'Voie', mapper: (element) => element?.location?.road ? operation.roadsObj[element?.location?.road]?.labelWithoutWay : '' },
                            { key: 'location.roadPosition', label: 'Position', mapper: (element) => element.location?.roadPosition ? RoadPositionLabel[element.location?.roadPosition] : '' },
                            { key: 'location.pr', label: 'PR', mapper: (element) => floatToPrString(element.location.pr) ?? '' },
                        ] : [
                            { key: 'location.location', label: 'Localisation' },
                        ]) as ListColumns<Sample>[],
                    { key: 'date', label: 'Date', mapper: (element) => element.date ? formatDate(element.date) : '' },
                ]}
                dataEndpoint={`/sample/operation/${operation._id}/${phase}/${type}${lot ? '/' + lot._id : ''}`}
                crudEndpoint="/sample"
                baseUrl={!lot ? `/operation/${operation._id}/${phase}/${type}` : `/operation/${operation._id}/travaux/lot/${lot._id}/${type}`}
                withSearch
                actions={{
                    comment: workspacePermissions.write,
                    edit: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    view: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    activate: workspacePermissions.administrate,
                    duplicate: workspacePermissions.write,
                    delete: workspacePermissions.administrate
                }}
                onView={workspacePermissions.read ? setSelectedSample : undefined}
                initialPagination={{ sortBy: 'date', sortDirection: -1 }}
                onSelectMultiple={type === SampleType.CAROTTE && phase !== Phase.TRAVAUX && isSelectModeForMelange ? setSelectedSamples : undefined}
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'problematic', label: 'Problématique', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                    { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                    { field: 'controle', label: 'Contrôle', type: FilterType.MULTIPLE_SELECT, items: Controles },
                    { field: 'analysis', label: 'Analyse', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                    ...(phase === Phase.TRAVAUX && !lot ? [
                        { field: 'fullLot', label: 'Lot', type: FilterType.MULTIPLE_SELECT, endpoint: `/lot/list/${operation._id}` },
                        { field: 'material', label: 'Matériau', type: FilterType.MULTIPLE_SELECT, endpoint: `/material/list/product/${operation._id}` },
                        { field: 'layer', label: 'Type de couche', type: FilterType.MULTIPLE_SELECT, items: CoucheTypes },
                    ] : []),
                    ...(operation.synoptique === 1
                        ? [
                            { field: 'prMin', label: 'PR minimum', type: FilterType.PR },
                            { field: 'prMax', label: 'PR maximum', type: FilterType.PR },
                            { field: 'road', label: 'Voies', type: FilterType.MULTIPLE_SELECT, items: operation.roads },
                            { field: 'roadPosition', label: 'Position', type: FilterType.MULTIPLE_SELECT, items: RoadPositionsList },
                        ] : []),
                ]}
                buttons={[
                    ...(phase !== Phase.TRAVAUX && type === SampleType.CAROTTE ? [
                        { color: 'secondary', label: isSelectModeForMelange && selectedSamples?.length ? SampleLabels.melange.create : SampleLabels.melange.select, onClick: () => isSelectModeForMelange && selectedSamples?.length ? navigate(`/operation/${operation._id}/${phase}/melange/creer?ids=${selectedSamples.map(s => s._id).join(',')}`) : setSelectModeForMelange(!isSelectModeForMelange) }
                    ] : []) as ButtonProps[],
                    ...(lot ? [
                        { color: 'secondary', label: SampleLabels[type].masse, onClick: () => navigate(`/operation/${operation._id}/${phase}/lot/${lot._id}/${type}/creer-en-masse`) }
                    ] : []) as ButtonProps[],
                    { label: SampleLabels[type].create, onClick: () => navigate(lot ? `/operation/${operation._id}/${phase}/lot/${lot._id}/${type}/creer` : `/operation/${operation._id}/${phase}/${type}/creer`) }
                ]}
            />
            <SampleDetail id={selectedSample?._id ?? undefined} onClose={handleClose} />
        </Fragment>
    )
}

export default SampleList;