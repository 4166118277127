// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ouvrage-element-form {
  overflow: hidden;
}
#ouvrage-element-form .col {
  height: 100%;
}

#ouvrage-blueprint-edit .scrollable-content-children {
  gap: 0;
  overflow: hidden;
}
#ouvrage-blueprint-edit .scrollable-content-children #ouvrage-blueprint-edit-visualization {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  position: relative;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ouvrage/Edit/index.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;;AAEA;EACI,MAAA;EACA,gBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACR","sourcesContent":["#ouvrage-element-form {\n    overflow: hidden;\n    & .col {\n        height: 100%;\n    }\n}\n\n#ouvrage-blueprint-edit .scrollable-content-children {\n    gap: 0;\n    overflow: hidden;\n\n    & #ouvrage-blueprint-edit-visualization {\n        display: flex;\n        flex-direction: row;\n        flex-grow: 1;\n        height: 100%;\n        position: relative;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
