import { MouseEvent, TouchEvent } from "react";
import { CoordinatesXY, CoordinatesXYZ } from "../../../models/location";
import { OuvrageElement } from "../../../models/ouvrage";
import {
    VisualizationDimension,
    VisualizationElement,
    VisualizationReducerAction,
    VisualizationReducerState,
} from "./types";

const PADDING = 40;

export const getEventCoordinates = (e: TouchEvent | MouseEvent) => {
    if ((e as any).touches) {
        const touch = e as TouchEvent;
        return { x: touch.touches[0].clientX, y: touch.touches[0].clientY };
    } else {
        const mouse = e as MouseEvent;
        return { x: mouse.clientX, y: mouse.clientY };
    }
};

const adaptView = (
    state: VisualizationReducerState
): VisualizationReducerState => {
    const width = ["left", "right"].includes(state.view)
        ? state.contentBbox.width
        : state.contentBbox.length;
    const height = ["top", "bottom"].includes(state.view)
        ? state.contentBbox.width
        : state.contentBbox.height;
    const x = ["left", "right"].includes(state.view)
        ? state.contentBbox.y
        : state.contentBbox.x;
    const y = ["top", "bottom"].includes(state.view)
        ? state.contentBbox.y
        : state.contentBbox.z;
    let zoom = Math.min(
        (state.viewBox.width - PADDING * 2) / width,
        (state.viewBox.height - PADDING * 2) / height
    );
    if (zoom < 0.1) zoom = 0.1;
    if (zoom > 3) zoom = 3;
    const offset = {
        x:
            x * zoom -
            (state.viewBox.width - width * zoom) / 2 -
            (["right", "back"].includes(state.view) ? width * zoom : 0),
        y: y * zoom - (state.viewBox.height - height * zoom) / 2,
    };

    return {
        ...state,
        zoom,
        viewBox: {
            ...state.viewBox,
            ...offset,
        },
        scale: 1,
    };
};

export const domCoordinatesToSvgCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY => ({
    x: (coordinates.x + state.viewBox.x) / state.zoom,
    y: (coordinates.y + state.viewBox.y) / state.zoom,
});

export const updateCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY,
    originalCoordinates: Partial<CoordinatesXYZ>
): CoordinatesXYZ => {
    const svgCoordinates = domCoordinatesToRealCoordinates(state, {
        x: coordinates.x - state.bbox.x,
        y: coordinates.y - state.bbox.y,
    });
    if (state.view === "front" || state.view === "back") {
        return {
            x: (state.view === "back" ? -1 : 1) * svgCoordinates.x,
            y: originalCoordinates?.y ?? 0,
            z: svgCoordinates.y,
        };
    } else if (state.view === "top" || state.view === "bottom") {
        return {
            x: svgCoordinates.x,
            y: svgCoordinates.y,
            z: originalCoordinates?.z ?? 0,
        };
    } else if (state.view === "left" || state.view === "right") {
        return {
            x: originalCoordinates?.x ?? 0,
            y: (state.view === "right" ? -1 : 1) * svgCoordinates.x,
            z: svgCoordinates.y,
        };
    }
    return { x: 0, y: 0, z: 0 };
};

export const svgCoordinatesToRealCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY => ({
    x: coordinates.x * state.scale,
    y: coordinates.y * state.scale,
});

export const domCoordinatesToRealCoordinates = (
    state: VisualizationReducerState,
    coordinates: CoordinatesXY
): CoordinatesXY =>
    svgCoordinatesToRealCoordinates(
        state,
        domCoordinatesToSvgCoordinates(state, coordinates)
    );

export const markerToCoordinates = (marker: CoordinatesXYZ, view: string) => {
    if (view === "front" || view === "back") {
        return {
            x: view === "back" ? -marker.x : marker.x,
            y: marker.z,
        };
    } else if (view === "left" || view === "right") {
        return {
            x: view === "right" ? -marker.y : marker.y,
            y: marker.z,
        };
    } else {
        return {
            x: marker.x,
            y: marker.y,
        };
    }
};

export const visualizationReducer = (
    state: VisualizationReducerState,
    action: VisualizationReducerAction
): VisualizationReducerState => {
    switch (action.type) {
        case "setBbox":
            if (!action.payload?.bbox) return state;

            if (!state.isInit) {
                return adaptView({
                    ...state,
                    bbox: {
                        x: action.payload?.bbox.left,
                        y: action.payload?.bbox.top,
                        width: action.payload?.bbox.width,
                        height: action.payload?.bbox.height,
                    },
                    viewBox: {
                        x: 0,
                        y: 0,
                        width: action.payload.bbox.width,
                        height: action.payload.bbox.height,
                    },
                    isInit: true,
                });
            }

            return {
                ...state,
                bbox: {
                    x: action.payload?.bbox.left,
                    y: action.payload?.bbox.top,
                    width: action.payload?.bbox.width,
                    height: action.payload?.bbox.height,
                },
                viewBox: {
                    ...state.viewBox,
                    width: action.payload.bbox.width,
                    height: action.payload.bbox.height,
                },
            };
        case "setContentBbox":
            if (!action.payload?.content?.length) return state;

            let bbox = {
                x: { min: 0, max: 10 },
                y: { min: 0, max: 10 },
                z: { min: 0, max: 10 },
            };
            const offsetZ =
                action.payload.content.find((e) => e.type === "tablier")
                    ?.dimensions.height ?? 0;

            for (const element of action.payload.content) {
                const elementBbox = {
                    x: {
                        min: element.coordinates.x - (element.arcLeft?.rX ?? 0),
                        max:
                            element.coordinates.x +
                            element.dimensions.length +
                            (element.arcRight?.rX ?? 0),
                    },
                    y: {
                        min: element.coordinates.y,
                        max: element.coordinates.y + element.dimensions.width,
                    },
                    z: {
                        min: element.coordinates.z,
                        max: element.coordinates.z + element.dimensions.height,
                    },
                };
                if (element.type === "pile") {
                    elementBbox.z.min = offsetZ;
                    elementBbox.z.max = element.dimensions.height;
                } else if (
                    element.type === "barriere" ||
                    element.type === "glissiere"
                ) {
                    elementBbox.z.min = -element.dimensions.height;
                    elementBbox.z.max = 0;
                }

                bbox = {
                    x: {
                        min: Math.min(elementBbox.x.min, bbox.x.min),
                        max: Math.max(elementBbox.x.max, bbox.x.max),
                    },
                    y: {
                        min: Math.min(elementBbox.y.min, bbox.y.min),
                        max: Math.max(elementBbox.y.max, bbox.y.max),
                    },
                    z: {
                        min: Math.min(elementBbox.z.min, bbox.z.min),
                        max: Math.max(elementBbox.z.max, bbox.z.max),
                    },
                };
            }

            return {
                ...state,
                contentBbox: {
                    x: bbox.x.min,
                    y: bbox.y.min,
                    z: bbox.z.min,
                    length: bbox.x.max - bbox.x.min,
                    width: bbox.y.max - bbox.y.min,
                    height: bbox.z.max - bbox.z.min,
                },
            };
        case "setView":
            if (!action.payload?.view) return state;

            return adaptView({
                ...state,
                view: action.payload?.view,
                zoom: 1,
            });
        case "addZoom":
            if (!action.payload?.zoom || !action.payload?.coordinates)
                return state;
            const zoom = state.zoom - action.payload?.zoom * 0.0008;

            if (zoom < 0.1 || zoom > 3) return state;
            const realCoordinates = domCoordinatesToSvgCoordinates(
                state,
                action.payload?.coordinates
            );
            const realCoordinatesAfter = domCoordinatesToSvgCoordinates(
                { ...state, zoom },
                action.payload?.coordinates
            );

            return {
                ...state,
                zoom,
                viewBox: {
                    ...state.viewBox,
                    x:
                        state.viewBox.x +
                        (realCoordinates.x - realCoordinatesAfter.x) *
                            state.zoom,
                    y:
                        state.viewBox.y +
                        (realCoordinates.y - realCoordinatesAfter.y) *
                            state.zoom,
                },
            };
        case "mouseDown":
            if (!action.payload?.coordinates) return state;
            return {
                ...state,
                mouseDown: action.payload.coordinates,
            };
        case "translate":
            if (!action.payload?.coordinates || !state.mouseDown) return state;

            return {
                ...state,
                translationInProgress: {
                    x: state.mouseDown.x - action.payload.coordinates.x,
                    y: state.mouseDown.y - action.payload.coordinates.y,
                },
            };
        case "mouseUp":
            return {
                ...state,
                mouseDown: undefined,
                viewBox: {
                    ...state.viewBox,
                    x: state.viewBox.x + (state.translationInProgress?.x ?? 0),
                    y: state.viewBox.y + (state.translationInProgress?.y ?? 0),
                },
                translationInProgress: undefined,
            };
        default:
            return state;
    }
};

export const scaleValue = (value: number, scale: number) =>
    Math.round(value / (scale !== 0 ? scale : 1));

export const cuboidForVisualization = (
    cuboid: OuvrageElement,
    view: string,
    scale: number
): VisualizationElement => {
    const cuboidScaled = {
        x:
            view === "left" || view === "right"
                ? scaleValue(
                      view === "left"
                          ? cuboid.coordinates.y
                          : -cuboid.coordinates.y - cuboid.dimensions.width,
                      scale
                  )
                : scaleValue(
                      view !== "back"
                          ? cuboid.coordinates.x
                          : -cuboid.coordinates.x - cuboid.dimensions.length,
                      scale
                  ),
        y:
            view === "top" || view === "bottom"
                ? scaleValue(
                      view === "top"
                          ? cuboid.coordinates.y
                          : -cuboid.coordinates.y - cuboid.dimensions.width,
                      scale
                  )
                : scaleValue(cuboid.coordinates.z, scale),
        width:
            view === "left" || view === "right"
                ? scaleValue(cuboid.dimensions.width, scale)
                : scaleValue(cuboid.dimensions.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(cuboid.dimensions.width, scale)
                : scaleValue(cuboid.dimensions.height, scale),
    };

    return {
        initial: cuboid,
        path: [
            `M ${cuboidScaled.x} ${cuboidScaled.y}`,
            `h ${cuboidScaled.width}`,
            `v ${cuboidScaled.height}`,
            `h -${cuboidScaled.width} Z`,
        ].join(" "),
        bbox: cuboidScaled,
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y,
                },
                point2: {
                    x: cuboidScaled.x + cuboidScaled.width,
                    y: cuboidScaled.y,
                },
                value:
                    view === "left" || view === "right"
                        ? cuboid.dimensions.width
                        : cuboid.dimensions.length,
                offset: 0,
            },
            {
                key: "height",
                side: "left",
                point1: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y,
                },
                point2: {
                    x: cuboidScaled.x,
                    y: cuboidScaled.y + cuboidScaled.height,
                },
                value:
                    view === "top" || view === "bottom"
                        ? cuboid.dimensions.width
                        : cuboid.dimensions.height,
                offset: 0,
            },
        ],
    };
};

export const pileForVisualization = (
    pile: OuvrageElement,
    offsetZ: number,
    view: string,
    scale: number
): VisualizationElement => {
    if (
        view === "top" ||
        view === "bottom" ||
        view === "left" ||
        view === "right"
    ) {
        const withArcs =
            (view === "top" || view === "bottom") &&
            (pile.arcLeft || pile.arcRight)
                ? cuboidForVisualization(
                      {
                          ...pile,
                          dimensions: {
                              ...pile.dimensions,
                              length:
                                  pile.dimensions.length +
                                  (pile.arcLeft?.rX ?? 0) +
                                  (pile.arcRight?.rX ?? 0),
                          },
                          coordinates: {
                              x: pile.coordinates.x - (pile.arcLeft?.rX ?? 0),
                              y: pile.coordinates.y,
                              z: offsetZ,
                          },
                      },
                      view,
                      scale
                  )
                : undefined;
        const cuboid = cuboidForVisualization(
            {
                ...pile,
                coordinates: {
                    ...pile.coordinates,
                    z: offsetZ,
                },
            },
            view,
            scale
        );
        const newBbox = withArcs?.bbox ?? cuboid.bbox;
        return {
            initial: pile,
            path: cuboid.path,
            bbox: newBbox,
            topPath: withArcs?.path,
            dimensions: [
                ...cuboid.dimensions,
                ...(((view === "top" || view === "bottom") && pile.arcLeft
                    ? [
                          {
                              key: "width-arcLeft",
                              side: "top",
                              point1: {
                                  x: newBbox.x,
                                  y: newBbox.y,
                              },
                              point2: {
                                  x:
                                      newBbox.x +
                                      scaleValue(pile.arcLeft.rX, scale),
                                  y: newBbox.y,
                              },
                              value: pile.arcLeft.rX,
                              offset: 0,
                          },
                      ]
                    : []) as (VisualizationDimension & { key: string })[]),
                ...(((view === "top" || view === "bottom") && pile.arcRight
                    ? [
                          {
                              key: "width-arcRight",
                              side: "top",
                              point1: {
                                  x:
                                      newBbox.x +
                                      newBbox.width -
                                      scaleValue(pile.arcRight.rX, scale),
                                  y: newBbox.y,
                              },
                              point2: {
                                  x: newBbox.x + newBbox.width,
                                  y: newBbox.y,
                              },
                              value: pile.arcRight.rX,
                              offset: 0,
                          },
                      ]
                    : []) as (VisualizationDimension & { key: string })[]),
            ],
        };
    }
    const coef = view === "back" ? -1 : 1;
    const pileScaled = {
        x:
            coef *
            scaleValue(pile.coordinates.x - (pile.arcLeft?.rX ?? 0), scale),
        y: scaleValue(offsetZ, scale),
        width: scaleValue(pile.dimensions.length, scale),
        height: scaleValue(pile.dimensions.height, scale),
        arcLeft:
            !!pile.arcLeft?.rX && !!pile.arcLeft?.rY
                ? {
                      rX: scaleValue(pile.arcLeft.rX, scale),
                      rY: scaleValue(pile.arcLeft.rY, scale),
                  }
                : { rX: 0, rY: 0 },
        arcRight:
            !!pile.arcRight?.rX && !!pile.arcRight?.rY
                ? {
                      rX: scaleValue(pile.arcRight.rX, scale),
                      rY: scaleValue(pile.arcRight.rY, scale),
                  }
                : { rX: 0, rY: 0 },
    };

    return {
        initial: pile,
        path: [
            `M ${pileScaled.x} ${pileScaled.y}`,
            `a ${pileScaled.arcLeft.rX} ${pileScaled.arcLeft.rY} 0 0 ${
                view === "back" ? 0 : 1
            } ${coef * pileScaled.arcLeft.rX} ${pileScaled.arcLeft.rY}`,
            `V ${pileScaled.y + pileScaled.height}`,
            `h ${coef * pileScaled.width}`,
            `v -${pileScaled.height - pileScaled.arcRight.rY}`,
            `a ${pileScaled.arcRight.rX} ${pileScaled.arcRight.rY} 0 0 ${
                view === "back" ? 0 : 1
            } ${coef * pileScaled.arcRight.rX} -${pileScaled.arcRight.rY} Z`,
        ].join(" "),
        bbox: {
            ...pileScaled,
            width:
                pileScaled.width +
                pileScaled.arcLeft.rX +
                pileScaled.arcRight.rX,
            height: pileScaled.height,
        },
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: pileScaled.x + coef * pileScaled.arcLeft.rX,
                    y: pileScaled.y,
                },
                point2: {
                    x:
                        pileScaled.x +
                        coef * (pileScaled.arcLeft.rX + pileScaled.width),
                    y: pileScaled.y,
                },
                value: pile.dimensions.length,
                offset: 0,
            },
            ...((!!pileScaled.arcLeft.rX
                ? [
                      {
                          key: "arcLeftX",
                          side: "top",
                          point1: {
                              x: pileScaled.x,
                              y: pileScaled.y,
                          },
                          point2: {
                              x: pileScaled.x + coef * pileScaled.arcLeft.rX,
                              y: pileScaled.y,
                          },
                          value: pile.arcLeft?.rX ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!pileScaled.arcRight.rX
                ? [
                      {
                          key: "arcRightX",
                          side: "top",
                          point1: {
                              x:
                                  pileScaled.x +
                                  coef *
                                      (pileScaled.width +
                                          pileScaled.arcLeft.rX),
                              y: pileScaled.y,
                          },
                          point2: {
                              x:
                                  pileScaled.x +
                                  coef *
                                      (pileScaled.width +
                                          pileScaled.arcLeft.rX +
                                          pileScaled.arcRight.rX),
                              y: pileScaled.y,
                          },
                          value: pile.arcRight?.rX ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!pileScaled.arcLeft.rY
                ? [
                      {
                          key: "arcLeftY",
                          side: "left",
                          point1: {
                              x: pileScaled.x,
                              y: pileScaled.y,
                          },
                          point2: {
                              x: pileScaled.x + coef * pileScaled.arcLeft.rX,
                              y: pileScaled.y + pileScaled.arcLeft.rY,
                          },
                          value: pile.arcLeft?.rY ?? 0,
                          offset: 1,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            ...((!!pileScaled.arcRight.rY
                ? [
                      {
                          key: "arcRightY",
                          side: "right",
                          point1: {
                              x:
                                  pileScaled.x +
                                  coef *
                                      (pileScaled.width +
                                          pileScaled.arcLeft.rX),
                              y: pileScaled.y,
                          },
                          point2: {
                              x:
                                  pileScaled.x +
                                  coef *
                                      (pileScaled.width +
                                          pileScaled.arcLeft.rX),
                              y: pileScaled.y + pileScaled.arcRight.rY,
                          },
                          value: pile.arcLeft?.rY ?? 0,
                          offset: 0,
                      },
                  ]
                : []) as (VisualizationDimension & { key: string })[]),
            {
                key: "height",
                side: "left",
                point1: {
                    x: pileScaled.x,
                    y: pileScaled.y,
                },
                point2: {
                    x: pileScaled.x + coef * pileScaled.arcLeft.rX,
                    y: pileScaled.y + pileScaled.height,
                },
                value: pile.dimensions.height,
                offset: 0,
            },
        ],
    };
};

export const barriereForVisualization = (
    barriere: OuvrageElement,
    view: string,
    scale: number
): VisualizationElement => {
    const width = barriere.type !== "glissiere" ? 10 : 20;
    const cuboid = cuboidForVisualization(
        {
            ...barriere,
            dimensions: {
                ...barriere.dimensions,
                width,
            },
            coordinates: {
                ...barriere.coordinates,
                z: -barriere.dimensions.height,
            },
        },
        view,
        scale
    );
    const barriereScaled = cuboid.bbox;
    if (view === "front" || view === "back") {
        const tick = scaleValue(
            barriere.type !== "glissiere" ? 40 : 150,
            scale
        );
        const ticks = Math.floor(barriereScaled.width / tick) - 1;
        const offset = Math.round((barriereScaled.width - ticks * tick) / 2);
        const handle = scaleValue(
            Math.min(
                barriere.type !== "glissiere" ? 10 : 50,
                barriere.dimensions.height
            ),
            scale
        );
        const tickHeight = barriereScaled.height - handle;

        return {
            ...cuboid,
            initial: barriere,
            path: [
                `M ${barriereScaled.x} ${barriereScaled.y} v ${handle} h ${
                    offset - 2
                }`,
                ...(ticks > 0
                    ? Array.from(Array(ticks).keys()).map(
                          () =>
                              `v ${tickHeight} h 4 v ${-tickHeight} h ${
                                  tick - 4
                              }`
                      )
                    : []),
                `v ${tickHeight} h 4 v ${-tickHeight} h ${
                    offset - 2
                } v ${-handle} Z`,
            ].join(" "),
        };
    } else if (view === "bottom" || view === "top") {
        return {
            ...cuboid,
            dimensions: cuboid.dimensions.filter((d) => d.key !== "height"),
            initial: barriere,
        };
    }

    const handle = scaleValue(
        Math.min(
            barriere.type !== "glissiere" ? 10 : 50,
            barriereScaled.height
        ),
        scale
    );
    return {
        ...cuboid,
        dimensions: cuboid.dimensions.filter((d) => d.key !== "width"),
        initial: barriere,
        path:
            barriere.type !== "glissiere"
                ? `M ${barriereScaled.x} ${barriereScaled.y} c 5 0 6 6 2 8 v ${
                      barriereScaled.height - 8
                  } h -4 v ${-barriereScaled.height + 8}  c -4 -2 -3 -8 2 -8 Z`
                : `M ${barriereScaled.x} ${
                      barriereScaled.y
                  } h -10 v ${handle} h 3 V ${
                      barriereScaled.y + barriereScaled.height
                  } h 4 v ${handle - barriereScaled.height} h 3 V ${
                      barriereScaled.y
                  } Z`,
    };
};

export const buseForVisualization = (
    buse: OuvrageElement,
    view: string,
    scale: number
): VisualizationElement => {
    const thicknessH = buse.thicknessH ?? 10;
    const thicknessV = buse.thicknessV ?? 10;
    const fullWidth = buse.dimensions.width + thicknessH * 2;
    const fullHeight = buse.dimensions.height + thicknessV * 2;

    const buseScaled = {
        x: 0,
        y: 0,
        width:
            view === "left" || view === "right"
                ? scaleValue(fullWidth, scale)
                : scaleValue(buse.dimensions.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(fullWidth, scale)
                : scaleValue(fullHeight, scale),
    };

    const buseInnerScaled = {
        x:
            view === "left" || view === "right"
                ? scaleValue(thicknessH, scale)
                : 0,
        y:
            view === "top" || view === "bottom"
                ? scaleValue(thicknessH, scale)
                : scaleValue(thicknessV, scale),
        width:
            view === "left" || view === "right"
                ? scaleValue(buse.dimensions.width, scale)
                : scaleValue(buse.dimensions.length, scale),
        height:
            view === "top" || view === "bottom"
                ? scaleValue(buse.dimensions.width, scale)
                : scaleValue(buse.dimensions.height, scale),
    };

    const offsetStart = buse.biseauStart
        ? scaleValue(
              fullHeight / Math.tan(((90 - buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const offsetEnd = buse.biseauEnd
        ? scaleValue(
              fullHeight / Math.tan(((90 - buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    if (view === "top" || view === "bottom") {
        return {
            initial: buse,
            path: [
                `M ${buseScaled.x} ${buseScaled.y}`,
                `h ${buseScaled.width}`,
                `v ${buseScaled.height}`,
                `h -${buseScaled.width} Z`,
            ].join(" "),
            bbox: buseScaled,
            topPath:
                offsetEnd || offsetStart
                    ? [
                          `M ${buseScaled.x + offsetStart} ${buseScaled.y}`,
                          `h ${buseScaled.width - (offsetEnd + offsetStart)}`,
                          `v ${buseScaled.height}`,
                          `h -${
                              buseScaled.width - (offsetEnd + offsetStart)
                          } Z`,
                      ].join(" ")
                    : undefined,
            innerPath: [
                `M ${buseInnerScaled.x} ${buseInnerScaled.y}`,
                `h ${buseInnerScaled.width}`,
                `v ${buseInnerScaled.height}`,
                `h -${buseInnerScaled.width} Z`,
            ].join(" "),
            dimensions: [
                {
                    key: "width",
                    side: "top",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.x + buseScaled.width,
                        y: buseScaled.y,
                    },
                    value: buse.dimensions.length,
                    offset: 0,
                },
                {
                    key: "height",
                    side: "left",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.x,
                        y: buseScaled.y + buseScaled.height,
                    },
                    value: fullWidth,
                    offset: 1,
                },
                {
                    key: "inner-height",
                    side: "left",
                    point1: {
                        x: buseInnerScaled.x,
                        y: buseInnerScaled.y,
                    },
                    point2: {
                        x: buseInnerScaled.x,
                        y: buseInnerScaled.y + buseInnerScaled.height,
                    },
                    value: buse.dimensions.width,
                    offset: 0,
                },
            ],
        };
    }
    if (view === "left" || view === "right") {
        return {
            initial: buse,
            bbox: buseScaled,
            path: [buseScaled, buseInnerScaled]
                .map((bs) =>
                    [
                        `M ${bs.x + bs.width / 2} ${bs.y}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            bs.width / 2
                        } ${bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            -bs.width / 2
                        } ${bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            -bs.width / 2
                        } ${-bs.height / 2}`,
                        `a ${bs.width / 2} ${bs.height / 2} 0 0 1 ${
                            bs.width / 2
                        } ${-bs.height / 2}`,
                    ].join(" ")
                )
                .join(" Z "),
            dimensions: [
                {
                    key: "width",
                    side: "top",
                    point1: {
                        x: buseScaled.x,
                        y: buseScaled.height / 2,
                    },
                    point2: {
                        x: buseScaled.x + buseScaled.width,
                        y: buseScaled.height / 2,
                    },
                    value: fullWidth,
                    offset: 1,
                },
                {
                    key: "height",
                    side: "left",
                    point1: {
                        x: buseScaled.width / 2,
                        y: buseScaled.y,
                    },
                    point2: {
                        x: buseScaled.width / 2,
                        y: buseScaled.y + buseScaled.height,
                    },
                    value: fullHeight,
                    offset: 1,
                },
                {
                    key: "inner-width",
                    side: "top",
                    point1: {
                        x: buseInnerScaled.x,
                        y: buseScaled.height / 2,
                    },
                    point2: {
                        x: buseInnerScaled.x + buseInnerScaled.width,
                        y: buseScaled.height / 2,
                    },
                    value: buse.dimensions.width,
                    offset: 0,
                },
                {
                    key: "inner-height",
                    side: "left",
                    point1: {
                        x: buseScaled.width / 2,
                        y: buseInnerScaled.y,
                    },
                    point2: {
                        x: buseScaled.width / 2,
                        y: buseInnerScaled.y + buseInnerScaled.height,
                    },
                    value: buse.dimensions.height,
                    offset: 0,
                },
            ],
        };
    }

    const innerOffsetStart = buse.biseauStart
        ? scaleValue(
              buse.dimensions.height /
                  Math.tan(((90 - buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const innerOffsetEnd = buse.biseauEnd
        ? scaleValue(
              buse.dimensions.height /
                  Math.tan(((90 - buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    const innerOffsetStartX = buse.biseauStart
        ? scaleValue(
              thicknessH / Math.tan(((90 - buse.biseauStart) * Math.PI) / 180),
              scale
          )
        : 0;
    const innerOffsetEndX = buse.biseauEnd
        ? scaleValue(
              thicknessH / Math.tan(((90 - buse.biseauEnd) * Math.PI) / 180),
              scale
          )
        : 0;

    return {
        initial: buse,
        bbox: buseScaled,
        path: [
            `M ${buseScaled.x + (view === "front" ? offsetStart : offsetEnd)} ${
                buseScaled.y
            }`,
            `h ${buseScaled.width - (offsetEnd + offsetStart)}`,
            `l ${view === "front" ? offsetEnd : offsetStart} ${
                buseScaled.height
            }`,
            `h -${buseScaled.width} Z`,
        ].join(" "),
        innerPath: [
            `M ${
                buseInnerScaled.x +
                (view === "front"
                    ? innerOffsetStart + innerOffsetStartX
                    : innerOffsetEnd + innerOffsetEndX)
            } ${buseInnerScaled.y}`,
            `h ${
                buseInnerScaled.width -
                (innerOffsetEnd +
                    innerOffsetEndX +
                    innerOffsetStart +
                    innerOffsetStartX)
            }`,
            `l ${view === "front" ? innerOffsetEnd : innerOffsetStart} ${
                buseInnerScaled.height
            }`,
            `h -${
                buseInnerScaled.width - (innerOffsetEndX + innerOffsetStartX)
            } Z`,
        ].join(" "),
        dimensions: [
            {
                key: "width",
                side: "top",
                point1: {
                    x: buseScaled.x,
                    y: buseScaled.y,
                },
                point2: {
                    x: buseScaled.x + buseScaled.width,
                    y: buseScaled.y,
                },
                value: buse.dimensions.length,
                offset: 0,
            },
            {
                key: "height",
                side: "left",
                point1: {
                    x: buseScaled.x,
                    y: buseScaled.y,
                },
                point2: {
                    x: buseScaled.x,
                    y: buseScaled.y + buseScaled.height,
                },
                value: fullHeight,
                offset: 1,
            },
            {
                key: "inner-height",
                side: "left",
                point1: {
                    x: buseInnerScaled.x,
                    y: buseInnerScaled.y,
                },
                point2: {
                    x: buseInnerScaled.x,
                    y: buseInnerScaled.y + buseInnerScaled.height,
                },
                value: buse.dimensions.width,
                offset: 0,
            },
        ],
    };
};
