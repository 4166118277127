import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Header from '../../components/ui/Header';
import { Modal } from '../../components/ui/Modal';
import useForm, { EntityFormRules } from '../../hooks/useForm';
import { MATERIAL_FORM_PARAMETERS, Material, MaterialCharacteristic, MaterialType, MaterialTypeLabel } from '../../models/material';
import useWorkspace from '../../services/hooks/use-workspace';
import { getRequest, postRequest, putRequest } from '../../services/request.service';
import { ActionIcon } from '../../utils/icons';
import MaterialEditComponent from './EditComponent';

const MaterialEdit = () => {
    const { operation } = useWorkspace();
    const { operationId, page, materialType, materialId, standardId } = useParams<{ operationId?: string, page?: string, materialType: MaterialType, materialId?: string, standardId?: string }>();
    const navigate = useNavigate();

    // Check if we are handling a standard or a market requirement
    const [isCatalogue, setCatalogueMode] = useState<boolean>(true);

    // Entity to edit/create
    const { entity, attachInput, validate, setEntity, setChanged, onChange, hasChanged } = useForm<Material>({ active: true, year: new Date().getFullYear(), type: materialType, isStandard: true });

    // Parameters for the form
    const [formParameters, setFormParameters] = useState<
        {
            validationCatalogue: EntityFormRules;
            characteristics: MaterialCharacteristic[];
        } | null>(null);

    // Confirmation modal
    const [isConfirmationModalVisible, setConfirmationModalVisible] = useState(false);

    const getMaterial = useCallback(async (_id: string, duplicate: boolean) => {
        getRequest<Material>(`/material/${_id}`, { errorMessage: 'Une erreur est survenue lors de la récupération du matériau', loader: true })
            .then((data) => {
                setEntity(duplicate ? { ...data, _id: undefined } : data);
            })
            .catch(() => navigate(-1));
    }, []);

    const getRequirementFromStandard = useCallback(async (_id: string) => {
        getRequest<Material>(`/material/${_id}`, { errorMessage: 'Une erreur est survenue lors de la récupération du matériau', loader: true })
            .then((data) => {
                setEntity({
                    active: true,
                    type: data.type,
                    standard: data._id,
                    standardPopulated: data,
                    name: data.name,
                    englishName: data.englishName,
                    year: data.year,
                    characteristics: data.characteristics,
                    specifications: data.specifications,
                    operation: operationId,
                    isStandard: false,
                });
                setChanged(true);
            })
            .catch(() => navigate(-1));
    }, []);

    const createOrUpdate = useCallback(async (close?: boolean) => {
        const entity = validate(formParameters?.validationCatalogue);
        if (!entity) return;

        const create = !entity?._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Material>('/material', entity, {
            successMessage: create ? 'Matériau créé avec succès' : 'Matériau mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(data);
                }
            })
            .catch(() => null);
    }, [formParameters, validate, setEntity, operationId, materialType]);

    const handleSubmit = useCallback((close?: boolean) => {
        const entity = validate(formParameters?.validationCatalogue);
        if (!entity) return;

        if (!isCatalogue && !!entity?._id && hasChanged) {
            setConfirmationModalVisible(true);
        } else {
            createOrUpdate(close);
        }
    }, [formParameters, isCatalogue, validate, hasChanged, createOrUpdate]);

    useEffect(() => {
        if (materialType && MATERIAL_FORM_PARAMETERS[materialType]) {
            setFormParameters(MATERIAL_FORM_PARAMETERS[materialType]);
            // Check if it is a market requirement
            if (operationId) {
                setCatalogueMode(false);
                // If materialId specified
                if (materialId && materialId !== 'creer') {
                    // If materialId specified (check if edit mode or duplicate mode)
                    getMaterial(materialId, page === 'dupliquer');
                } else if (standardId) {
                    // Else fetch associated standard
                    getRequirementFromStandard(standardId);
                } else {
                    // Else weird
                    navigate(-1);
                }
            } else {
                setCatalogueMode(true);
                if (standardId) {
                    // If standardId specified
                    getMaterial(standardId, page === 'dupliquer');
                }
            }
        }
    }, []);

    if (!materialType) {
        return (null);
    }

    return (
        <Fragment>
            <Header
                breadcrumbs={isCatalogue ? [
                    { label: 'Catalogue', href: `/catalogue/${materialType}` },
                    { label: `${entity?._id ? entity.name : 'Créer : ' + MaterialTypeLabel[materialType]}` }
                ] : [
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: 'Exigences', href: `/operation/${operationId}/exigences/${materialType}` },
                    { label: `${entity?._id ? entity.name : 'Créer : ' + MaterialTypeLabel[materialType]}` }
                ]}
                withBorder
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button
                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <MaterialEditComponent entity={entity} attachInput={attachInput} onChange={onChange} />
            {isConfirmationModalVisible && (
                <Modal
                    title="Attention !" className="modal-confirmation"
                    size="small"
                    actions={[
                        { label: 'Annuler', color: 'secondary', onClick: () => setConfirmationModalVisible(false) },
                        { label: 'Valider', color: 'primary', onClick: () => createOrUpdate(true) },
                    ]}
                >
                    <span>
                        La modification de l'exigence va lancer le recalcul des conformités pour toutes les analyses qui lui sont liées.
                        <span className="error"> Cette opération sera effectuée en tâche de fond et est définitive.</span>
                    </span>
                    <span>Assurez-vous que les modifications apportées sont correctes avant de continuer</span>
                </Modal>
            )}
        </Fragment>
    )
}

export default MaterialEdit;
