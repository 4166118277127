import { OperationLocation } from "./location";
import { Phase } from "./operation";
import { RevisionEntity } from "./shared";
import { StorageFile } from "./storage";

export type Marker = RevisionEntity & {
    name: string;
    description: string;
    location: OperationLocation;
    operation: string;
    date: Date;
    phase: Phase;
    files: string[];
    filesPopulated?: StorageFile[];
    filesUrls?: string[];
    pathologies: Pathologies;
    problematic?: boolean;
};

export const isMarker = (o: any) =>
    o.hasOwnProperty("location") && !o.hasOwnProperty("type");

export type Pathology = {
    value: number;
    quantity: number;
    problematic: boolean;
};

export type Pathologies = {
    [field: string]: Pathology;
};

export const PATHOLOGIES = [
    {
        key: "general",
        label: "Générales",
        items: [
            { key: "degradation", label: "Dégradation", unit: "m²" },
            { key: "ornierage", label: "Orniérage", unit: "m" },
            { key: "flaches", label: "Flaches", unit: "mm" },
            {
                key: "nidDePoule",
                label: "Nid de poule",
                unit: "m²",
                withQuantity: true,
            },
            {
                key: "ouvertureJoints",
                label: "Ouverture des joints",
                unit: "m",
            },
            {
                key: "fissurationLongitudinale",
                label: "Fissuration longitudinale",
                unit: "m",
                withQuantity: true,
            },
            {
                key: "fissurationTransversale",
                label: "Fissuration transversale",
                unit: "m",
                withQuantity: true,
            },
        ],
    },
    {
        key: "deformations",
        label: "Déformations",
        items: [
            {
                key: "jointLongitudinal",
                label: "Joint longitudinal",
                unit: "m",
            },
            { key: "fluage", label: "Fluage", unit: "m" },
            { key: "faiencage", label: "Faïençage", unit: "m²" },
            { key: "fissureDalle", label: "Fissure en dalle", unit: "m²" },
            { key: "fissureRamifiee", label: "Fissure ramifiée", unit: "m²" },
            { key: "fissureOblique", label: "Fissure oblique", unit: "m" },
            { key: "affaissement", label: "Affaissements", unit: "m" },
            {
                key: "bourreletTransversal",
                label: "Bourrelet transversal",
                unit: "m",
            },
            { key: "gonfle", label: "Gonfle", unit: "cm" },
        ],
    },
    {
        key: "arrachements",
        label: "Arrachements",
        items: [
            { key: "glacage", label: "Glaçage", unit: "m²" },
            { key: "plumage", label: "Plumage", unit: "m²" },
            { key: "peignage", label: "Peignage", unit: "m²" },
            { key: "desenrobage", label: "Désenrobage", unit: "m²" },
            { key: "pelades", label: "Pelades", unit: "m²" },
            { key: "ecaillage", label: "Ecaillage", unit: "m²" },
            { key: "epauffrure", label: "Epauffrure", unit: "m²" },
            { key: "fissureJoint", label: "Fissure joint", unit: "m" },
        ],
    },
    {
        key: "materials",
        label: "Matériaux",
        items: [
            { key: "ressuage", label: "Ressuage", unit: "m²" },
            { key: "indentations", label: "Indentations", unit: "m²" },
            { key: "remonteeFines", label: "Remontée de fines", unit: "m²" },
            { key: "rejets", label: "Rejets", unit: "m²" },
        ],
    },
];

export const MarkerIcon = "mdi:crosshairs";
