import { Icon } from '@iconify/react';
import { Fragment, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SampleUnknownAnalysis from '../../../components/Analysis/SampleUnknowAnalysis';
import EntityCard from '../../../components/entities/EntityCard';
import RevisionCard from '../../../components/entities/RevisionCard';
import Checkbox from '../../../components/inputs/Checkbox';
import DatePickerInput from '../../../components/inputs/DatePicker';
import Select from '../../../components/inputs/Select';
import Switch from '../../../components/inputs/Switch';
import TextInput from '../../../components/inputs/TextInput';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import Header from '../../../components/ui/Header';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import useForm, { FormComparator } from '../../../hooks/useForm';
import { Melange, MelangeTypes } from '../../../models/melange';
import { Phase, PhaseLabel, RoadPositionLabel } from '../../../models/operation';
import { PaginatedResults } from '../../../models/pagination';
import { Controle, Controles, Sample, SampleLabels } from '../../../models/sample';
import { RevisionStatus } from '../../../models/shared';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest, postRequest, putRequest } from '../../../services/request.service';
import { LayerColors } from '../../../synoptique/Synoptique.class';
import { formatNumberToFixedDecimal } from '../../../utils/format';
import { ActionIcon } from '../../../utils/icons';
import { toggleInArray } from '../../../utils/objects';
import { floatToPrString } from '../../../utils/pr';
import './index.scss';

const VALIDATION = {
    name: [{ comparator: FormComparator.REQUIRED }],
    date: [{ comparator: FormComparator.REQUIRED }],
    type: [{ comparator: FormComparator.REQUIRED }],
    samplesSelectedLayers: [{ comparator: FormComparator.REQUIRED }, { comparator: FormComparator.NOT_EMPTY }]
};

const MelangeEdit = () => {
    const { operation, workspacePermissions } = useWorkspace();
    const { page, melangeId, phase } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { entity, attachInput, validate, setEntity, onChange, errors, hasChanged, setChanged } = useForm<Melange>({});

    const handleSelectLayer = useCallback((_id: string, order: number) => {
        onChange(
            'samplesSelectedLayers',
            toggleInArray({ _id, order }, entity.samplesSelectedLayers ?? [], (s1, s2) => s1._id === s2._id && s1.order === s2.order)
        );
    }, [onChange, entity]);

    const mapMelange = useCallback((melange: Melange, duplicate?: boolean) => {
        const samplesSelectedLayers: { _id: string, order: number }[] = [];
        if (!duplicate) {
            for (const sample of melange.samplesPopulated ?? []) {
                for (const layer of sample.layers) {
                    if (layer.melange === melange._id) {
                        samplesSelectedLayers.push({ _id: sample._id, order: layer.order });
                    }
                }
            }
        }
        return { ...melange, samplesSelectedLayers };
    }, []);

    const handleSubmit = useCallback(async (close?: boolean) => {
        const entity = validate(VALIDATION);
        if (!entity) return;

        const dto = {
            ...entity,
            way: entity?.samplesPopulated?.[0]?.location?.way,
            analysis: entity.analysisPopulated ? { ...entity.analysisPopulated, controle: entity.controle, date: entity.date, operation: entity.operation } : undefined
        };
        const create = !entity._id;
        const requestMethod = create ? postRequest : putRequest;

        requestMethod<Melange>('/melange', dto, {
            successMessage: create ? 'Mélange créé avec succès' : 'Mélange mis à jour avec succès',
            errorMessage: 'Une erreur est survenue lors de l\'enregistrement',
            loader: true
        })
            .then((data) => {
                if (close) {
                    navigate(-1)
                } else {
                    setChanged(false);
                    setEntity(mapMelange(data));
                }
            })
            .catch(() => null);
    }, [validate, setEntity]);

    useEffect(() => {
        if (melangeId) {
            getRequest<Melange>(`/melange/${melangeId}${page === 'dupliquer' ? '/duplicate' : ''}`, { errorMessage: 'Une erreur est survenue lors de la récupération du mélange.', loader: true })
                .then((melange) => {
                    setEntity(mapMelange(melange, page === 'dupliquer'));

                })
                .catch(() => navigate(-1));
        } else {
            const queryParams = new URLSearchParams(location.search);
            const ids = queryParams.get('ids')?.split(',');

            if (!ids?.length) navigate(-1);

            getRequest<PaginatedResults<Sample>>(`/sample/operation/${operation._id}/${phase}/carotte`, { params: { ids, perPage: 100 }, errorMessage: 'Une erreur est survenue lors de la récupération des échantillons.', loader: true })
                .then((data) => setEntity({
                    samplesPopulated: data.data,
                    active: true,
                    operation: operation._id,
                    problematic: false,
                    phase: phase as Phase,
                    status: workspacePermissions.validate ? RevisionStatus.VALIDATED : RevisionStatus.DRAFT,
                    controle: workspacePermissions.controles?.[0] ?? Controle.EXTERIEUR
                }))
                .catch(() => navigate(-1));
        }
    }, []);

    return (
        <Fragment>
            <Header
                breadcrumbs={[
                    { label: operation?.name, href: `/operation/${operation?._id}` },
                    { label: PhaseLabel[phase as Phase], href: `/operation/${operation?._id}/${phase}/carotte` },
                    { label: entity?._id ? SampleLabels.melange.edit : SampleLabels.melange.create }
                ]}
            >
                <Button
                    label="Fermer"
                    color="secondary"
                    onClick={() => navigate(-1)}
                />
                {hasChanged && (
                    <Fragment>
                        <Button
                            label="Enregistrer"
                            icon={ActionIcon.SAVE}
                            onClick={handleSubmit}
                        />
                        <Button

                            label="Enregistrer et fermer"
                            icon={ActionIcon.SAVE_CLOSE}
                            onClick={() => handleSubmit(true)}
                        />
                    </Fragment>
                )}
            </Header>
            <ScrollableContent
                side={
                    <EntityCard entity={entity} attachInput={attachInput}>
                        <RevisionCard<Melange> entity={entity} attachInput={attachInput} />
                    </EntityCard>
                }
            >
                <Card title="Informations générales">
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="name">Nom *</label>
                            <TextInput {...attachInput('name')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="type">Type *</label>
                            <Select items={MelangeTypes} {...attachInput('type')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-column">
                            <label htmlFor="date">Date *</label>
                            <DatePickerInput max={new Date()} withIcon {...attachInput('date')} />
                        </div>
                        <div className="input-column">
                            <label htmlFor="controle">Contrôle *</label>
                            <Switch
                                className="form-switch"
                                items={Controles.filter(c => workspacePermissions.controles?.includes(c.key))}
                                {...attachInput('controle')}
                            />
                        </div>
                    </div>
                </Card>
                <Card title="Composition" className="melange-composition">
                    <span className="info">Cliquer sur les couches utilisées lors de l'melange pour les sélectionner.</span>
                    <span className="warn">Les couches déjà ajoutées ou utilisées par un autre mélange ne sont pas sélectionnable.</span>
                    {!!errors?.samplesSelectedLayers?.length && <span className="error">{errors.samplesSelectedLayers.join(',')}</span>}
                    <div id="melange-composition-container">
                        <table className="simple-table">
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    {entity.samplesPopulated?.map(s => (<th key={s._id}>{s.name}</th>))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PR</td>
                                    {entity.samplesPopulated?.map(s => (<td key={s._id}>{floatToPrString(s.location.pr)}</td>))}
                                </tr>
                                <tr>
                                    <td>Sens</td>
                                    {entity.samplesPopulated?.map(s => (<td key={s._id}>{s.location.way ? `Sens ${s.location.way}` : ''}</td>))}
                                </tr>
                                <tr>
                                    <td>Voie</td>
                                    {entity.samplesPopulated?.map(s => (<td key={s._id}>{s.location.road ? operation.roadsObj[s.location.road]?.name : ''}</td>))}
                                </tr>
                                <tr>
                                    <td>Position</td>
                                    {entity.samplesPopulated?.map(s => (<td key={s._id}>{s.location.roadPosition ? RoadPositionLabel[s.location.roadPosition] : ''}</td>))}
                                </tr>
                                <tr>
                                    <td>Épaisseur</td>
                                    {entity.samplesPopulated?.map(s => (<td key={s._id}>{formatNumberToFixedDecimal(s.thickness)} cm</td>))}
                                </tr>
                                <tr>
                                    <td>Couches</td>
                                    {entity.samplesPopulated?.map(s => (
                                        <td key={s._id} className="melange-composition-layers">
                                            {s.layers?.map(layer => (
                                                <div
                                                    key={layer.order}
                                                    style={{ borderLeft: `${LayerColors[layer.order]} 8px solid` }}
                                                    onClick={() => !layer.melange || layer.melange === entity?._id ? handleSelectLayer(s._id, layer.order) : undefined}
                                                    className={`${entity.samplesSelectedLayers?.some(ss => ss._id === s._id && ss.order === layer.order) ? 'selected' : ''}${layer.melange && layer.melange !== entity?._id ? ' disabled' : ''}`}
                                                >
                                                    <div>{layer.problematic && <Icon icon="fluent:warning-16-regular" className="icon-small color-error" />}{layer.supposedMaterial ?? '(Couche sans nom)'}</div>
                                                    <div>{`${formatNumberToFixedDecimal(layer.thickness)} cm`}</div>
                                                </div>
                                            ))}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
                <Card title="Analyse">
                    <SampleUnknownAnalysis
                        analysis={entity.analysisPopulated ?? {}}
                        onChange={(analysis) => onChange('analysisPopulated', analysis)}
                    />
                    <div className="row">
                        <Checkbox label="Définir comme problématique" className="form-checkbox"  {...attachInput('problematic')} />
                    </div>
                </Card>
            </ScrollableContent>
        </Fragment >
    )
}

export default MelangeEdit;