import { ReactNode } from "react";
import Button, { ButtonProps } from "../Button";
import './index.scss';

export interface PanelRightProps {
    children: ReactNode;
    title?: string;
    className?: string;
    id?: string;
    actions?: ButtonProps[];
}

const PanelRight = ({ title, className, id, actions, children }: PanelRightProps) => {

    return (
        <div id={id} className={`panel-right ${className ?? ''}`}>
            {title && <div className="panel-right-title">{title}</div>}
            <div className="panel-right-content">
                {children}
            </div>
            <div className="panel-right-footer">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
    )
}
export default PanelRight;