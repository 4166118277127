import { Icon } from '@iconify/react';
import { ReactNode } from 'react';
import './index.scss';

export interface ToolbarProps {
    children: ReactNode;
}

const Toolbar = ({ children }: ToolbarProps) => <div className="toolbar">{children}</div>;

export interface ToolbarBlockProps {
    label: string;
    children: ReactNode;
}

Toolbar.ToolbarBlock = ({ label, children }: ToolbarBlockProps) => (
    <div className="toolbar-block">
        <span>{label}</span>
        <div>{children}</div>
    </div>
);

export interface ToolbarButtonProps {
    label: string;
    icon: string;
    active?: boolean;
    onClick: () => void;
}

Toolbar.ToolbarButton = ({ label, icon, active, onClick }: ToolbarButtonProps) => (
    <div className={`toolbar-button ${active ? 'active' : ''}`} onClick={onClick}>
        <Icon icon={icon} />
        <span>{label}</span>
    </div>
)

export default Toolbar;