import { useEffect, useState } from 'react';
import ScrollableContent from '../../../components/ui/ScrollableContent';
import Toolbar from '../../../components/ui/Toolbar';
import { MarkerIcon } from '../../../models/marker';
import { OuvrageElement } from '../../../models/ouvrage';
import { OuvrageMarker } from '../../../models/ouvrage-marker';
import useWorkspace from '../../../services/hooks/use-workspace';
import { getRequest } from '../../../services/request.service';
import OuvrageMarkerForm from '../OuvrageMarkerForm';
import Visualization from '../Visualization';
import VisualizationMarker from '../Visualization/components/VisualizationMarker';
import VisualizationViewButtons from '../Visualization/components/VisualizationViewButtons';
import OuvrageViewElement from './components/OuvrageViewElement';
import OuvrageViewPanel from './components/OuvrageViewPanel';
import './index.scss';

const OuvrageView = () => {
    const { ouvrage } = useWorkspace();
    const [selectedElement, setSelectedElement] = useState<OuvrageElement>();
    const [markerToEdit, setMarkerToEdit] = useState<Partial<OuvrageMarker>>();
    const [markers, setMarkers] = useState<OuvrageMarker[]>([]);
    const [view, setView] = useState<"front" | "top" | "left" | "right" | "back" | "bottom">('front');

    const getMarkers = () => getRequest<OuvrageMarker[]>('/ouvrage-marker', { params: { ouvrage: ouvrage._id }, loader: true })
        .then(setMarkers)
        .catch(() => null);

    useEffect(() => {
        getMarkers();
    }, [ouvrage]);

    return (
        <ScrollableContent noPadding noScroll id="ouvrage-view-page">
            <Toolbar>
                <Toolbar.ToolbarBlock label="Point d'intérêt">
                    <Toolbar.ToolbarButton
                        label="Nouveau point"
                        icon={MarkerIcon}
                        onClick={() => setMarkerToEdit({ ouvrage: ouvrage._id, element: selectedElement?._id })}
                    />
                </Toolbar.ToolbarBlock>
                <Toolbar.ToolbarBlock label="Vue">
                    <VisualizationViewButtons view={view} setView={setView} />
                </Toolbar.ToolbarBlock>
            </Toolbar>
            <div id="ouvrage-view">
                <Visualization
                    ouvrage={ouvrage}
                    view={view}
                    onMarker={markerToEdit && !markerToEdit?.coordinates ? (coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates }) : undefined}
                    onSelect={setSelectedElement}
                    selected={selectedElement}
                >
                    {markers.map(marker => (
                        <VisualizationMarker
                            key={marker._id}
                            selected={markerToEdit?._id === marker._id}
                            marker={marker}
                            onClick={!markerToEdit ? () => setMarkerToEdit(marker) : undefined}
                            ghost={!!selectedElement && marker.element !== selectedElement._id}
                            hide={marker._id === markerToEdit?._id}
                        />
                    ))}
                    {!!markerToEdit?.coordinates && <VisualizationMarker marker={markerToEdit} onMove={(coordinates) => setMarkerToEdit({ ...markerToEdit, coordinates })} />}
                </Visualization>
                {!selectedElement && !markerToEdit && (
                    <OuvrageViewPanel onClose={() => setSelectedElement(undefined)} onSelectElement={setSelectedElement} />
                )}
                {!!selectedElement && !markerToEdit && (
                    <OuvrageViewElement
                        element={selectedElement}
                        markers={markers.filter(m => m.element === selectedElement._id)}
                        onSelectMarker={setMarkerToEdit}
                        onClose={() => setSelectedElement(undefined)}
                    />
                )}
                {!!markerToEdit?.coordinates && <OuvrageMarkerForm marker={markerToEdit} onDelete={getMarkers} onClose={() => setMarkerToEdit(undefined)} onSubmit={getMarkers} />}
            </div>
        </ScrollableContent>
    )
}
export default OuvrageView;