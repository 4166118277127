import { useState } from 'react';
import { v4 } from 'uuid';
import MenuBar from '../../../../components/ui/MenuBar';
import ScrollableContent from '../../../../components/ui/ScrollableContent';
import { FormHookReturn } from '../../../../hooks/useForm';
import { Barriere, Ouvrage, OuvrageElement, OuvrageElementCategories, OuvrageElementIcon, OuvrageType, Pile } from '../../../../models/ouvrage';
import { replaceInArray } from '../../../../utils/objects';
import Visualization from '../../Visualization';
import ElementMarkers, { ElementMarker } from '../../Visualization/components/ElementMarkers';
import { VisualizationSelection } from '../../Visualization/types';
import BarriereForm from './BarriereForm';
import BuseForm from './BuseForm';
import MurForm from './MurForm';
import OuvrageElementForm from './OuvrageElementForm';
import PileForm from './PileForm';
import TablierForm from './TablierForm';

export interface OuvrageBlueprintEditProps extends Pick<FormHookReturn<Ouvrage>, 'entity' | 'onChange' | 'onChangeMultiple' | 'attachInput' | 'errors'> { }

const OuvrageBlueprintEdit = ({ entity, onChange }: OuvrageBlueprintEditProps) => {
    const [blueprintSelection, setBlueprintSelection] = useState<VisualizationSelection>();
    const [elementToEdit, setElementToEdit] = useState<Partial<OuvrageElement>>();

    return (
        <ScrollableContent noPadding noScroll>
            <div id="ouvrage-blueprint-edit">
                <div id="ouvrage-blueprint-edit-menu" className="no-print">
                    <MenuBar card>
                        {entity.type === OuvrageType.PONT && (
                            <MenuBar.Item label="Dessiner" icon="mdi:draw">
                                <MenuBar.SubItem label="Pile" icon={OuvrageElementIcon.PILE} onClick={() => setBlueprintSelection({ pile: { _id: v4() } })} />
                                <MenuBar.SubItem label="Barrière" icon={OuvrageElementIcon.BARRIERE} onClick={() => setBlueprintSelection({ barriere: { _id: v4() } })} />
                                <MenuBar.SubItem label="Glissière" icon={OuvrageElementIcon.BARRIERE} onClick={() => setBlueprintSelection({ barriere: { _id: v4(), type: 'glissiere' } })} />
                            </MenuBar.Item>
                        )}
                        <MenuBar.Item label="Ajouter un élément">
                            {OuvrageElementCategories.map(category => (
                                <MenuBar.SubItem
                                    key={category.key}
                                    label={category.label}
                                    icon={category.icon}
                                    onClick={() => setElementToEdit({ _id: v4(), category: category.key })}
                                />
                            ))}
                        </MenuBar.Item>
                    </MenuBar>
                </div>
                <Visualization
                    ouvrage={entity}
                    editable={!elementToEdit}
                    blueprintSelection={blueprintSelection}
                    onBlueprintSelection={setBlueprintSelection}
                    onMarker={elementToEdit && !elementToEdit.coordinates ? (coordinates) => setElementToEdit({ ...elementToEdit, coordinates }) : undefined}
                >
                    <ElementMarkers
                        ouvrage={entity}
                        selected={elementToEdit?._id}
                        onSelect={setElementToEdit}
                        hideSelected
                    />
                    {!!elementToEdit?.coordinates && (
                        <ElementMarker
                            element={elementToEdit}
                            onMove={elementToEdit ? (coordinates) => setElementToEdit({ ...elementToEdit, coordinates }) : undefined}
                        />
                    )}
                </Visualization>
                {!!blueprintSelection?.tablier && !!entity.blueprint?.tablier && (
                    <TablierForm
                        tablier={entity.blueprint?.tablier}
                        onClose={() => setBlueprintSelection(undefined)}
                        onChange={(tablier) => onChange('blueprint.tablier', tablier)}
                    />
                )}
                {!!blueprintSelection?.pile && (
                    <PileForm
                        pile={blueprintSelection?.pile}
                        onClose={() => setBlueprintSelection(undefined)}
                        onChange={(pile) => onChange('blueprint.piles', replaceInArray(pile as Pile, entity.blueprint?.piles, (p1, p2) => p1._id === p2._id, true))}
                    />
                )}
                {!!blueprintSelection?.barriere && (
                    <BarriereForm
                        barriere={blueprintSelection?.barriere}
                        onClose={() => setBlueprintSelection(undefined)}
                        onChange={(barriere) => onChange('blueprint.barrieres', replaceInArray(barriere as Barriere, entity.blueprint?.barrieres, (p1, p2) => p1._id === p2._id, true))}
                    />
                )}
                {!!blueprintSelection?.mur && !!entity.blueprint?.mur && (
                    <MurForm
                        mur={entity.blueprint.mur}
                        onClose={() => setBlueprintSelection(undefined)}
                        onChange={(mur) => onChange('blueprint.mur', mur)}
                    />
                )}
                {!!blueprintSelection?.buse && !!entity.blueprint?.buse && (
                    <BuseForm
                        buse={entity.blueprint.buse}
                        onClose={() => setBlueprintSelection(undefined)}
                        onChange={(buse) => onChange('blueprint.buse', buse)}
                    />
                )}
                {!!elementToEdit?.coordinates && (
                    <OuvrageElementForm
                        element={elementToEdit}
                        onClose={() => setElementToEdit(undefined)}
                        onSubmit={(element) => onChange('elements', replaceInArray(element, entity.elements, (e1, e2) => e1._id === e2._id, true))}
                    />
                )}
            </div>
        </ScrollableContent>
    )
}
export default OuvrageBlueprintEdit;