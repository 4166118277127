import { MinMaxAverage } from "./analysis";
import { OperationLocation } from "./location";
import { Lot } from "./lot";
import { RevisionEntity } from "./shared";

export type Population = RevisionEntity & {
    type: PopulationType;
    lot: string;
    lotPopulated?: Lot;
    fullLot: string;
    material: string;
    depth?: number;
    statistics?: MinMaxAverage;
    controle: PopulationControles;
    problematicDataCount: number;
    operation: string;
};

export const isPopulation = (o: any) =>
    o.hasOwnProperty("problematicDataCount");

export type PopulationSynoptique = {
    _id: string;
    type: PopulationType;
    fullLot: string;
    lot: string;
    material: string;
    location: OperationLocation;
    problematic: boolean;
    value: number;
};

export type PopulationData = {
    location: OperationLocation;
    value: number;
    problematic: boolean;
};

export type PopulationControle = {
    laboratory?: string;
    date?: Date;
    statistics?: MinMaxAverage;
    compliance95?: boolean;
    compliance100?: boolean;
    problematicDataCount: number;
    data?: PopulationData[];
};

export type PopulationControles = {
    externe?: PopulationControle;
    exterieur?: PopulationControle;
    autre?: PopulationControle;
};

export enum PopulationType {
    PMT = "pmt",
    PERMEABILITE = "permeabilite",
    VIDE = "vide",
}

export const PopulationTypeLabel = {
    [PopulationType.VIDE]: "Pourcentage de vide",
    [PopulationType.PERMEABILITE]: "Perméabilité",
    [PopulationType.PMT]: "PMT",
};

export const PopulationColor = {
    [PopulationType.VIDE]: "#d5e5a1",
    [PopulationType.PERMEABILITE]: "#d3eaf0",
    [PopulationType.PMT]: "#f2c8a2",
};

export const PopulationDataHeader = {
    [PopulationType.VIDE]: "% vide",
    [PopulationType.PERMEABILITE]: "VP (cm/s)",
    [PopulationType.PMT]: "PMT",
};

export const PopulationTypes = [
    {
        key: PopulationType.VIDE,
        label: PopulationTypeLabel[PopulationType.VIDE],
        color: "#2c9b3d",
    },
    {
        key: PopulationType.PERMEABILITE,
        label: PopulationTypeLabel[PopulationType.PERMEABILITE],
        color: "#1f6b2a",
    },
    {
        key: PopulationType.PMT,
        label: PopulationTypeLabel[PopulationType.PMT],
        color: "#195c23",
    },
];

export const PopulationIcon = "mdi:close";
