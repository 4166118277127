import { License } from "./license";
import { CoordinatesXYZ, Location } from "./location";
import { UserWorkspaceRole } from "./user";

export interface Ouvrage {
    _id: string;
    name: string;
    type: OuvrageType;
    location: Location;
    license?: string;
    licensePopulated?: License;
    businesses: string[];
    users: UserWorkspaceRole[];
    elements: OuvrageElement[];
    diagnostic?: {
        last?: Date;
        inProgress?: boolean;
        grade?: string;
        elements: { element: string; grade: string }[];
    };
    active: boolean;
    favoritedBy?: string[];
}

export interface OuvrageElement {
    _id: string;
    category: OuvrageElementCategory;
    type?: string;
    name?: string;
    description?: string;
    location?: string;
    coordinates: CoordinatesXYZ;
    dimensions: Cuboid;
    arcRight?: Arc;
    arcLeft?: Arc;
    biseauStart?: number;
    biseauEnd?: number;
    thicknessV?: number;
    thicknessH?: number;
}

export interface Cuboid {
    width: number;
    length: number;
    height: number;
}

export interface Arc {
    rX: number;
    rY: number;
}

export const OuvrageGrades = [
    { key: "1", label: "1", color: "#31b225" },
    { key: "1S", label: "1S", color: "#31b225" },
    { key: "2", label: "2", color: "#ddbb5b" },
    { key: "2S", label: "2S", color: "#ddbb5b" },
    { key: "2E", label: "2E", color: "#ddbb5b" },
    { key: "2ES", label: "2ES", color: "#ddbb5b" },
    { key: "3", label: "3", color: "#c72626" },
    { key: "3S", label: "3S", color: "#c72626" },
    { key: "3U", label: "3U", color: "#c72626" },
    { key: "3US", label: "3US", color: "#c72626" },
];

export enum OuvrageType {
    PONT = "pont",
    BUSE = "buse",
    MUR = "mur",
}

export const OuvrageTypeLabel = {
    [OuvrageType.PONT]: "Pont",
    [OuvrageType.BUSE]: "Buse",
    [OuvrageType.MUR]: "Mur",
};

export const OuvrageIcon = {
    [OuvrageType.PONT]: "mdi:bridge",
    [OuvrageType.BUSE]: "mdi:high-tunnel",
    [OuvrageType.MUR]: "mdi:wall",
};

export const OuvrageTypes = [
    {
        key: OuvrageType.PONT,
        label: OuvrageTypeLabel[OuvrageType.PONT],
        icon: OuvrageIcon[OuvrageType.PONT],
        color: "#3d2b3d",
    },
    {
        key: OuvrageType.BUSE,
        label: OuvrageTypeLabel[OuvrageType.BUSE],
        icon: OuvrageIcon[OuvrageType.BUSE],
        color: "#494c5d",
    },
    {
        key: OuvrageType.MUR,
        label: OuvrageTypeLabel[OuvrageType.MUR],
        icon: OuvrageIcon[OuvrageType.MUR],
        color: "#84a31f",
    },
];

export enum OuvrageElementCategory {
    STRUCTURE = "structure",
    EQUIPMENT = "equipment",
    PROTECTION = "protection",
}

export const OuvrageElementCategoryLabel = {
    [OuvrageElementCategory.STRUCTURE]: "Structure",
    [OuvrageElementCategory.EQUIPMENT]: "Equipement",
    [OuvrageElementCategory.PROTECTION]: "Protection",
};

export const OuvrageElementCategoryIcon = {
    [OuvrageElementCategory.STRUCTURE]: "mdi:frame",
    [OuvrageElementCategory.EQUIPMENT]: "mdi:fire-hydrant",
    [OuvrageElementCategory.PROTECTION]: "mdi:security",
};

export const OuvrageElementCategories = [
    {
        key: OuvrageElementCategory.STRUCTURE,
        label: OuvrageElementCategoryLabel[OuvrageElementCategory.STRUCTURE],
        icon: OuvrageElementCategoryIcon[OuvrageElementCategory.STRUCTURE],
        color: "#494c5d",
    },
    {
        key: OuvrageElementCategory.EQUIPMENT,
        label: OuvrageElementCategoryLabel[OuvrageElementCategory.EQUIPMENT],
        icon: OuvrageElementCategoryIcon[OuvrageElementCategory.EQUIPMENT],
        color: "#494c5d",
    },
    {
        key: OuvrageElementCategory.PROTECTION,
        label: OuvrageElementCategoryLabel[OuvrageElementCategory.PROTECTION],
        icon: OuvrageElementCategoryIcon[OuvrageElementCategory.PROTECTION],
        color: "#494c5d",
    },
];

export enum OuvrageElementIcon {
    PILE = "mdi:column",
    POUTRE = "mdi:direct-current",
    BARRIERE = "mdi:fence",
}
