import { ReactNode } from 'react';
import './index.scss';

interface ScrollableContentProps {
    children: ReactNode;
    side?: ReactNode;
    header?: ReactNode;
    id?: string;
    noPadding?: boolean;
    noScroll?: boolean;
}

const ScrollableContent = ({ children, header, side, id, noPadding, noScroll }: ScrollableContentProps) => {

    return (
        <div className="scrollable-content" id={id}>
            {!!header && <div className="scrollable-content-header">{header}</div>}
            <div className={`scrollable-content-scrollable ${noPadding ? 'no-padding' : ''} ${noScroll ? 'no-scroll' : ''}`}>
                <div className="scrollable-content-children">
                    {children}
                </div>
                {!!side && <div className="scrollable-content-side">{side}</div>}
            </div>
            <div className="scrollable-content-gradient" />
        </div>
    )
}

export default ScrollableContent;