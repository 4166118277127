import { Icon } from "@iconify/react";
import { Fragment, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PopulationDetail from "../../../components/PopulationDetail";
import List, { FilterType, ListColumns } from "../../../components/data/List";
import Tag, { Tags } from "../../../components/ui/Tag";
import { CoucheTypeLabel, CoucheTypes, Lot } from "../../../models/lot";
import { RoadNameLabel } from "../../../models/operation";
import { Population, PopulationTypeLabel, PopulationTypes } from "../../../models/population";
import { Controles, SampleLabels } from "../../../models/sample";
import { RevisionStatus, RevisionStatusList } from "../../../models/shared";
import useWorkspace from "../../../services/hooks/use-workspace";
import { formatNumberToFixedDecimal } from "../../../utils/format";
import { floatToPrString } from "../../../utils/pr";

interface PopulationListProps {
    lot?: Lot;
}

const PopulationList = ({ lot }: PopulationListProps) => {
    const { operation, workspacePermissions } = useWorkspace();
    const [selectedPopulation, setSelectedPopulation] = useState<Population | null>(null);
    const navigate = useNavigate();
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const handleClose = useCallback((refresh?: boolean) => {
        setSelectedPopulation(null);
        if (refresh) {
            const refreshParam = URLSearchParams.get('refresh_list');
            URLSearchParams.set('refresh_list', refreshParam ? String(Number(refreshParam) + 1) : '1');
            setURLSearchParams(URLSearchParams);
        }
    }, [URLSearchParams]);

    return (
        <Fragment>
            <List<Population>
                columns={[
                    { key: 'type', label: 'Type', mapper: (element) => PopulationTypeLabel[element.type] },
                    { key: 'problematic', label: 'Prob.', mapper: (element) => !!element.problematicDataCount ? <Icon icon="fluent:warning-16-regular" className="color-error" /> : (null) },
                    { key: 'status', label: 'Statut', mapper: (element) => <Tag value={element.status} items={RevisionStatusList} /> },
                    { key: 'controle', label: 'Contrôle', mapper: (element) => <Tags values={Controles.filter(c => !!element.controle[c.key]).map(c => c.key)} items={Controles} /> },
                    ...(operation.synoptique === 1 && !lot
                        ? [
                            { key: 'fullLot', label: 'Lot' },
                            { key: 'lot.material.name', label: 'Materiaux' },
                            { key: 'lot.type', label: 'Type de couche', mapper: (element) => element.lotPopulated?.type ? CoucheTypeLabel[element.lotPopulated?.type] : '' },
                        ] : [
                            { key: 'statistics.average', label: 'Moyenne', mapper: (element) => formatNumberToFixedDecimal(element?.statistics?.average) },
                            { key: 'statistics.min', label: 'Minimum', mapper: (element) => formatNumberToFixedDecimal(element?.statistics?.min) },
                            { key: 'statistics.max', label: 'Maximum', mapper: (element) => formatNumberToFixedDecimal(element?.statistics?.max) },
                        ]) as ListColumns<Population>[],
                    ...(operation.synoptique === 1 && !lot
                        ? [
                            { key: 'lot.way', label: 'Sens', mapper: (element) => operation.waysObj[element.lotPopulated?.way ?? 1]?.label },
                            { key: 'lot.roads', label: 'Voie(s)', mapper: (element) => (element.lotPopulated?.roads ?? []).map(roadId => RoadNameLabel[operation.roadsObj[roadId]?.name]).join(', ') },
                            { key: 'lot.zone.prStart', label: 'PR début - PR fin', mapper: (element) => floatToPrString(element.lotPopulated?.zone?.prStart) + ' - ' + floatToPrString(element.lotPopulated?.zone?.prEnd) },
                        ] : []) as ListColumns<Population>[],
                ]}
                dataEndpoint={`/population/operation/${operation._id}${lot ? '/' + lot._id : ''}`}
                crudEndpoint="/population"
                baseUrl={lot ? `/operation/${operation._id}/travaux/lot/${lot._id}/population` : `/operation/${operation._id}/travaux/population`}
                actions={{
                    comment: workspacePermissions.write,
                    edit: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    view: (e) => workspacePermissions.write && (workspacePermissions.validate || e.status !== RevisionStatus.VALIDATED),
                    activate: workspacePermissions.administrate,
                    duplicate: workspacePermissions.write,
                    delete: workspacePermissions.administrate
                }}
                onView={workspacePermissions.read ? setSelectedPopulation : undefined}
                initialPagination={{ sortBy: 'lot.fullLot', sortDirection: -1 }}
                filters={[
                    { field: 'active', label: 'Actif' },
                    { field: 'problematic', label: 'Problématique', type: FilterType.SWITCH, items: [{ key: true, label: 'Oui' }, { key: false, label: 'Non' }] },
                    { field: 'status', label: 'Statut', type: FilterType.MULTIPLE_SELECT, items: RevisionStatusList },
                    { field: 'controle', label: 'Contrôle', type: FilterType.MULTIPLE_SELECT, items: Controles },
                    ...(!lot ? [
                        { field: 'fullLot', label: 'Lot', type: FilterType.MULTIPLE_SELECT, endpoint: `/lot/distinct/${operation._id}` },
                        { field: 'material', label: 'Matériau', type: FilterType.MULTIPLE_SELECT, endpoint: `/material/list/product/${operation._id}` },
                        { field: 'type', label: 'Type', type: FilterType.MULTIPLE_SELECT, items: PopulationTypes },
                        { field: 'layer', label: 'Type de couche', type: FilterType.MULTIPLE_SELECT, items: CoucheTypes },
                    ] : [])
                ]}
                buttons={[{ label: SampleLabels.population.create, onClick: () => navigate(lot ? `/operation/${operation._id}/travaux/lot/${lot?._id}/population/creer` : `/operation/${operation._id}/travaux/population/creer`) }]}
            />
            <PopulationDetail population={selectedPopulation ?? undefined} onClose={handleClose} />
        </Fragment>
    )
}

export default PopulationList;